import { Component, OnInit } from '@angular/core';
import { LogOutService } from '../services/log-out.service';

@Component({
  selector: 'app-log-out-button-old',
  templateUrl: './log-out-button.component.html',
  styleUrls: ['./log-out-button.component.scss']
})
export class LogOutButtonComponent implements OnInit {
  public isLoggedIn: boolean

  constructor(private logOutService: LogOutService ) {
    this.logOutService.isLoggedIn.subscribe((userStatus) =>{
      this.isLoggedIn = userStatus
    })
   }

  ngOnInit() {
  }

  logOut(){
    this.logOutService.logOut()
  }

  logIn(){
    this.logOutService.logIn()
  }

}
