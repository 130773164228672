<div style="height: 70px;  z-index: 10;">
    <gea-mes-header
      showLogOut="desktop"
      showMenu="both"

      showLeftIcon1="both"
      routeLeftIcon1="home"
      leftIcon1Src="home"
      leftIcon1ToolTip="Home Page"

      showLeftIcon2="both"
      routeLeftIcon2="dashboard/overview-metrics"
      leftIcon2Src="poll"
      leftIcon2ToolTip="Dashboard View"

      showRightIcon1="both"
      routeRightIcon1="admin"
      rightIcon1Src="settings_applications"
      rightIcon1ToolTip="Admin Configuration"

      showRightIcon2="both"
      linkRightIcon2="https://geappliances.sharepoint.com/sites/ManufacturingIT/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FManufacturingIT%2FShared%20Documents%2FBrilliant%20Factory&p=true"
      rightIcon2Src="help_outline"
      rightIcon2ToolTip="Help"
      >
    </gea-mes-header>
</div> 
<div>
  <app-progress-spinner></app-progress-spinner>
  <router-outlet></router-outlet>
</div>