<div class="fx-row-start-center">
  <gea-mes-datepicker
    class="dateRangeBut"
    matTooltip="Select Date" 
    (onDateSelect)="onDateSelection($event)" 
    (onDateInit)="onDateSelection($event)"
    singleDate="false" 
    showLabel="true"
    showCurrentShift="false"
    defaultRange="Year To Date"
    showRangeLabel="true"
    timeRangeExclude="Today,this week,tHiS MoNth">
  </gea-mes-datepicker>
  <div class="datepicker-div" *ngIf="!mobile">
    <button mat-raised-button color="primary" class="dateRangeBut" (click)="navToSSRS()">Reports</button>
  </div>
</div>  
<mat-card class="enterprise-complete-card mat-elevation-z8">
    <mat-card-header>
      <mat-card-title>Completed Tasks</mat-card-title>
      <mat-card-subtitle class="navCard">
        <button mat-button color="primary" class="siteSelectBackButton" (click)="navToEnterprise()"><mat-icon>arrow_back</mat-icon></button>
        <button mat-button [matMenuTriggerFor]="menu" class="siteSelectButton" color="primary">{{displayName}}<mat-icon>arrow_drop_down</mat-icon></button>
        <button mat-button [matMenuTriggerFor]="menu2" class="siteSelectButton" color="primary">{{currentSection}}<mat-icon>arrow_drop_down</mat-icon></button>
        <button mat-button *ngIf="subMenuDataExists" [matMenuTriggerFor]="subSectionMenu" class="siteSelectButton" color="primary">{{currentSubSection}}<mat-icon>arrow_drop_down</mat-icon></button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngFor="let siteData of siteMenuData" (click)="changeSite(siteData.DisplayName)">{{siteData.DisplayName}}</button>
        </mat-menu>  
        <mat-menu #menu2="matMenu">
          <button mat-menu-item *ngFor="let sectionData of sectionMenuData" (click)="changeSection(sectionData)">{{sectionData}}</button>
        </mat-menu>
        <mat-menu #subSectionMenu="matMenu">
          <button mat-menu-item *ngFor="let subSectionData of subSectionMenuData" (click)="changeSubSection(subSectionData)">{{subSectionData}}</button>
        </mat-menu>
      </mat-card-subtitle>
    </mat-card-header>

    <div *ngIf="!mobile; else mobileView">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
    
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition -->

        <!-- Section Column -->
        <ng-container matColumnDef="Section">
          <th mat-header-cell *matHeaderCellDef> Section </th>
          <td mat-cell *matCellDef="let element" (click)="changeSection(element.Object.Section)"> 
            <button mat-button color = "primary" class = "sectionTableButton">{{element.Object.Section}}</button>
          </td>
        </ng-container>

        <!-- Sub Section Column -->
        <ng-container matColumnDef="SubSection">
          <th mat-header-cell *matHeaderCellDef> Sub Section </th>
          <td mat-cell *matCellDef="let element" (click)="changeSubSection(element.Object.SubSection, element.Object.Section)">
            <button mat-button color = "primary" class="sectionTableButton">{{element.Object.SubSection}}</button>  
          </td>
        </ng-container>
    
        <!-- Object Column -->
        <ng-container matColumnDef="Object">
          <th mat-header-cell *matHeaderCellDef> Object </th>
          <td class="clickableColumn" mat-cell *matCellDef="let element" (click)="navToEquipStatusDetails(element.Object.Environment, element.Object.ObjectID)"> 
            <button mat-button color="primary" class = "sectionTableButton">{{element.Object.Description}}</button> 
          </td>
        </ng-container>
    
        <!-- Daily Column -->
        <ng-container matColumnDef="Daily">
          <th mat-header-cell *matHeaderCellDef> Daily </th>
          <td mat-cell *matCellDef="let element"> 
            <div *ngIf="element.Object.PercentComplete.SHIFT == 'NA'; else weekElseDiv">
              
            </div>  
            <ng-template #weekElseDiv>
              <div [ngStyle]="{'color': element.Object.PercentComplete.SHIFT > colorChangeThreshold ? 'green' : 'red'}">
                {{element.Object.PercentComplete.SHIFT | percent}}
              </div>
            </ng-template>
            <div *ngIf="element.Object.Issues.SHIFT > 0"> 
              <span class="clickableColumn" (click)="navToEquipDetials('DAILY', element.Object.Environment, element.Object.ObjectID)">
                <button mat-button color="primary" class="sectionTableButton">Issues: {{element.Object.Issues.SHIFT}}</button></span>
            </div>
            <div *ngIf="element.Object.Issues.SHIFT == 'NA'"> 
              <span>{{element.Object.Issues.SHIFT}}</span>
            </div>
          </td>
        </ng-container>
    
        <!-- Weekly Column -->
        <ng-container matColumnDef="Weekly">
          <th mat-header-cell *matHeaderCellDef> Weekly </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.Object.PercentComplete.WEEK == 'NA'; else weekElseDiv">
              
            </div>  
            <ng-template #weekElseDiv>
              <div [ngStyle]="{'color': element.Object.PercentComplete.WEEK > colorChangeThreshold ? 'green' : 'red'}">
                {{element.Object.PercentComplete.WEEK | percent}}
              </div>
            </ng-template>
            <div *ngIf="element.Object.Issues.WEEK > 0">  
              <span class="clickableColumn" (click)="navToEquipDetials('WEEK', element.Object.Environment, element.Object.ObjectID)">
                <button mat-button color="primary" class="sectionTableButton">Issues: {{element.Object.Issues.WEEK}}</button></span>
            </div>
            <div *ngIf="element.Object.Issues.WEEK == 'NA'">  
              <span>{{element.Object.Issues.WEEK}}</span>
            </div>
          </td>
        </ng-container>

        <!-- Biweekly Column -->
        <ng-container matColumnDef="Biweekly">
          <th mat-header-cell *matHeaderCellDef> Biweekly </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.Object.PercentComplete.BIWEEKLY== 'NA'; else biWeekElseDiv">
              
            </div>  
            <ng-template #biWeekElseDiv>
              <div [ngStyle]="{'color': element.Object.PercentComplete.BIWEEKLY > colorChangeThreshold ? 'green' : 'red'}">
                {{element.Object.PercentComplete.BIWEEKLY | percent}}
              </div>
            </ng-template>
            <div *ngIf="element.Object.Issues.BIWEEKLY> 0">  
              <span class="clickableColumn" (click)="navToEquipDetials('BIWEEKLY', element.Object.Environment, element.Object.ObjectID)">
                <button mat-button color="primary" class="sectionTableButton">Issues: {{element.Object.Issues.BIWEEKLY}}</button></span>
            </div>
            <div *ngIf="element.Object.Issues.BIWEEKLY == 'NA'">  
              <span>{{element.Object.Issues.BIWEEKLY}}</span>
            </div>
          </td>
        </ng-container>
    
        <!-- Monthly Column -->
        <ng-container matColumnDef="Monthly">
          <th mat-header-cell *matHeaderCellDef> Monthly </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.Object.PercentComplete.MONTH == 'NA'; else weekElseDiv">
              
            </div>  
            <ng-template #weekElseDiv>
              <div [ngStyle]="{'color': element.Object.PercentComplete.MONTH > colorChangeThreshold ? 'green' : 'red'}">
                {{element.Object.PercentComplete.MONTH | percent}}
              </div>
            </ng-template>
            <div *ngIf="element.Object.Issues.MONTH > 0">  
              <span class="clickableColumn" (click)="navToEquipDetials('MONTH', element.Object.Environment, element.Object.ObjectID)">
                <button mat-button color="primary" class="sectionTableButton">Issues: {{element.Object.Issues.MONTH}}</button></span>
            </div>
            <div *ngIf="element.Object.Issues.MONTH == 'NA'">  
              <span>{{element.Object.Issues.MONTH}}</span>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

  <!--- 
  *
  *  
    Mobile View 
  *
  *    
  -->
    <ng-template #mobileView>
      <mat-card *ngFor="let obj of equipmentOverviewData" class="enterprise-complete-card mat-elevation-z8">
        <mat-card-header>
          <mat-card-title>{{obj.Object.Description}}</mat-card-title>
          <mat-card-subtitle>{{obj.Object.Section}}</mat-card-subtitle>
          <mat-card-subtitle>{{obj.Object.SubSection}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-divider></mat-divider>
          <div class="fx-row-space-evenly-center">
            <div *ngIf="obj.Object.PercentComplete.SHIFT != 'NA'; else shiftElseDiv">
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Daily</h3>
                </div>
                <div [ngStyle]="{'color': obj.Object.PercentComplete.SHIFT > colorChangeThreshold ? 'green' : 'red'}">
                  {{obj.Object.PercentComplete.SHIFT | percent}}
                </div>
                <div [ngStyle]="{color: '#1976D2'}" *ngIf="obj.Object.Issues.SHIFT > 0"> 
                  <span (click)="navToEquipDetials('DAILY', obj.Object.Environment, obj.Object.ObjectID)" color="primary">Issues: {{obj.Object.Issues.SHIFT}}</span>
                </div>
                <div *ngIf="obj.Object.Issues.SHIFT == 'NA'"> 
                  <span>{{obj.Object.Issues.SHIFT}}</span>
                </div> 
              </div>
            </div>
            <ng-template #shiftElseDiv>
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Daily</h3>
                </div>
                <div [ngStyle]="{color: '#1976D2'}" *ngIf="obj.Object.Issues.SHIFT > 0" color = "primary">  
                  <span (click)="navToEquipDetials('DAILY', obj.Object.Environment, obj.Object.ObjectID)">
                      Issues: {{obj.Object.Issues.SHIFT}}
                  </span>
                </div>
                <div *ngIf="obj.Object.Issues.SHIFT == 'NA'">  
                  <span>{{obj.Object.Issues.SHIFT}}</span>
                </div>
              </div>
            </ng-template>

            <div *ngIf="obj.Object.PercentComplete.WEEK != 'NA'; else weekElseDiv">
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Weekly</h3>
                </div>
                <div  [ngStyle]="{'color': obj.Object.PercentComplete.WEEK > colorChangeThreshold ? 'green' : 'red'}">
                  {{obj.Object.PercentComplete.WEEK | percent}}
                </div>
                <div [ngStyle]="{color: '#1976D2'}" *ngIf="obj.Object.Issues.WEEK > 0"> 
                  <span (click)="navToEquipDetials('WEEK', obj.Object.Environment, obj.Object.ObjectID)">Issues: {{obj.Object.Issues.WEEK}}</span>
                </div>
                <div *ngIf="obj.Object.Issues.WEEK == 'NA'"> 
                  <span>{{obj.Object.Issues.WEEK}}</span>
                </div>
              </div>
            </div>
            <ng-template #weekElseDiv>
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Weekly</h3>
                </div>
                <div [ngStyle]="{color: '#1976D2'}" *ngIf="obj.Object.Issues.WEEK > 0">  
                  <span (click)="navToEquipDetials('WEEK', obj.Object.Environment, obj.Object.ObjectID)">Issues: {{obj.Object.Issues.WEEK}}</span>
                </div>
                <div *ngIf="obj.Object.Issues.WEEK == 'NA'">  
                  <span>{{obj.Object.Issues.WEEK}}</span>
                </div>
              </div>
            </ng-template>

            <div *ngIf="obj.Object.PercentComplete.BIWEEKLY != 'NA'; else biWeekElseDiv">
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Biweekly</h3>
                </div>
                <div  [ngStyle]="{'color': obj.Object.PercentComplete.BIWEEKLY > colorChangeThreshold ? 'green' : 'red'}">
                  {{obj.Object.PercentComplete.BIWEEKLY | percent}}
                </div>
                <div [ngStyle]="{color: '#1976D2'}" *ngIf="obj.Object.Issues.BIWEEKLY > 0"> 
                  <span (click)="navToEquipDetials('BIWEEKLY', obj.Object.Environment, obj.Object.ObjectID)">Issues: {{obj.Object.Issues.BIWEEKLY}}</span>
                </div>
                <div *ngIf="obj.Object.Issues.BIWEEKLY == 'NA'"> 
                  <span>{{obj.Object.Issues.BIWEEKLY}}</span>
                </div>
              </div>
            </div>
            <ng-template #biWeekElseDiv>
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Biweekly</h3>
                </div>
                <div [ngStyle]="{color: '#1976D2'}" *ngIf="obj.Object.Issues.BIWEEKLY > 0">  
                  <span (click)="navToEquipDetials('BIWEEKLY', obj.Object.Environment, obj.Object.ObjectID)">Issues: {{obj.Object.Issues.BIWEEKLY}}</span>
                </div>
                <div *ngIf="obj.Object.Issues.BIWEEKLY == 'NA'">  
                  <span>{{obj.Object.Issues.BIWEEKLY}}</span>
                </div>
              </div>
            </ng-template>

            <div *ngIf="obj.Object.PercentComplete.MONTH != 'NA'; else monthElseDiv">
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Monthly</h3>
                </div>
                <div  [ngStyle]="{'color': obj.Object.PercentComplete.MONTH > colorChangeThreshold ? 'green' : 'red'}">
                  {{obj.Object.PercentComplete.MONTH | percent}}
                </div>
                <div [ngStyle]="{color: '#1976D2'}" *ngIf="obj.Object.Issues.MONTH > 0"> 
                  <span (click)="navToEquipDetials('MONTH', obj.Object.Environment, obj.Object.ObjectID)">Issues: {{obj.Object.Issues.MONTH}}</span>
                </div>
                <div *ngIf="obj.Object.Issues.MONTH == 'NA'"> 
                  <span>{{obj.Object.Issues.MONTH}}</span>
                </div>
              </div>
            </div>
            <ng-template #monthElseDiv>
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Monthly</h3>
                </div>
                <div [ngStyle]="{color: '#1976D2'}" *ngIf="obj.Object.Issues.MONTH > 0">  
                  <span (click)="navToEquipDetials('MONTH', obj.Object.Environment, obj.Object.ObjectID)">Issues: {{obj.Object.Issues.MONTH}}</span>
                </div>
                <div *ngIf="obj.Object.Issues.MONTH == 'NA'">  
                  <span>{{obj.Object.Issues.MONTH}}</span>
                </div>
              </div>
            </ng-template>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-template>
  </mat-card>