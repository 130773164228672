import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class TasklistService extends AbstractRestService  
{
  postInit(): void { }

  
  taskList(site: string, environment: string, object: string, shiftdate: string, shift: string):Observable<GenericLambdaReturn<TaskListType>> 
  {
    return this.get({
      ApiResource: "/tasklist",
      data: {
        Site: site,
        Environment: environment,
        Object: object,
        ShiftDate: shiftdate,
        Shift: shift
      }
    })
  }

  completeTask(id: number, site: string, comment?: string, correctiveAction?: string, tagno?: string, status?: number, ):Observable<GenericLambdaReturn<TaskListType>> 
  {
    let params:any={};

    params.id = id;
    params.Site = site;

    comment != null ? params.comment=comment:null
    status != null ? params.status = status:null
    correctiveAction != null ? params.correctiveaction = correctiveAction:null
    tagno != null ? params.tagno = tagno:null

    return this.post({
      ApiResource: "/tasklist",
      data: { 
        data: params
      }
    })
  }

  postDidNotCompleteList(ids: number[], comment: string) {
    let params:any={};

    params.ids = ids;
    comment ? params.comment=comment:null;

    return this.post({
      ApiResource: "/allnotcomplete",
      data: { 
        data: params
      }
    });      
  }

  admintaskList(site: string, object: string):Observable<GenericLambdaReturn<TaskListType>> 
  {
    return this.get({
      ApiResource: "/configTasks",
      data: {
        Site: site,
        Object: object
      }
    });
  }

  validatorTaskList(taskType?: string):Observable<GenericLambdaReturn<TaskListType>> {
    let params:any={};

    params.Type = "USER";
    taskType ? params.TaskType=taskType:null;

    return this.get({
      ApiResource: "/configTasks",
      data: params,
      repeatCall: false
    })
  }


}

export type TaskListType = {
  status: StatusType;
  tasks: TaskType[];

}

export type StatusType = {
  MONTH: StatusDetailType;
  SHIFT: StatusDetailType;
  WEEK: StatusDetailType;
  BIWEEKLY: StatusDetailType;

}

export type StatusDetailType = {
  last_complete_date: string;
  completed_tasks: number;
  total_tasks: number;
}

export type TaskType = {
  id: number;
  task_type: string;
  seq: number;
  type: string;
  task: string;
  time: string;
  otherinfo: string;
  comment: string;
  corectiveaction: string;
  tagno: string;
  completed: number;
  completed_timestamp: Date;
  completed_userId: string;
  label: string;
  configid?: number,
  files?: number
}

export type TaskUpdate = {
  id: number;
}