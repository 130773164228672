import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(weekOfYear);

@Pipe({name: 'DateConversion'})
export class DateConversionPipe implements PipeTransform {
  transform(value: any, dateType: string): string {
    var dateConversionStr: string

    if (value.length <= 6){
      let regexSt = new RegExp(/^\d{4}/);
      let valStr = value.toString()
      let convertedDate = valStr.replace(regexSt, '')
      let convertedDateInt = parseInt(convertedDate, 10)
      if (dateType == 'month'){
        dateConversionStr = dayjs().month(convertedDateInt).format('MMM YYYY')
        console.debug('DateConversionPipe (value.length<=6 && month): ' + dateConversionStr)
        return dateConversionStr
      } else if ( dateType == 'week'){
        dateConversionStr = dayjs().startOf('week').week(convertedDateInt).format('MM/DD/YYYY') + ' - ' + dayjs().endOf('week').week(convertedDateInt).format('MM/DD/YYYY')
        console.debug('DateConversionPipe (value.length<=6 && week): ' + dateConversionStr)
        return dateConversionStr
      }
    } else {
      if (dateType == 'month'){
        dateConversionStr = dayjs(value).format('MMM YYYY')
        console.debug('DateConversionPipe (value.length>6 && month): ' + dateConversionStr)
        return dateConversionStr
      } else if ( dateType == 'week'){
        dateConversionStr = dayjs(value).startOf('week').format('MM/DD/YYYY') + ' - ' + dayjs(value).endOf('week').format('MM/DD/YYYY')
        console.debug('DateConversionPipe (value.length>6 && week): ' + dateConversionStr)
        return dateConversionStr
      }
    }   
  }
}