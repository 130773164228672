import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class PlantListService  extends AbstractRestService{

  postInit(): void { }

  plantList():Observable<GenericLambdaReturn<PlantType>>  {
    return this.get({
      ApiResource: "/configplantlist",
      repeatCall: false
    });    
  }  


}

export type PlantType = {
  plantid: number;
  displayName: string;
  site: string;
  environment; string
}