import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { AdminTaskFileDialog } from 'src/app/admin/components/admin-task-file-dialog/admin-task-file-dialog.component';
import { AdminTaskListService } from 'src/app/admin/services/admin-task-list.service';
import { DialogData } from '../task-complete-complete';
import { TaskType } from '../../services/tasklist.service';

@Component({
  selector: 'app-task-file-dialog',
  templateUrl: './task-file-dialog.component.html',
  styleUrls: ['./task-file-dialog.component.scss']
})
export class TaskFileDialog implements OnInit {

  task:TaskType;

  largeImage:string;

  fileListSub$: Subscription;
  fileList: any[];

  displayedColumns: string[] = [ 'image' ];

  constructor(
    public dialogRef: MatDialogRef<AdminTaskFileDialog>,
    @Inject(MAT_DIALOG_DATA) public inData: DialogData,
    private svcAdminTaskList:AdminTaskListService,
  ) { 
    this.task = inData['task'];
  }

  ngOnInit(): void {
    this.loadFileList();
  }

  loadFileList(): void {
    if (this.fileListSub$) this.fileListSub$.unsubscribe();

    this.fileListSub$ = this.svcAdminTaskList.taskFileList(this.task.configid).subscribe(
      (out) => {
        this.fileList = out.Body;
      }
    )
  }

  zoomImage(s3link:string):void {
    this.largeImage = s3link;
  }

  close():void {
    this.dialogRef.close();
  }

}
