import { Injectable } from '@angular/core';
import { AbstractRestService } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class EAMService extends AbstractRestService {

  postInit(): void {}

  getEAMAssetList(site: string, parent?: string){
    let params:any={};

    params.Site = site;
    parent != null ? params.Parent = parent:null;

    return this.get({
      ApiResource: "/eam",
      data: params,
      repeatCall: false
    })
  }

  createEamWorkOrder(eamAsset: string, priority: string, description: string, id: number, site: string){
    let params:any={};

    params.asset_number = eamAsset;
    params.priority = priority;
    params.description = description;
    params.task_id = id;
    params.Site = site

    return this.post({
      ApiResource: "/eam",
      data: { 
        data: params
      }
    })    
  }
  getEAMWorkorders(inAssetName: string){
    let params:any={};

    params.AssetName = inAssetName;
  
    return this.get({
      ApiResource: "/eamworkorder",
      data: params,
      repeatCall: false
    })

  }
}
