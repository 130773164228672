<div style="display:flex; flex-direction: row; gap: 400px;">
    <div>
        <h3 mat-dialog-title>Image or Documents</h3>
    </div>
    <div>
        <button mat-icon-button color="primary" (click)="addFile()"><mat-icon>add</mat-icon> Add File</button>
        <input type="file" id="fileImageUpload" name="fileUpload"  accept=".png,.jpg,.pdf" style="display:none;"/>
    </div>
</div>
<div mat-dialog-content>
    <div>
        <b>Time Frame:</b> {{task.task_type}}
    </div>
    <div>
        <b>Task:</b> {{task.task}}
    </div>
    <div *ngIf="fileList && fileList.length > 0">
        <mat-table [dataSource]="fileList" class="mat-elevation-z8">
        <!--- NOTE: No headers for this mat-table -->

        <!-- Image/PDF Column -->
        <ng-container matColumnDef="image">
            <mat-cell *matCellDef="let element">
                <span *ngIf="element.s3_filename.split('.').pop() != 'pdf'"><img class="small-size" [src]="element.s3link"/></span>
                <span *ngIf="element.s3_filename.split('.').pop() == 'pdf'"><a [href]="element.s3link">Download PDF</a></span>

            </mat-cell>
        </ng-container>

        <!-- Actions -->
        <ng-container matColumnDef="actions">
            <mat-cell *matCellDef="let element">
                <div  class="action_buttons">
                    <div>
                        <button [disabled]="!adminFlag || element.display_order <= 1" mat-button color="primary" (click)="changeOrder(element.id, element.display_order, -1)"><mat-icon>arrow_drop_up</mat-icon> Move Up</button>
                    </div>
                    <div>
                        <button [disabled]="!adminFlag || element.display_order >= fileList.length" mat-button color="primary" (click)="changeOrder(element.id, element.display_order, 1)"><mat-icon>arrow_drop_down</mat-icon> Move Down</button>
                    </div>
                    <div>
                        <button [disabled]="!adminFlag" mat-button color="warn" (click)="deleteFile(element.id)"><mat-icon>delete_forever</mat-icon> Delete</button>
                    </div>
                </div>
            </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
    <div *ngIf="fileList && fileList.length == 0" style="padding: 10px">
        <h4>No pictures, click Add File above to add.</h4>
    </div>
</div>
