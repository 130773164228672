import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class CompletedOverviewService extends AbstractRestService {
    postInit(): void {
    }
  
    completedDashData(startDate?: string, endDate?: string, site?: string):Observable<GenericLambdaReturn<completedOverview>> 
    {
      let currentDate = new Date();
      let currentDateString = [currentDate.getFullYear(), ('0' + (currentDate.getMonth() + 1)).slice(-2), ('0' + currentDate.getDate()).slice(-2)].join('-')

      let params:any={};

      currentDateString ? params.CurrentDate = currentDateString:null

      startDate ? params.DateRangeStart = startDate:null
      endDate ? params.DateRangeEnd = endDate:null

      site ? params.Site = site:null
      
      return this.get({
        ApiResource: "/dashcompletedoverview",
        data: params,
        repeatCall: false
      })      
    }
  
}

export type completedOverview = {
  Site: string,
  ObjectID: string,
  Section: string,
  task_type: string,
  Completed: number,
  Total: number,
  Status: string
}
  