<button mat-icon-button color="primary" *ngIf="isLoggedIn; else logIn" (click)="logOut()">
  <mat-icon>exit_to_app</mat-icon>
</button>
<ng-template #logIn>
  <button
  type="button"
  mat-button 
  color="primary" 
  matTooltip="Log Out / Log In"
  (click)="logIn()">
    Log In
  </button>
</ng-template>