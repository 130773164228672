<h3 mat-dialog-title>EAM Asset Lookup</h3>
<div mat-dialog-content>
    <div class="dialog-container">
        <form [formGroup]="objEAMLookupFormGroup">
            <mat-form-field>
                <mat-select placeholder="Site" formControlName="display_name" (selectionChange)="changePlant()" cdkFocusInitial>
                <mat-option *ngFor="let d of plantList" [value]="d.display_name">{{d.display_name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <div *ngIf="assetListLoading">
                <div class="spinner-wrapper">
                    <span>Loading Asset List </span>
                    <mat-spinner [diameter]="20"></mat-spinner>
                  </div>
                <mat-divider></mat-divider>
            </div>

            <mat-form-field *ngIf="!assetListLoading">
                <input  type="text"
                placeholder="Type to Search Asset Number"
                aria-label="Asset"
                matInput
                formControlName="assetNum"
                [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                    <div *ngIf="showAutoComplete">
                        <cdk-virtual-scroll-viewport itemSize="5" minBufferPx="200" maxBufferPx="400" class="eamassets-viewport-select">
                            <mat-option *cdkVirtualFor="let option of filteredOptions | async" [value]="option.AssetNumber" [ngStyle]="{'color':option.Maintainable_flag === 'N' ? 'red' : 'black'}">
                            {{option.AssetNumber}}<span *ngIf="(isScreen$ | async)?.matches">&nbsp; &nbsp; &nbsp; &nbsp;  ({{option.Description}})</span>
                            </mat-option>
                        </cdk-virtual-scroll-viewport>
                    </div>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </div>
</div>
<div mat-dialog-actions>
  <button class="mat-raised-button" (click)="selectData()" >Select</button>
  <button class="mat-raised-button" (click)="onNoClick()">Cancel</button>
  <div *ngIf="showInvalidAsset" style="color: red">The Asset Number field must be blank or contain a valid EAM asset number</div>
 </div>
