import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TasklistService } from 'src/app/services/tasklist.service';
import { Subscription} from 'rxjs';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';

@Component({
  selector: 'app-validator-results',
  templateUrl: './validator-results.component.html',
  styleUrls: ['./validator-results.component.scss']
})
export class ValidatorResultsComponent implements OnInit {

  taskType:string; // Task type to display (SHIFT, MONTH, etc)

  dataSource: any; // List of tasks

  formNumber: string; // Form number, pulled from first record of dataSource
  revision: string; // Revision, pulled from first record revision
  userid: string; // Userid from @gea-mes/cognito
  label: string; // Task Label to display

  subscription: Subscription;
  
  columns: string[]=["type", "task", "time", "otherinfo"];

  constructor(private route: ActivatedRoute,
              private geaMesCognito: GeaMesCognitoAuthService,
              private getTaskListService: TasklistService) { 
      this.taskType = this.route.snapshot.paramMap.get('tasktype');
    }

  ngOnInit(): void {
    console.log("ValidatorResultsComponent", this.taskType);
    this.userid = this.geaMesCognito.getUserAttribute("sso");
    this.fetch();
  }

  fetch() {

    this.subscription = this.getTaskListService.validatorTaskList(this.taskType).subscribe((data) => {
      this.dataSource = data.Body;

      if (this.dataSource.length > 0) {
        this.formNumber = this.dataSource[0].form_number;
        this.revision = this.dataSource[0].revision;
        this.label = this.dataSource[0].label;
      } else {
        this.formNumber = "";
        this.revision = "";
      }

      console.log(this.dataSource)
    });    
  }

  ngOnDestory() {
    if (this.subscription) this.subscription.unsubscribe();
  }

}
