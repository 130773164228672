import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ConfigS3UploadService, s3Link } from '../../services/config-s3-upload.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { WcConfirmDialog } from '../wc-confirm/wc-confirm.dialog';
import { Subscription} from 'rxjs';
import { first } from 'rxjs/operators';

export enum UploadEnum {
  Object="OBJECT",
  User="USER"
}

@Component({
  selector: 'app-file-upload',
  inputs: ['type','site', 'environment','object','text','admin'],
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.sass']
})
export class FileUploadComponent implements OnInit {

  type:string = UploadEnum.Object;
  site: string;
  environment: string;
  object: string;
  text: string;
  
  adminFlag: boolean;
  adminSub$: Subscription;

  @Output() onUploadStart: EventEmitter<any> = new EventEmitter(); // Event Emitted when upload has started
  @Output() onUploadComplete: EventEmitter<any> = new EventEmitter(); // Event Emitted when upload has completed

  constructor(private service: ConfigS3UploadService,
              private snakBar: MatSnackBar,
              private geaMesCognito: GeaMesCognitoAuthService,
              private dialog: MatDialog) {
    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => { 
      this.adminFlag = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });

  }

  ngOnInit() {
  }

  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
          for (let index = 0; index < fileUpload.files.length; index++) {
                const file = fileUpload.files[index];
                this.uploadFile(file);
          }
    };

    fileUpload.click();
  }  

  private uploadFile(file: File) {

    if (this.type == UploadEnum.Object) {
      const dialogRef = this.dialog.open(WcConfirmDialog, {width: '500px', disableClose: true});

      dialogRef.afterClosed().subscribe( confirm => {
        if (confirm) {
          this.startUpload(file);
        }
      });    
    } else {
      this.startUpload(file);
    }
  }

  startUpload(file: File) {
    this.onUploadStart.emit(file.name);
    this.service.getS3Link(this.type, this.site, this.environment, this.object, file.name).subscribe((
      out => {
        if (out.Body.status.substr(0,5) == "ALLOW") {
          this.completeUpload(out.Body,file);
        } else {
          let title:string;
          if (this.object == undefined) {
            title = this.geaMesCognito.getUserAttribute("sso");
          } else {
            title = this.object;
          }

          this.snakBar.open(`${out.Body.status}.`, `${title}` )
        }
    }));
  }

  completeUpload(signedURLInfo: s3Link, file: File) {
    this.service.uploadFile(signedURLInfo, file).subscribe(out => {
      let title:string;
      if (this.object == undefined) {
        title = this.geaMesCognito.getUserAttribute("sso");
      } else {
        title = this.object;
      }

      this.snakBar.open(`Configuration for ${title} Uploaded.`, "Complete", { duration: 2000 } );
      this.onUploadComplete.emit(file.name);
    });
  }
}