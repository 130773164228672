<div class="fx-row-start-center">
  
  <gea-mes-datepicker
    class="dateRangeBut"
    matTooltip="Select Date" 
    (onDateSelect)="onDateSelection($event)" 
    (onDateInit)="onDateSelection($event)"
    singleDate="false" 
    showLabel="true"
    showCurrentShift="false"
    defaultRange="Year To Date"
    showRangeLabel="true"
    timeRangeExclude="Today">
  </gea-mes-datepicker>

  <div class="datepicker-div" *ngIf="!mobile">
    <button mat-raised-button color="primary" class="dateRangeBut" (click)="navToSSRS()">Reports</button>
  </div>

</div>

<mat-card class="enterprise-complete-card mat-elevation-z8">
  <mat-card-header>
    <mat-card-title>Completed Tasks</mat-card-title>
  </mat-card-header>

    <!-- DESKTOP VIEW -->
  <div *ngIf="(isScreen$ | async)?.matches">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">      
      <!-- Site Column -->
      <ng-container matColumnDef="Site">
        <th mat-header-cell *matHeaderCellDef> Site </th>
        <td mat-cell *matCellDef="let element" (click)="navToSiteMetrics(element.DisplayName)"> 
          <button mat-button color="primary" class="siteTableButton">{{element.DisplayName}}</button>
        </td>
      </ng-container>

      <!-- Daily Column -->
      <ng-container matColumnDef="Daily">
        <th mat-header-cell *matHeaderCellDef> Daily </th>
        <td mat-cell *matCellDef="let element"> 
          <div *ngIf="element.SHIFT.percentComplete == 'NA'; else dailyElseDiv">
            NA
          </div>  
          <ng-template #dailyElseDiv>
            <div [ngStyle]="{'color': element.SHIFT.percentComplete > colorChangeThreshold ? 'green' : 'red'}">
              {{element.SHIFT.percentComplete | percent}}
            </div>
          </ng-template>
        </td>
      </ng-container>

      <!-- Weekly Column -->
      <ng-container matColumnDef="Weekly">
        <th mat-header-cell *matHeaderCellDef> Weekly </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.WEEK.percentComplete == 'NA'; else weekElseDiv">
            NA
          </div>  
          <ng-template #weekElseDiv>
            <div [ngStyle]="{'color': element.WEEK.percentComplete > colorChangeThreshold ? 'green' : 'red'}">
              {{element.WEEK.percentComplete | percent}}
            </div>
          </ng-template>
        </td>
      </ng-container>

      <!-- Biweekly Column -->
      <ng-container matColumnDef="Biweekly">
        <th mat-header-cell *matHeaderCellDef> Biweekly </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.BIWEEKLY.percentComplete == 'NA'; else biweekElseDiv">
            NA
          </div>  
          <ng-template #biweekElseDiv>
            <div [ngStyle]="{'color': element.BIWEEKLY.percentComplete > colorChangeThreshold ? 'green' : 'red'}">
              {{element.BIWEEKLY.percentComplete | percent}}
            </div>
          </ng-template>
        </td>
      </ng-container>

      <!-- Monthly Column -->
      <ng-container matColumnDef="Monthly">
        <th mat-header-cell *matHeaderCellDef> Monthly </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.MONTH.percentComplete == 'NA'; else monthElseDiv">
            NA
          </div>  
          <ng-template #monthElseDiv>
            <div [ngStyle]="{'color': element.MONTH.percentComplete > colorChangeThreshold ? 'green' : 'red'}">
              {{element.MONTH.percentComplete | percent}}
            </div>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

<!--- 
  *
  *  
    Mobile View 
  *
  *    
-->
  <div *ngIf="(isTablet$ | async)?.matches">
    <mat-card *ngFor="let obj of dataSource.data" class="enterprise-complete-card mat-elevation-z8">
        <mat-card-header>
          <mat-card-title style="color:'#1976D2'" (click)="navToSiteMetrics(obj.DisplayName)">
            {{obj.DisplayName}}
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div class="fx-row-space-evenly-center">
            
            <div class="fx-row-center-center">
              <div>
                <h3 class="mat-3">Daily</h3>
              </div>
              <div *ngIf="obj.SHIFT.percentComplete != 'NA'" [ngStyle]="{'color': obj.SHIFT.percentComplete > colorChangeThreshold ? 'green' : 'red'}">
                {{obj.SHIFT.percentComplete | percent}}
              </div>
              <div *ngIf="obj.SHIFT.percentComplete == 'NA'">
                NA
              </div>
            </div>

            <div class="fx-row-center-center">
              <div>
                <h3 class="mat-3">Weekly</h3>
              </div>
              <div *ngIf="obj.WEEK.percentComplete != 'NA'" [ngStyle]="{'color': obj.WEEK.percentComplete > colorChangeThreshold ? 'green' : 'red'}">
                {{obj.WEEK.percentComplete | percent}}
              </div>
              <div *ngIf="obj.WEEK.percentComplete == 'NA'">
                NA
              </div>
            </div>

            <div class="fx-row-center-center">
              <div>
                <h3 class="mat-3">Biweekly</h3>
              </div>
              <div *ngIf="obj.BIWEEKLY.percentComplete != 'NA'" [ngStyle]="{'color': obj.BIWEEKLY.percentComplete > colorChangeThreshold ? 'green' : 'red'}">
                {{obj.BIWEEKLY.percentComplete | percent}}
              </div>
              <div *ngIf="obj.BIWEEKLY.percentComplete == 'NA'">
                NA
              </div>
            </div>

            <div class="fx-row-center-center">
              <div>
                <h3 class="mat-3">Monthly</h3>
              </div>
              <div *ngIf="obj.MONTH.percentComplete != 'NA'" [ngStyle]="{'color': obj.MONTH.percentComplete > colorChangeThreshold ? 'green' : 'red'}">
                {{obj.MONTH.percentComplete | percent}}
              </div>
              <div *ngIf="obj.MONTH.percentComplete == 'NA'">
                NA
              </div>
            </div>

          </div>
        </mat-card-content>
    </mat-card>
  </div>
  
</mat-card>