import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, Inject} from '@angular/core';
import { DialogData } from './task-complete-complete';
import { EAMService } from 'src/app/services/eam.service';
import { Subscription, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';



@Component({
    selector: 'eam-work-request-dialog',
    templateUrl: './eam-work-request-dialog.html',
    styleUrls: ['./eam-work-request-dialog.scss']
  })
  export class EamWorkRequestDialog {
    workPriority: string
    workPriorityOptions: option[]
    eamAsset: string
    eamServiceSubscription: Subscription
    site: string
    childAsset: string
    childAssetOptions: option[]

    isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
    isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
  
    constructor(public dialogRef: MatDialogRef<EamWorkRequestDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,  
        private eamService: EAMService,
        private breakpointObserver: BreakpointObserver) {
        this.eamAsset = this.data.eamAsset
        
        this.workPriorityOptions = [
            {value: '04-STAN-5-14D', viewValue: '5-14 Days (04-STAN-5-14D)'},
            {value: '05-STAN-15-30D', viewValue: '15-30 Days (05-STAN-15-30D)'},
            {value: '06-STAN-31-90D', viewValue: '31-90 Days (06-STAN-31-90)'},
            {value: '09-NON-OP', viewValue: 'No Production Impact (09-NON-OP)'}
        ]

        this.site = this.data.site
        if (this.eamAsset != "") {
            this.site = this.eamAsset.substr(0,3);
        }

        this.childAssetOptions = []

        console.log("REST call")
        if (this.eamServiceSubscription) this.eamServiceSubscription.unsubscribe()
        
        this.eamServiceSubscription = this.eamService.getEAMAssetList(this.site, this.eamAsset).pipe(
            pluck('Body')
        ).subscribe((assetListRestRaw: any[]) =>{
            this.childAssetOptions = assetListRestRaw
            this.childAsset = '' 
            console.log(this.childAssetOptions)
        })

  
    }

    onNoClick(): void {
        this.dialogRef.close();
      }

    onSubmit(){
        if (this.childAsset ){
            console.log('  asset selected ',this.childAsset)
            let resultSet = {
                workPriority: this.workPriority,
                childAsset: this.childAsset
            }
            this.dialogRef.close(resultSet)

        }

    }
}

export interface option {
    value: string,
    viewValue: string
}
  
