<div class="headerItems">
    <div>
        <h3 mat-dialog-title>Select Images to Associate</h3>
    </div>
</div>
<div mat-dialog-content>
    <div *ngIf="fileList && fileList.length > 0">
        <mat-table [dataSource]="fileList" class="mat-elevation-z8">
            <!--- NOTE: No headers for this mat-table -->

            <!-- Image/PDF Column -->
            <ng-container matColumnDef="image">
                <mat-cell *matCellDef="let element">
                    <span *ngIf="element.FileName.split('.').pop() != 'pdf'"><img class="small-size" [src]="element.s3link"/></span>
                    <span *ngIf="element.FileName.split('.').pop() == 'pdf'"><a [href]="element.s3link">Download PDF</a></span>
                </mat-cell>
            </ng-container>

            <!-- Actions -->
            <ng-container matColumnDef="actions">
                <mat-cell *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()" 
                    (change)="$event ? selection.toggle(element.FileName) : null" 
                    [checked]="selection.isSelected(element.FileName)"></mat-checkbox>

                </mat-cell>
            </ng-container>

            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
    <div *ngIf="fileList && fileList.length == 0" style="padding: 10px">
        <h4>No existing pictures or PDFs</h4>
    </div>
</div>

<div mat-dialog-actions class="fx-row-space-evenly-center">
    <div>
      <button mat-raised-button [disabled]="selection.isEmpty()" color="primary" type="submit" (click)="save()" >Add Images</button>
    </div>
    <div>
      <button type="button" color="primary" mat-raised-button (click)="onNoClick()" class="warn">Cancel</button>
    </div>
</div>