<h3 mat-dialog-title>Select Work Request Asset and Priority</h3>
<br>
<form (ngSubmit)="onSubmit()">
    <div *ngIf="childAssetOptions" class="fx-row-wrap">
        <mat-form-field class="eam-card-dialog">
            <mat-label> Work Request Asset</mat-label>
            <mat-select [(ngModel)]="childAsset" class="form-control" id="childAsset" name="childAsset" [(value)]="eamAsset">
                <cdk-virtual-scroll-viewport itemSize="5" minBufferPx="200" maxBufferPx="400" class="eamassets-viewport-select">
                    <mat-option *ngFor="let option of childAssetOptions" [value]="option.CHILD_ASSET" [disabled]="option.MAINTAINABLE_FLAG==='N'" [ngStyle]="{'color':option.MAINTAINABLE_FLAG === 'N' ? 'red' : 'black'}">
                        {{option.CHILD_ASSET}} <span *ngIf="(isScreen$ | async)?.matches">&nbsp; &nbsp; &nbsp; &nbsp; ({{option.INSTANCE_DESCRIPTION}})</span>
                    </mat-option>
                </cdk-virtual-scroll-viewport>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="fx-row-wrap">  
        <mat-form-field class="eam-card-dialog">      
            <mat-label>Work Request Priority</mat-label>
            <mat-select [(ngModel)]="workPriority" class="form-control" id="workPriority" name="workPriority">
                <mat-option *ngFor="let priority of workPriorityOptions" [value]="priority.value">
                    {{priority.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>     
    </div>
    <div class="fx-row-wrap" style="color: red">  
        NOTE: Work Requests may take up to 5 minutes to appear in the eAM system
    </div>
    <br>
    <div class="fx-row-space-around-center">
        <button type="button" class="mat-raised-button" (click)="onNoClick()">Cancel</button>
        <button type="submit" class="mat-raised-button" >Submit</button>
    </div> 
</form>