
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ObjectShiftListService } from '../services/object-shift-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ObjectDescriptionService } from '../services/object-description.service';
import { ShiftDateShareService } from '../services/shift-date-share.service';

@Component({
  selector: 'app-shift-list',
  templateUrl: './shift-list.component.html',
  styleUrls: ['./shift-list.component.scss']
})
export class ShiftListComponent implements OnInit {
  shiftSubscription: Subscription;
  dsShiftList: {};
  selectedShiftDate: string;
  selectedShift: string;
  site: string;
  objAliasTitle: string;
  lineTitle: string;
  environment: string;
  object: string;
  histShiftList: {};
  histWeekList: {};
  histBiWeeklyList: {};
  histMonthList: {};
  activeShiftList: {};
  activeWeekList: {};
  activeBiWeeklyList: {};
  activeMonthList: {};
  test:boolean = true;
  siteTitle: string;
  eamAsset: string;

  showActiveDaily:boolean = false;
  showActiveWeekly:boolean = false;
  showActiveBiweekly:boolean = false;
  showActiveMonthly:boolean = false;
  showHistoricDaily:boolean = false;
  showHistoricWeekly:boolean = false;
  showHistoricBiweekly:boolean = false;
  showHistoricMonthly:boolean = false;
  showNoActiveChecklist:boolean = false;
  showNoHistoricChecklist:boolean = false;
  
  @Output() SHIFTDATE = new EventEmitter<string>();
  @Output() shift = new EventEmitter<string>();
  
  constructor(
    private objShiftListService: ObjectShiftListService,
    private route: ActivatedRoute,
    private objDescriptionService: ObjectDescriptionService, 
    private router: Router,
    private shiftDateShare: ShiftDateShareService
  ) { 
    this.site = this.route.snapshot.paramMap.get('site');
    this.environment = this.route.snapshot.paramMap.get('environment');
    this.object = this.route.snapshot.paramMap.get('object');
    this.eamAsset = this.route.snapshot.paramMap.get('eamAsset')
    
  }

  ngOnInit() {
    this.getShifts()
    this.getObjectDescription(this.site, this.object)
  }

  getShifts(){
    this.shiftSubscription = this.objShiftListService.objectShiftListSubscription(this.site, this.environment, this.object).subscribe((
      out => {

        let validShift: boolean = false;
        this.activeShiftList = out.Body["active"]["Daily"];
        this.activeWeekList = out.Body["active"]["Weekly"];
        this.activeBiWeeklyList = out.Body["active"]["BiWeekly"];
        this.activeMonthList = out.Body["active"]["Monthly"];
        this.histShiftList = out.Body["historic"]["Daily"];
        this.histWeekList = out.Body["historic"]["Weekly"];
        this.histBiWeeklyList = out.Body["historic"]["BiWeekly"];
        this.histMonthList = out.Body["historic"]["Monthly"];

        for (let shift in this.activeShiftList) {
          if (this.selectedShiftDate == this.activeShiftList[shift]["SHIFTDATE"] && this.selectedShift == this.activeShiftList[shift]["SHIFT"]) {
            validShift = true;
          }
        }

        if (!validShift) {
          this.selectedShift = "";
          this.selectedShiftDate = "";
        }

        if(this.activeShiftList !== undefined){ if(this.activeShiftList["length"] > 0){ this.showActiveDaily = true; }}
        if(this.activeWeekList !== undefined){ if(this.activeWeekList["length"] > 0){ this.showActiveWeekly = true; }}
        if(this.activeBiWeeklyList !== undefined){ if(this.activeBiWeeklyList["length"] > 0){ this.showActiveBiweekly = true; }}
        if(this.activeMonthList !== undefined){ if(this.activeMonthList["length"] > 0){ this.showActiveMonthly = true; }}
        if(this.histShiftList !== undefined){ if(this.histShiftList["length"] > 0){ this.showHistoricDaily = true; }}
        if(this.histWeekList !== undefined){ if(this.histWeekList["length"] > 0){ this.showHistoricWeekly = true; }}
        if(this.histBiWeeklyList !== undefined){ if(this.histBiWeeklyList["length"] > 0){ this.showHistoricBiweekly = true; }}
        if(this.histMonthList !== undefined){ if(this.histMonthList["length"] > 0){ this.showHistoricMonthly = true; }}

        if(!this.showActiveDaily && !this.showActiveWeekly && !this.showActiveBiweekly && !this.showActiveMonthly){ this.showNoActiveChecklist = true; }
        if(!this.showHistoricDaily && !this.showHistoricWeekly && !this.showHistoricBiweekly && !this.showHistoricMonthly){ this.showNoHistoricChecklist = true; }
      }
    ));
  }

  getObjectDescription(site: string, obj: string){
    this.objDescriptionService.getObjs().subscribe((objData) =>{
      if (objData != null){
        Object.keys(objData).forEach(site =>{
          Object.keys(objData[site]).forEach(area =>{
            if (area == obj){
              this.siteTitle = site
            }
          })
        })
        this.objAliasTitle = objData[this.siteTitle][obj]['description']
        this.lineTitle = objData[this.siteTitle][obj]['section']
        if (this.siteTitle == "Pla") {
          this.siteTitle = "COE";
        }
      
      }
    })
  }

  selectShift(shiftDate: string, shift: string, checklistType: string, mode: string){
    let convertShiftDateStr: string 
    convertShiftDateStr = shiftDate
    this.shiftDateShare.changeShiftDate(convertShiftDateStr)
    this.shiftDateShare.changeShift(shift ? shift : "NONE")
    this.shiftDateShare.changeDescription(this.objAliasTitle)
    this.routeToTaskList(checklistType, mode)
  }

  routeToTaskList(checklistType: string, mode: string){
    this.router.navigate(['/tasklist', this.site, this.environment, this.object, mode, checklistType])
  }

}
