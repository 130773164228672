<h3 mat-dialog-title>ServiceNow Incident Creation</h3>
<br>
<form (ngSubmit)="onSubmit()" [formGroup]="snFormGroup">
    
    <mat-form-field class="readonly-card-dialog">
        <textarea class="sn-label" matInput cdkTextareaAutosize placeholder="ServiceNowConfiguration Item (Pre-configured by checklist admin)" formControlName="serviceNowCI" readonly></textarea>
    </mat-form-field>

    <div class="fx-row-wrap">
        <mat-form-field class="readonly-card-dialog">
            <textarea matInput cdkTextareaAutosize placeholder="Short Description" formControlName="shortDescription" readonly></textarea>
        </mat-form-field>
    </div>

    <mat-form-field class="readonly-card-dialog">
        <textarea matInput cdkTextareaAutosize placeholder="Full Description" formControlName="fullDescription" readonly></textarea>
    </mat-form-field>

    <br>

    <mat-form-field class="card-dialog">      
        <mat-label class="required-label">Urgency</mat-label>
        <mat-select [(ngModel)]="ticketUrgency" id="ticketUrgency" formControlName="ticketUrgency" required>
            <mat-option *ngFor="let urgency of ticketUrgencyOptions" [value]="urgency.value">
                {{urgency.viewValue}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <br>

    <div class="fx-row-wrap" style="color: red">  
        NOTE: Incidents may take up to 5 minutes to appear in ServiceNow
    </div>
    
    <br>
    
    <div class="fx-row-space-around-center">
        <button type="button" class="mat-raised-button" (click)="onNoClick()">Cancel</button>
        <button type="submit" class="mat-raised-button" >Submit</button>
    </div> 

</form>

