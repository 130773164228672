import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, Inject } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder, FormControl } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';


@Component({
  selector: 'servicenow-dialog',
  templateUrl: './servicenow-dialog.html',
  styleUrls: ['./servicenow-dialog.scss']
})
export class ServiceNowDialog{
  ticketUrgency: string
  ticketUrgencyOptions: option[]
  serviceNowCI: string
  shortDescription: string
  fullDescription: string
  site: string
  siteTitle: string
  lineTitle: string
  objAliasTitle: string
  comment: string
  correctiveAction: string
  snFormGroup: UntypedFormGroup

  constructor(public dialogRef: MatDialogRef<ServiceNowDialog>,
    @Inject(MAT_DIALOG_DATA) public data: SNOWDialogData,
    private snFormBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar) {

    this.serviceNowCI = this.data.serviceNowCI
    this.site = this.data.site
    this.serviceNowCI = this.data.serviceNowCI
    this.siteTitle = this.data.siteTitle
    this.lineTitle = this.data.lineTitle
    this.objAliasTitle = this.data.objAliasTitle
    this.comment = this.data.comment
    this.correctiveAction = this.data.correctiveAction

    this.shortDescription = this.comment + ' (' + this.lineTitle + ' - ' + this.objAliasTitle + ')'
    this.fullDescription = 'Corrective Action: ' + this.correctiveAction

    
    this.ticketUrgencyOptions = [
      {value: '1', viewValue: 'High'},
      {value: '2', viewValue: 'Medium'},
      {value: '3', viewValue: 'Low'}

    ]

    this.snFormGroup = this.snFormBuilder.group({
      serviceNowCI: this.serviceNowCI,
      shortDescription: this.shortDescription,
      fullDescription: this.fullDescription,
      ticketUrgency: [this.ticketUrgency,{
        validators: Validators.required
      }]
    })
  }  
  
  onNoClick(): void{
    this.dialogRef.close();
  }

  onSubmit(){
    if (this.snFormGroup.status == 'INVALID'){
      console.error('Invalid form submission.')
      this._snackBar.openFromComponent(ErrorSnackBar,{
        duration:5000,
      });
    }
    else{
      let resultSet = {
        urgency: this.ticketUrgency
      }
      this.dialogRef.close(resultSet)
    }
  }
}
export interface option {
  value: string,
  viewValue: string
}

@Component({
  selector: 'ErrorSnackBar',
  template: "<span style='color: yellow'>Must Include Urgency Level.</span>",
  styles: [],
})
export class ErrorSnackBar {}


export interface SNOWDialogData {
  serviceNowCI: string;
  site: string;
  siteTitle: string;
  lineTitle: string;
  objAliasTitle: string;
  comment: string;
  correctiveAction: string;
}