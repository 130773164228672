import { Injectable } from '@angular/core';
import { NavItem } from '@gea-mes/navbar';


@Injectable()
export class NavItemService {

  navItems: NavItem[] = [

    {
        "displayName": "Logout",
        "disabled": false,
        "show":"mobile",
        "iconName": "",
        "gea_network":"No",
        "children": [
            {
              "displayName": 'Logout',
              "iconName": '',
              "route": 'logout',
              "show":"mobile",
              "gea_network":"No",
            }]
       
    } 
    
    ];
}
