import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Component, Inject} from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { EAMService } from '../services/eam.service';
import { EamWorkRequestDialog } from './eam-work-request-dialog';
import { EamWorkRequestOrderComponent } from './eam-work-request-order/eam-work-request-order.component'
import { ServiceNowDialog } from './servicenow-dialog';
import { ServiceNowService } from '../services/servicenow.service';
import { TaskType } from '../services/tasklist.service'

@Component({
    selector: 'task-complete-complete',
    templateUrl: './task-complete-dialog.html',
    styleUrls: ['./task-complete-dialog.scss']
  })
  export class TaskCompleteDialog {
    id: number;
    comment: string;
    inputData: string;
    correctiveAction: string;
    tagno: string;
    completed: number;
    type: number;
    title:string;
    completeFormGroup: UntypedFormGroup;
    validForm: boolean
    editMode: boolean=false;
    issuePlaceHolder: string;
    eamAssetFlag: boolean;
    eamAsset: string;
    serviceNowFlag: boolean;
    serviceNowCI: string;
    priority: string;
    descriptionCount: number;
    descriptionCountOver: boolean;
    eAMCharacterCount: number = 186;
    site: string;
    siteTitle: string;
    lineTitle: string;
    objAliasTitle: string;

    showInputData: boolean = false;
    showComment: boolean = false;
    showCorrectiveActionTagNo: boolean = false;
  
    constructor(
      public dialogRef: MatDialogRef<TaskCompleteDialog>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      private fb: UntypedFormBuilder,
      private _snackBar: MatSnackBar,
      private eamService: EAMService,
      private serviceNowService: ServiceNowService,
      public workRequestDialog: MatDialog,
      public ViewEamWorkOrder: MatDialog,
      public serviceNowDialog: MatDialog) {
        this.validForm = false;
        this.id = data.id;
        this.comment = data.task.comment ? data.task.comment : "";
        this.correctiveAction = data.task.corective_action ? data.task.corective_action : "";
        this.completed = data.task.completed;
        this.tagno = data.task.tag_no ? data.task.tag_no : "";
        this.type = data.type;
        this.editMode = data.editMode;
        this.eamAsset = data.eamAsset;
        this.priority = '';
        this.serviceNowCI = data.serviceNowCI;
        this.siteTitle = data.siteTitle;
        this.lineTitle = data.lineTitle;
        this.objAliasTitle = data.objAliasTitle;

        let description = this.tagno + this.comment + this.correctiveAction
        this.descriptionCount = description.length
        this.descriptionCount > this.eAMCharacterCount ? this.descriptionCountOver = true : this.descriptionCountOver = false
        this.site = data.site

        this.eamAssetFlag = this.eamAsset == 'None' ? false : true;
        this.serviceNowFlag = this.serviceNowCI == 'None' ?  false: true;
        
        this.completeFormGroup = this.fb.group({
          comment: (data.task.comment == null) ? '': data.task.comment,
          correctiveAction: (data.task.corective_action == null) ? '': data.task.corective_action,
          tagno: (data.task.tag_no == null) ? '': data.task.tag_no,
          input_data: (data.task.input_data == null) ? '' : data.task.input_data
        });

        if (data.task.required_input != '' && data.type != 2) {
          // If required_input exists and not a Did Not Complete dialog require the input_data form field
          this.completeFormGroup.controls["input_data"].addValidators(Validators.required);
          this.showInputData = true;
        }

        if (data.requireComment || (data.type != 5 && data.type != 6) ) {
          // If any other form except Complete/Complete Edit or require comment is true
          this.completeFormGroup.controls["comment"].addValidators(Validators.required);
          this.showComment = true;
        }

        if (data.type == 1 || data.type == 3) {
          // If Issue Found or Issue Found (Edit) show the corrective action/tag no fields
          this.showCorrectiveActionTagNo = true;
        }

        console.debug("DialogData", data);
        switch(data.type) {
          case 1:
            this.title = "Issue Found";
            break;
          case 2:
            this.title = "Did Not Complete";
            break;
          case 3:
            this.title = "Issue Found (Edit)"
            break;
          case 4: 
            this.title = "Did Not Complete (Edit)"
            break;
          case 5:
            this.title = "Complete"
            break;
          case 6:
            this.title = "Complete (Edit)"
            break;
        }

        if(data.type == 2 || data.type == 4){
          this.issuePlaceHolder = "Reason for not completing"
        } else {
          this.issuePlaceHolder = "Issue Description"
        }

        this.completeFormGroup.valueChanges.subscribe((formValues) =>{
          this.comment = formValues.comment
          this.inputData = formValues.input_data
          this.correctiveAction = formValues.correctiveAction
          this.tagno = formValues.tagno
          let description = this.tagno + this.comment + this.correctiveAction
          this.descriptionCount = description.length
          this.descriptionCount > this.eAMCharacterCount ? this.descriptionCountOver = true : this.descriptionCountOver = false
        })
      }
  
    onNoClick(): void {
      this.dialogRef.close();
    }

    saveData(): void {
      const formData = {id: this.id, comment: this.comment, input_data: this.inputData, correctiveAction: this.correctiveAction, tagno: this.tagno, completed: this.completed, type: this.type}

      if (this.showComment && (this.comment == '' || this.comment == null)){
        this._snackBar.openFromComponent(ErrorSnackBar, {
          duration: 5000,
        });
        this.validForm = false

      } else {
        this.validForm = true
        this.dialogRef.close(formData);
      }
    }
    viewWorkRequest(): void{
      const ViewWorkOrderDialogRef = this.ViewEamWorkOrder.open(EamWorkRequestOrderComponent, {
        width: '500px',
        height:'550px',
        data: {eamAsset: this.eamAsset}
      });
  
      ViewWorkOrderDialogRef.afterClosed().subscribe(result => {
      
      });    

    }
    createWorkRequest(): void{
      const formData = {priority: this.priority, id: this.id, comment: this.comment, correctiveAction: this.correctiveAction, tagno: this.tagno, completed: this.completed, type: this.type}
      if(this.comment == '' || this.comment == null) {
        this._snackBar.openFromComponent(ErrorSnackBar, {
          duration: 5000,
        });
      } 
      else {
        let description = this.tagno + this.comment + this.correctiveAction

        if (description.length <= this.eAMCharacterCount){

          const workOrderDialogRef = this.workRequestDialog.open(EamWorkRequestDialog, {
            width: '1000px',
            data: {eamAsset: this.eamAsset,
                  site: this.site}
          });
      
          workOrderDialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {

              let asset =this.eamAsset
              if (result.childAsset) asset = result.childAsset

              this.eamService.createEamWorkOrder(asset, result.workPriority, description, this.id, this.site).subscribe((result) =>{
                if (result['Body'] == "Work Order Record Recorded"){
                  this.saveData()
                } else if (result['Body'] == "Work Order Already Exists"){
                  let message = "Work Order Already Exists"
                  let action = "Error"
                  this._snackBar.open(message, action, {
                    duration: 2000,
                  });
                }
              })
            }
          });
        }
      } 
    }
    createServiceNowIncident(): void{
      if(this.comment == '' || this.comment == null) {
        this._snackBar.openFromComponent(ErrorSnackBar, {
          duration: 5000,
        });
      } 
      else {
        const serviceNowDialogRef = this.serviceNowDialog.open(ServiceNowDialog, {
          width: '500px',
          data: {
            serviceNowCI: this.serviceNowCI,
            site: this.site,
            siteTitle: this.siteTitle,
            lineTitle: this.lineTitle,
            objAliasTitle: this.objAliasTitle,
            comment: this.comment,
            correctiveAction: this.correctiveAction
          }
        });

        serviceNowDialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {
            this.saveData()
            this.serviceNowService.createServiceNowTicket(result.urgency, this.id, this.site).subscribe((result) =>{
             if (result['Body'] == "Service Now Ticket Already Exists"){
                let message = result['Body']
                let action = "Error"
                this._snackBar.open(message, action, {
                  duration: 2000,
                });
            }
          })
        }
       });
      }
    }
  }
  
  
  export interface DialogData {
    id: number;
    type: number;
    editMode: boolean;
    eamAsset: string;
    serviceNowCI: string;
    site: string;
    siteTitle: string;
    lineTitle: string;
    objAliasTitle: string;
    tasktitle: string;
    requireComment: boolean;
    task:TaskType;
  }

  @Component({
    selector: 'ErrorSnackBar',
    template: "<span style='color: yellow'>Enter an Issue Found, Reason, or Comment</span>",
    styles: [],
  })
  export class ErrorSnackBar {}