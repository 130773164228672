import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class ConfigS3UploadService  extends AbstractRestService {

  postInit(): void {
  }

  getS3Link(type:string, site: string, environment: string, object: string, filename: string):Observable<GenericLambdaReturn<s3Link>>  {
    return this.get({
      ApiResource: "/configs3link",
      data: {
        Type: type,
        Site: site,
        Environment: environment,
        Object: object,
        OriginalName: filename
      },
      repeatCall: false
    })
  }

  uploadFile(signedURLInfo: s3Link, file: File):Observable<GenericLambdaReturn<any>> {
    let fd = new FormData();
    
    for (var key in signedURLInfo.s3response.fields) {
      let fieldName = key.replace("_","-")
      fd.append(fieldName, signedURLInfo.s3response.fields[key])
    }

    fd.append("file",file);

    return this.post({
      ApiCompleteURL: signedURLInfo.s3response.url,
      data: fd,
      disableAuthHeader: true,
      repeatCall: false
    });
  }
}
export type s3Link = {
    s3response: {
      url: string;
      fields: {
        key: string;
        x_amz_algorithm: string;
        x_amz_credential: string;
        x_amz_date: string;
        x_amz_security_token: string;
        policy: string;
        x_amz_signature: string;
      }
    };
    status: string
  }