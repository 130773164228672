  <div mat-dialog-content>
    <mat-list  >
     
      <h4> FORCE UPDATE </h4>
      <mat-list-item *ngIf="checklistArrComplete.length >  0">
         <h4  >Not Started Checklists: <br >(will be immediately replaced)</h4> 
      </mat-list-item>
      <mat-list-item *ngFor="let checklist of checklistArrComplete" >
            <span style="color:green" *ngIf="checklist.shift"> {{checklist.shift}} </span>
            <span style="color:green" *ngIf="checklist.shift_date">&nbsp; {{checklist.shift_date | date:'MM/dd/yyyy'}} </span>
            <span style="color:green" *ngIf="checklist.task_type == 'WEEK'">&nbsp;Week: {{checklist.week | WeekMonthConversion: 'w'}}</span>         
            <span style="color:green" *ngIf="checklist.task_type == 'BIWEEKLY'">&nbsp;Biweekly: {{checklist.week | WeekMonthConversion: 'w'}}</span>
            <span style="color:green" *ngIf="checklist.month">&nbsp;Month: {{checklist.month | WeekMonthConversion: 'MMMM'}} </span>    
      </mat-list-item>
      <mat-list-item *ngIf="checklistArrNotComplete.length >  0">
         <h4>In-Progress Checklists: <br> (existing checklist activity will be deleted, if selected) </h4>
      </mat-list-item>
      <mat-list-item *ngFor="let checklist of checklistArrNotComplete">
        <mat-checkbox [(ngModel)] = "checklist.checked">
          <span style="color:red" *ngIf="checklist.shift"> {{checklist.shift}} </span>
          <span style="color:red"  *ngIf="checklist.shift_date"> {{checklist.shift_date | date:'MM/dd/yyyy'}} </span>
          <span style="color:red"  *ngIf="checklist.task_type == 'WEEK'"> Week: {{checklist.week | WeekMonthConversion:'w'}}</span>
          <span style="color:red" *ngIf="checklist.task_type == 'BIWEEKLY'"> Biweekly: {{checklist.week | WeekMonthConversion:'w'}}</span>
          <span style="color:red"  *ngIf="checklist.month"> Month: {{checklist.month | WeekMonthConversion:'MMMM'}} </span>
        </mat-checkbox>
      </mat-list-item>
      <mat-list-item *ngIf="checklistArr.length <= 0">
        No check list are loaded. Delete will be skipped, but all checklists will be loaded. 
      </mat-list-item>
    </mat-list>
</div>
<div *ngIf="checklistArr.length > 0">

  <div mat-dialog-actions >
     <button mat-button class="mat-raised-button manLoadBut" color="warn" (click)="deleteTaskLists()" >Delete and Update</button>
     <button mat-button class="mat-raised-button manLoadBut" (click)="onNoClick()">Cancel</button>
  </div>
</div>
<div *ngIf="checklistArr.length == 0">

  <div mat-dialog-actions >
     <button mat-button class="mat-raised-button manLoadBut" color="warn" (click)="deleteTaskLists()" >Update</button>
     <button mat-button class="mat-raised-button manLoadBut" (click)="onNoClick()">Cancel</button>
  </div>
</div>