<h3 mat-dialog-title>{{title}}</h3>
<div mat-dialog-content>
  <div class="dialog-container">
    <form [formGroup]="objCreateFormGroup">
      <mat-form-field>
          <mat-select placeholder="Site" formControlName="display_name" (selectionChange)="select()" cdkFocusInitial>
            <mat-option *ngFor="let d of datasource" [value]="d.display_name">{{d.display_name}}</mat-option>
          </mat-select>
      </mat-form-field>
          
      <mat-form-field>
        <textarea matInput cdkTextareaAutosize placeholder="Section" formControlName="section"></textarea>
      </mat-form-field>

      <mat-form-field>
        <textarea matInput cdkTextareaAutosize placeholder="Sub Section (optional)" formControlName="subsection"></textarea>
      </mat-form-field>      
          
      <mat-form-field>
          <textarea  matInput cdkTextareaAutosize placeholder="Object ID" formControlName="objectID"></textarea>
      </mat-form-field>
      
      <mat-form-field>
          <textarea matInput cdkTextareaAutosize placeholder="Description" formControlName="manualDescript"></textarea>
      </mat-form-field>
      
      
      <div>
        <mat-checkbox class="example-margin"  formControlName="loadtime">Load Only After Shift Starts</mat-checkbox>
      </div>

      <div style="margin-bottom: 10px; margin-top: 10px;">
        <mat-checkbox class="example-margin"  formControlName="requireComment">Require Comment for "Complete (OK)"</mat-checkbox>
      </div>
      
      <div *ngIf="assetListLoading">
        <div class="spinner-wrapper">
            <span>Loading Asset List </span>
            <mat-spinner [diameter]="20"></mat-spinner>
          </div>
        <mat-divider></mat-divider>
      </div>
      <div *ngIf="!assetListLoading" class="eam-assetid">
        <div class="eam-assetid-input">
          <mat-form-field>
              <input type="text"
                placeholder="Type to Search Asset Number (optional)"
                aria-label="Asset"
                matInput
                formControlName="assetNum"
                [matAutocomplete]="auto">

            <mat-autocomplete  #auto="matAutocomplete">
              <div *ngIf="showAutoComplete">
                <cdk-virtual-scroll-viewport itemSize="5" minBufferPx="200" maxBufferPx="400" class="eamassets-viewport-select">
                  <mat-option *cdkVirtualFor="let option of filteredOptions | async" [value]="option.AssetNumber" [ngStyle]="{'color':option.Maintainable_flag === 'N' ? 'red' : 'black'}">
                    {{option.AssetNumber}}<span *ngIf="(isScreen$ | async)?.matches">&nbsp; &nbsp; &nbsp; &nbsp;  ({{option.Description}})</span>
                  </mat-option>
                </cdk-virtual-scroll-viewport>
              </div>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="eam-assetid-search">
          <button mat-button class="mat-raised-button" (click)="openEAMLookup()" ><mat-icon>search</mat-icon></button>
        </div>
      </div>
      <mat-form-field>
        <textarea matInput cdkTextareaAutosize placeholder="ServiceNow Configuration Item CI (optional)" formControlName="servicenowci"></textarea>
      </mat-form-field>
    </form>  
  </div>
</div>
<div mat-dialog-actions>
  <button class="mat-raised-button" (click)="saveData()" >Save</button>
  <button class="mat-raised-button" (click)="onNoClick()">Cancel</button>
  <div *ngIf="showInvalidAsset" style="color: red">The Asset Number field must be blank or contain a valid EAM asset number</div>
 </div>

