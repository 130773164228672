import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap, map, } from 'rxjs/operators';
import { Observable, Subscription, combineLatest, timer} from 'rxjs';
import { MatSort } from '@angular/material/sort'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { GenericLambdaReturn } from '../models/genericLambda';
import { TasklistService, TaskListType, TaskType, StatusType } from '../services/tasklist.service'
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TaskCompleteDialog } from './task-complete-complete'
import { DidNotCompleteDialog } from './did-not-complete-dialog';
import { ObjectDescriptionService } from '../services/object-description.service';
import { ShiftDateShareService } from '../services/shift-date-share.service';
import { ClearTaskDialog } from './clear-task-dialog';
import { TaskFileDialog } from './task-file-dialog/task-file-dialog.component';

export interface taskListItem {
  id: number 
  comment: string 
  corective_action: string 
  tag_no: string 
  completed: number
  type: number
  tasktitle: string
}

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit, OnDestroy {
  dataSource:MatTableDataSource<TaskType>
  mDataSource: TaskType[]
  statusDatasource: StatusType;
  shift: any;
  statusValue: string = "VALUE"
  site: string;
  environment: string;
  object: string;
  selectedTaskType: string = "";
  selectedLastCompleted: string;
  selectedRevision: any;
  selectedFormNumber: any;
  showDidNotCompleteAllButton: boolean;
  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
  columns: string[]=["type", "task", "time", "otherinfo", "issueFound", "tag"];
  objAliasTitle: string;
  dsShiftList:{};
  selectedShiftDate: string;
  selectedShift: string;
  @ViewChild(MatSort) sort: MatSort;
  subscription: Subscription;  
  shiftSubscription: Subscription;
  timer$: Subscription;
  objInfoObj: any;
  objInfoOb: any;
  shiftDateTitle: string;
  shiftTitle: string;
  objInfoObcombine: Subscription;
  checkListType: string;
  mode: string;
  editMode: boolean;
  lineTitle: string;
  siteTitle: string;
  eamAsset: string;
  serviceNowCI:string;
  tasktitle: string;
  requireComment: boolean;

  constructor(
    private service: TasklistService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private objDescriptionService: ObjectDescriptionService,
    private shiftDateShare: ShiftDateShareService,
    private router: Router
  ) {  
    this.site = this.route.snapshot.paramMap.get('site');
    this.environment = this.route.snapshot.paramMap.get('environment');
    this.object = this.route.snapshot.paramMap.get('object');
    this.checkListType = this.route.snapshot.paramMap.get('checklistType');
    this.mode = this.route.snapshot.paramMap.get('mode');
    this.editMode = (this.mode == "ACTIVE") ? true : false;
    this.dataSource = new MatTableDataSource<TaskType>();
  }

  ngOnInit() {
    this.selectedTaskType = "";
    const objShiftInfo = combineLatest(
      this.shiftDateShare.objDesctiptOb,
      this.shiftDateShare.shiftDateOb,
      this.shiftDateShare.shiftOb
    ).pipe(map(([objDescript, shiftDate, shift]) => {
      return {objDescript, shiftDate, shift}
    }))

    objShiftInfo.subscribe((selectedObj) => {
      if (selectedObj.objDescript == "" || selectedObj.shiftDate == "" || selectedObj.shift == ""){
        this.router.navigate(['/tasklist', this.site, this.environment, this.object])
      }
      this.selectedShift = selectedObj.shift
      this.selectedShiftDate = selectedObj.shiftDate
      this.objAliasTitle = selectedObj.objDescript
      this.getObjectDescription(this.site, this.object)
      this.fetch()
    })
  }

  unSelectShift() {
    this.router.navigate(['/tasklist', this.site, this.environment, this.object])
  } 

  fetch(){

    this.dataSource.filterPredicate = (data: TaskType, filter: string) => {
      return data.task_type == filter;
    };
    this.dataSource.sort=this.sort;

    let d: Observable<GenericLambdaReturn<TaskListType>> =
      this.route.paramMap.pipe(
        switchMap((params:ParamMap) =>
        this.service.taskList(this.site, this.environment, this.object, this.selectedShiftDate, this.selectedShift))
      )
    
    
      if (this.subscription) this.subscription.unsubscribe();     

    this.subscription = d.subscribe((next) => {
      next.Body.status.SHIFT = (next.Body.status.SHIFT === undefined) ? {"last_complete_date": "", "completed_tasks": 0, "total_tasks": 0} : next.Body.status.SHIFT
      next.Body.status.WEEK = (next.Body.status.WEEK === undefined) ? {"last_complete_date": "", "completed_tasks": 0, "total_tasks": 0} : next.Body.status.WEEK
      next.Body.status.BIWEEKLY = (next.Body.status.BIWEEKLY === undefined) ? {"last_complete_date": "", "completed_tasks": 0, "total_tasks": 0} : next.Body.status.BIWEEKLY
      next.Body.status.MONTH = (next.Body.status.MONTH === undefined) ? {"last_complete_date": "", "completed_tasks": 0, "total_tasks": 0} : next.Body.status.MONTH
      this.dataSource.data=next.Body["tasks"];
      this.dataSource.sort=this.sort;
      this.selectedTaskType = this.checkListType;
      this.dataSource.filter=this.selectedTaskType;
      this.mDataSource =this.dataSource.filteredData;

      console.info(this.mDataSource)      
      this.statusDatasource = next.Body["status"]
      this.shift = (next.Body["status"]["SHIFT"] == null || next.Body["status"]["SHIFT"] == undefined) ? next.Body["status"]["SHIFT"] : [];
      this.selectedLastCompleted = (this.statusDatasource[this.selectedTaskType] == null || this.statusDatasource[this.selectedTaskType] == undefined) ? '' : this.statusDatasource[this.selectedTaskType].last_complete_date;
      this.selectedRevision = (this.statusDatasource[this.selectedTaskType] == undefined) ? '' : this.statusDatasource[this.selectedTaskType].revision;
      this.selectedFormNumber = (this.statusDatasource[this.selectedTaskType] == undefined) ? '' : this.statusDatasource[this.selectedTaskType].form_number;
      
      this.showDidNotCompleteAll()

      if (this.mode != 'ACTIVE'){
        this.setFilter(this.checkListType)
        this.editMode = true
      } else {
        this.editMode = false
      }
    },
    (err) => {
      console.log("AN ERROR OCCURRED!!");
      console.error(err);

      if (this.service.isRerunnable(err)) {
        console.log("This is a rerunnable failure");

        if (!this.timer$ || this.timer$.closed) {
          let waitTime:number = this.service.randomInt(30000, 60000);
          console.warn(`Retrieve tasklist failed, waiting ${waitTime}ms before trying again.`)
          this.timer$ = timer(waitTime).subscribe((next) => {
              console.info("Trying to retrieve tasklist again after prior failure.");
              this.timer$.unsubscribe(); // Clean up this subscription
              this.fetch();
          });
        }
      } else {
        console.error("Failure is client side, do not rerun but warn the user somehow");
      }
    });
  }

  markOK(id:number, task:TaskType) {
    console.debug("markOK", this.requireComment);

    if (this.requireComment || (task.required_input && task.required_input != '')) {
      let width:string;
  
      if (this.isScreen$) {
        width ='500px';
      } else {
        width = '500px';
      }

      const dialogRef = this.dialog.open(TaskCompleteDialog, {
        width: width,
        data: 
          {
            site: this.site, 
            id: id, 
            comment: '', 
            correctiveAction: '', 
            tagno: '', 
            completed: 0, 
            type: 5, // Set Dialog in comment only mode 
            editMode: this.editMode, 
            eamAsset: this.eamAsset, 
            serviceNowCI: this.serviceNowCI,
            siteTitle: this.siteTitle,
            lineTitle: this.lineTitle,
            objAliasTitle: this.objAliasTitle,
            requireComment: this.requireComment,
            task: task
          }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
            this.complete(result.id, result.comment, result.correctiveAction, result.tagno, 1, result.input_data); // Record OK
        }
      });
    } else {
      this.complete(id,'','','',1); // Record OK
    }
  }

  complete(id: number, comment?: string, correctiveAction?: string, tagno?: string,status?:number, inputData?: string) {
    this.service.completeTask(id, this.site, comment, correctiveAction, tagno, status, inputData).subscribe(out => {
      this.fetch();
    })
  }

  setFilter(tasktype: string) {
    this.selectedTaskType = tasktype;
    this.dataSource.filter=this.selectedTaskType;
    this.mDataSource =this.dataSource.filteredData;
    this.selectedLastCompleted = this.statusDatasource[tasktype].last_complete_date;
    this.selectedRevision = this.statusDatasource[this.selectedTaskType].revision;
    this.showDidNotCompleteAll()    
  }

  openfiles(task:TaskType):void {
    const dialogRef = this.dialog.open(TaskFileDialog, {
      width: '800px',
      maxHeight: '800px',
      data: {task: task,
            site: this.site,
            environment: this.environment,
            object: this.object
      }
    });

  }

  viewComments(task: TaskType){
    let type: number

    if (task.completed == 1) {
      type = 6
    } if (task.completed == 2){
      type = 3
    } else if (task.completed == 3){
      type = 4
    }
    this.openCompleteDialog(task.id, 1, task) 
  }

  openCompleteDialog(id: number, type: number, task:TaskType) {
    let width:string;
    type = type ? type:1

    if (this.isScreen$) {
      width ='500px';
    } else {
      width = '500px';
    }
    const dialogRef = this.dialog.open(TaskCompleteDialog, {
      width: width,
      data: 
        {
          site: this.site, 
          id: id, 
          type: type, 
          editMode: this.editMode, 
          eamAsset: this.eamAsset, 
          serviceNowCI: this.serviceNowCI,
          siteTitle: this.siteTitle,
          lineTitle: this.lineTitle,
          objAliasTitle: this.objAliasTitle,
          requireComment: this.requireComment,
          task: task
        }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.type == 2 || result.type == 4) {
          this.complete(result.id, result.comment, result.correctiveAction, result.tagno, 3, result.input_data); // Did not complete
        } else if (result.type == 5 || result.type == 6) {
            this.complete(result.id, result.comment, result.correctiveAction, result.tagno, 1, result.input_data); // Complete
        } else {
          this.complete(result.id, result.comment, result.correctiveAction, result.tagno, 2, result.input_data); // Issue Found, not OK
        }
      }
    });
  }

  showDidNotCompleteAll(): void {
    let statusData = this.statusDatasource
    if (this.selectedTaskType == "SHIFT" && statusData["SHIFT"].completed_tasks == 0) {
      this.showDidNotCompleteAllButton = true
    } else if (this.selectedTaskType == "WEEK" && statusData["WEEK"].completed_tasks == 0) {
      this.showDidNotCompleteAllButton = true
    } else if (this.selectedTaskType == "BIWEEKLY" && statusData["BIWEEKLY"].completed_tasks == 0) {
      this.showDidNotCompleteAllButton = true      
    } else if (this.selectedTaskType == "MONTH" && statusData["MONTH"].completed_tasks == 0){
      this.showDidNotCompleteAllButton = true
    } else {
      this.showDidNotCompleteAllButton = false
    }
  }

  didNotCompleteAll(): void {
    let width:string;
    

    if (this.isScreen$) {
      width ='500px';
    } else {
      width = '250px';
    }
    const dialogRef = this.dialog.open(DidNotCompleteDialog, {
      width: width,
      maxHeight: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      let ids = this.dataSource.filteredData.map(data => data.id)
      if (result != undefined) {
        if (result.deleteFlag == true) {
          this.service.postDidNotCompleteList(ids, result.comment).subscribe((data => {
            this.fetch()
          }))
        } else {
          console.warn("Did Not Complete Cancelled")
        }
      }
    });
  }

  unsubscribe() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.shiftSubscription) this.shiftSubscription.unsubscribe();
    if (this.objInfoObcombine) this.objInfoObcombine.unsubscribe();
  }

  getObjectDescription(site: string, obj: string){
    this.objDescriptionService.getObjs().subscribe((objData) =>{
      if (objData != null){
        Object.keys(objData).forEach(site =>{
          Object.keys(objData[site]).forEach(area =>{
            if (area == obj){
              this.siteTitle = site
            }
          })
        })
        this.objAliasTitle = objData[this.siteTitle][obj]['description']
        this.lineTitle = objData[this.siteTitle][obj]['section']
        this.eamAsset = objData[this.siteTitle][obj]["eamasset"]
        this.serviceNowCI = objData[this.siteTitle][obj]["servicenowci"]
        if (this.eamAsset == "") this.eamAsset = "None"
        if (this.serviceNowCI == "") this.serviceNowCI = "None" 

        this.requireComment = objData[this.siteTitle][obj]['requirecomment'] == 1 ? true : false;
      }
    })
  }

  navToEam(){
    window.open("https://apex.appl.ge.com/ords/f?p=100")
  }

  clearChecklistItem(id: number, comment: string, correctiveAction: string, tagno: string, status: number, tasktitle:string){
    let width:string;
    console.log(id)
    let checkListItem = {
      id: id,
      comment: comment,
      correctiveAction: correctiveAction,
      tagno: tagno,
      deleteFlag: false,
      status: status,
      tasktitle: tasktitle
    }
    

    if (this.isScreen$) {
      width ='500px';
    } else {
      width = '250px';
    }
    const dialogRef = this.dialog.open(ClearTaskDialog, {
      width: width,
      maxHeight: '500px',
      data: checkListItem
    });

    dialogRef.afterClosed().subscribe(checkListItem => {
      let ids = this.dataSource.filteredData.map(checkListItem => checkListItem.id)
      if (checkListItem != undefined) {
        if (checkListItem.deleteFlag == true) {
          let checkListItem = {
            id: id,
            comment: '',
            correctiveAction: '',
            tagno: '',
            input_data: '',
            status: 0
          }
      
          this.complete(checkListItem.id, checkListItem.comment, checkListItem.correctiveAction, checkListItem.tagno, checkListItem.status, checkListItem.input_data)
        } else {
          console.log("Clear Task Cancelled")
        }
      }
    });

  }
}
