import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService extends AbstractRestService {

  postInit(): void { }

  getStatus():Observable<GenericLambdaReturn<ValidatorGetStatusType>> {
    return this.get({
      ApiResource: "/configvalidstatus",
      repeatRate: 5000
    });
  }
}

export type ValidatorGetStatusType = {
  status: ValidatorStatusType,
  tasktypes: ValidatorTaskType[],
}

export type ValidatorStatusType = {
  UserID: string,
  Updatestatus: string,
  OriginalFileName: string,
  FileHash: string,
  LastFileUpdateTime: Date,
  revision: string,
  form_number: string
}

export type ValidatorTaskType = {
  task_type: string,
  label: string,
  heading: string
}