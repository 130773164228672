<mat-card class="log-out-card">
  <mat-card-header>
    <mat-card-title>You are Logged Out</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      You have been logged out. If you need to log back in, click the button below.
    </p>
    <p>
      If you are not using the application be sure to close your browser window. 
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="logIn()">Log In</button>
  </mat-card-actions>
</mat-card>