<h1 class="mat-h1">Checklist File Validator</h1>

<div class="fx-row-start">
    <div class="CellSpacingMedium">
        <button mat-raised-button [routerLink]="'/admin'">BACK</button>  
    </div>

    <div class="CellSpacingMedium">
        <button mat-raised-button><app-file-upload type="USER" text="Upload Checklist File" (onUploadStart)="uploadStart($event)" (onUploadComplete)="uploadComplete($event)"></app-file-upload></button>
    </div>
</div>

<div class="fx-column-space-between-start" style="margin: 15px 15px 15px 15px;">

</div>

<div [ngSwitch]="uploadStatus">
    <div *ngSwitchCase="UploadStatusEnum.INPROCESS">
        <div class="fx-column-space-between-center">
            <div><mat-spinner></mat-spinner></div>
            <div>{{status.Updatestatus}}</div>
        </div>
    </div>
    <div *ngSwitchCase="UploadStatusEnum.COMPLETE">
        <div class="HeadingSmall CellSpacingMedium" style="margin: 15px 15px 15px 0px;">Filename: {{status.OriginalFileName}}</div>
        <div class="HeadingSmall CellSpacingMedium" style="margin: 15px 15px 15px 0px;">Uploaded Time (UTC): {{status.LastFileUpdateTime  | date:'yyyy-MM-dd HH:mm:ss' }}</div>
        <div class="HeadingSmall CellSpacingMedium" style="margin: 15px 15px 15px 0px;">Uploaded by: {{status.UserID}}</div>
        <div class="HeadingSmall CellSpacingMedium" style="margin: 15px 15px 15px 0px;">Form Number: {{status.form_number}}</div>
        <div class="HeadingSmall CellSpacingMedium" style="margin: 15px 15px 15px 0px;">Revision: {{status.revision}}</div>

        <ng-container  *ngFor="let d of taskTypes">
            <h3 class="mat-h2">{{d.section}}:</h3>
            <div class="fx-column-space-between-start">
                <ng-container *ngFor="let t of d.tasks">
                    <button class="TaskType" mat-raised-button (click)="navToResults(t.task_type)" color="primary">{{t.label}}</button>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div *ngSwitchCase="UploadStatusEnum.ERROR">
        <div class="fx-column-space-around-center">
            <div><mat-icon color="warn" style="font-size: 75px;" >error</mat-icon></div>
            <div>{{status.Updatestatus}}</div>
        </div>
    </div>    
</div>