import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription} from 'rxjs';
import { first } from 'rxjs/operators'
import { ValidatorService, ValidatorStatusType, ValidatorTaskType } from '../../services/validator.service';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import * as dayjs from 'dayjs';

export interface tasks  {
  section:string,
  sort: number,
  tasks: any[]
}

export type task = {
  task_type: string
}

export enum UploadStatusEnum {
  COMPLETE = 1,
  INPROCESS = 2,
  ERROR = 3,
  NONE = 4
}

@Component({
  selector: 'app-validator-status',
  templateUrl: './validator-status.component.html',
  styleUrls: ['./validator-status.component.scss']
})
export class ValidatorStatusComponent implements OnInit {

  UploadStatusEnum = UploadStatusEnum; // Declare so we can use Enum in HTML

  adminFlag: boolean;
  adminSub$: Subscription;

  headingDisplay = 
    {
      "SHIFT": { display: "Daily", sort: 1},
      "WEEK": { display: "Weekly", sort: 2},
      "BIWEEKLY": { display: "Biweekly", sort: 3},
      "MONTHSPECIFIC": { display: "Specific Month", sort: 4},
      "MONTH": { display: "Monthly", sort: 5}
    }

  status: ValidatorStatusType;
  statusSub$: Subscription;
  taskTypes: tasks[] = [];

  uploadStatus:UploadStatusEnum = UploadStatusEnum.NONE;
  
  constructor(private router: Router,
              private validator:ValidatorService,
              private geaMesCognito: GeaMesCognitoAuthService) { }

  ngOnInit(): void {
    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => { 
      this.adminFlag = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });

    this.fetch();
  }

  /**
   * Fetch current user validator status and details about the results.
   */

  fetch() {
    if (this.statusSub$) this.statusSub$.unsubscribe();

    this.statusSub$ = this.validator.getStatus().subscribe(
      (data) => {
        this.status = data.Body.status;
        
        if (this.status.Updatestatus == "Update Complete") {
          if (data.Body.tasktypes.length == 0) {
            // Complete, but no checklists found
            this.uploadStatus = UploadStatusEnum.ERROR;
            this.status.Updatestatus = "Error: No Checklist Found";
          } else {
            // Complete with checklists found
            this.uploadStatus = UploadStatusEnum.COMPLETE;
          }
        } else if (this.status.Updatestatus == "No Validation Result") {
          this.uploadStatus = UploadStatusEnum.NONE;
        } else if (this.status.Updatestatus.toUpperCase().includes("ERROR")) {
          this.uploadStatus = UploadStatusEnum.ERROR;
        } else {
          this.uploadStatus = UploadStatusEnum.INPROCESS;
        }

        if (this.uploadStatus == UploadStatusEnum.COMPLETE) {
          this.processTaskTypes(data.Body.tasktypes);
        } else {
          this.taskTypes = [];
        }

        if (this.uploadStatus == UploadStatusEnum.COMPLETE || this.uploadStatus == UploadStatusEnum.ERROR) {
          this.statusSub$.unsubscribe();
        }
      },
      (err) => {
        console.error("Error retrieve Status", err);
      }
    )
  }

  /**
   * Break up the task types for easier display in grouping by task type.
   * 
   * @param myTaskTypes An array of valid task types
   */
  processTaskTypes(myTaskTypes:ValidatorTaskType[]) {
    let uniqueHeadings = new Set(myTaskTypes.map( obj => obj.heading));

    this.taskTypes = [];

    uniqueHeadings.forEach((heading) => {
      let taskType:tasks = {
        section: this.headingDisplay[heading].display,
        sort: this.headingDisplay[heading].sort,
        tasks: myTaskTypes.filter(obj => { return obj.heading===heading})
      };

      this.taskTypes.push(taskType);

    });

    this.taskTypes = this.taskTypes.sort( (a,b) => a.sort < b.sort ? -1 : a.sort > b.sort ? 1 : 0 )
  }

  uploadStart(fileName:string) {
    this.uploadStatus = UploadStatusEnum.INPROCESS;
    this.status.Updatestatus = `Uploading: Started - ${fileName}`;
  }

  uploadComplete(fileName:string) {
    this.uploadStatus = UploadStatusEnum.INPROCESS;
    this.status.Updatestatus = `Uploading: Complete - ${fileName}`;
    this.fetch();
  }

  navToResults(taskType:string) {
    this.router.navigate(["admin/validator/results", taskType]);

  }

  ngOnDestory() {
    if (this.statusSub$) this.statusSub$.unsubscribe();
  }

}
