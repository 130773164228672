import { Component, OnInit } from '@angular/core';
import {TpmService} from "src/app/dashboard/services/tpm.service";

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.sass']
})
export class ProgressSpinnerComponent implements OnInit {
  isLoading: boolean;
  constructor(private tpmService:TpmService) { }

  ngOnInit() {
    this.tpmService.isLoading.subscribe(value => {
      this.isLoading = value;
    })
  }

}
