import { Component, Input } from '@angular/core';
import { ChartDataset, ChartType, ChartOptions } from 'chart.js';

@Component({
  selector: 'gea-mes-piechart',
  templateUrl: './gea-mes-piechart.component.html',
  styleUrls: ['./gea-mes-piechart.component.scss']
})
export class GeaMesPiechartComponent {
  @Input() checklistCompletion: number[];

  pieChartType: ChartType = 'pie';

  pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    elements:{
      arc: {
        borderWidth: 1,
        borderColor: 'gray'
      }
    }
  };

  pieChartLabels: String[] = ['Completed', 'Completed With Issue', 'Not Completed', 'Did Not Complete'];

  pieChartData: ChartDataset[] = [{ 
                                    data: [], // Will be set in ngOnInit
                                    backgroundColor: ['green', 'red', '#f2f4f4', 'aqua']
                                  }];
  pieChartLegend = false;



  constructor() {}

  ngOnInit() {
    this.pieChartData[0].data = this.checklistCompletion;
  }
}
