import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentIssueDetailsService } from '../../services/equipment-issue-details.service';
import { pluck, } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-equipment-issue-details',
  templateUrl: './equipment-issue-details.component.html',
  styleUrls: ['./equipment-issue-details.component.scss']
})
export class EquipmentIssueDetailsComponent implements OnInit {

  completedSectionMetricsObservable: Observable<any>;
  completedSectionMetricsSubscription: Subscription;
  getSectionDataObservable: Observable<any>;
  getSectionDataSubscription: Subscription;
  getSiteMenuDataObservable: Observable<any>;
  getSiteMenuDataSubscription: Subscription;
  obj: string;
  dateRangeStart: string;
  dateRangeEnd: string;
  displayName: string;
  currentSite: string;
  issueArray: any[];
  siteMenuData: any;
  sectionMenuData: any;
  currentObject: string;
  currentTaskType: string;
  mDataSource: {};
  ObjectKeys = Object.keys;
  typeTaskData: string[]; 
  currentDescription: string;
  fromDateString: string;
  toDateString: string;
  section: string;
  subsection:string;
  
  constructor(private activatedRoute: ActivatedRoute,
    private equipmentIssueDetailsService: EquipmentIssueDetailsService,
    private router: Router) {
    this.currentSite = this.activatedRoute.snapshot.paramMap.get('site')
    this.currentObject = this.activatedRoute.snapshot.paramMap.get('object')
    this.currentTaskType = this.activatedRoute.snapshot.paramMap.get('tasktype')
    this.typeTaskData = ['ALL', 'DAILY', 'WEEK', 'BIWEEKLY', 'MONTH']
   }

  ngOnInit() {
    this.fetch()
  }

  ngOnDestroy(){
    this.completedSectionMetricsSubscription.unsubscribe()
  }

  fetch(){
    this.getIssueDetails();
  }


  getIssueDetails(){
    if (this.completedSectionMetricsSubscription) this.completedSectionMetricsSubscription.unsubscribe();

    this.completedSectionMetricsObservable = this.equipmentIssueDetailsService.getObjIssueDetials(this.fromDateString, this.toDateString, this.currentSite, this.currentObject)
    
    this.completedSectionMetricsSubscription = this.completedSectionMetricsObservable.subscribe((data) =>{
      let filetereData = this.filterTaskType(data)
      this.issueArray = filetereData
      if (this.issueArray.length == 0) {
        this.currentDescription = ''
      } else {
        this.currentDescription = this.issueArray[0].ManualDescription
      }

      if (filetereData.length == 0) {
        this.displayName = ''
        this.section = ''
        this.subsection = ''
      } else {
        this.displayName = filetereData[0]['DisplayName']
        this.section = filetereData[0]['Section']
        this.subsection = filetereData[0]['SubSection']
      }
    })

     
  }

  filterTaskType(issueDetailData: any): any{
    let filIssueData = []
    issueDetailData = issueDetailData.sort((a,b) => (a.shift_date < b.shift_date) ? 1 : null ? ((a.week < b.week) ? 1 : null ? ((a.month < b.month) ? 1 : -1) : -1) : -1)
    if (this.currentTaskType == 'ALL'){
      filIssueData = issueDetailData
    } else {
      filIssueData = issueDetailData.filter(rData => rData.task_type == this.currentTaskType)
    }
    return filIssueData
  }

  changeTaskType(taskType: string){
    this.currentTaskType = taskType

    let buildUrl = "/dashboard/equipment-issues/" + this.currentSite + '/' + this.currentObject + '/' + this.currentTaskType
    this.router.navigate([buildUrl])
    this.getIssueDetails();

  }

  navToEquipOver(){
    let buildUrl:string = '';
    if (this.subsection == null){
      buildUrl = "/dashboard/equipment-overview/" + this.displayName + '/' + this.section 
    }
    else{
      buildUrl = "/dashboard/equipment-overview/" + this.displayName + '/' + this.section + '/' + this.subsection
    }
    this.router.navigate([buildUrl])
  }

  onDateSelection(e) {
    let utcOffset:number = dayjs(e.start).utcOffset() * -1;

    this.fromDateString = dayjs(e.start).add(utcOffset,'minute').format('YYYY-MM-DD');
    this.toDateString = dayjs(e.end).format('YYYY-MM-DD');

    console.debug('Selected Date Range: ' + this.fromDateString + ' - ' + this.toDateString);

    this.fetch();
  }

  
}
