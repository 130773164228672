import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ObjectListService, ObjectNode } from '../services/object-list.service';
import { ObjectDescriptionService } from '../services/object-description.service';
import { Observable, Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';

@Component({
  selector: 'app-object-list',
  templateUrl: './object-list.component.html',
  styleUrls: ['./object-list.component.scss']
})
export class ObjectListComponent implements OnInit {

  mDataSource: {};
  expandedNodes: ObjectNode[] = [];
  plantExist: boolean;
  plantName: string;

  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])

  treeControl = new NestedTreeControl<ObjectNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ObjectNode>();
  subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private service: ObjectListService,
    private objDescriptService: ObjectDescriptionService) {
  }

  hasChild = (_: number, node: ObjectNode) => !!node.children && node.children.length > 0;  

  ngOnInit() {
    
    if (this.route.snapshot.paramMap.get("plant") !== undefined && this.route.snapshot.paramMap.get("plant") !== null){      
      this.plantExist = true;          
    }
    else {
      this.plantExist = false;
    }
    
    this.subscription = this.service.objectList().subscribe((
      out => {
        this.objDescriptService.updateObjList(out)
        
        if(this.plantExist){          
          this.plantName = this.route.snapshot.paramMap.get("plant");
          this.dataSource.data = out.Body.filter(x => [this.plantName].includes(x.description));
        }         
        else{ 
          this.dataSource.data = out.Body;
        }
        console.log("output", this.dataSource.data);

        if (this.route.snapshot.paramMap.get("plant") !== undefined && this.route.snapshot.paramMap.get("plant") !== null){
          this.expandTree("plant", out.Body);
        }
        
        if(this.expandedNodes.length == 1 && this.route.snapshot.paramMap.get("section") !== undefined && this.route.snapshot.paramMap.get("section") !== null){
          this.expandTree("section", this.expandedNodes[0].children);
        }

        if(this.expandedNodes.length == 2 && this.route.snapshot.paramMap.get("subsection") !== undefined && this.route.snapshot.paramMap.get("subsection") !== null){
          this.expandTree("subsection", this.expandedNodes[1].children);
        }        
      }
    ));
  }

  navigate(site: string, environment: string, objectid: string, eamAsset: string) {
    eamAsset == "" ? eamAsset = 'None' : eamAsset = eamAsset

    this.router.navigate(["/tasklist",site,environment,objectid]);
  }

  navigatePlant(isplant: boolean, site:string){
    if (isplant) {
      this.plantName = site;
      this.router.navigate(["/home",site]);
    }
  }

  expandTree(param: string, nodes: ObjectNode[]){
    if (this.route.snapshot.paramMap.get(param) !== undefined){
      var valid = false;
      var expandedNode: ObjectNode;

      nodes.forEach(node => {
        var routeParam: string = this.route.snapshot.paramMap.get(param);

        if(node.description.toLowerCase() == routeParam.toLowerCase()){
          expandedNode = node;
          valid = true;
        }
      });

      if(valid){
        console.debug("Valid " + param + " parameter supplied, expanding " + this.route.snapshot.paramMap.get(param));
        this.treeControl.toggle(expandedNode);
        this.expandedNodes.push(expandedNode);
      }else{
        console.warn("Invalid " + param + " parameter provided");
      }
    }
  }
  
  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }  
}