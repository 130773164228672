import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class ObjectListService extends AbstractRestService {

  postInit(): void {
    console.log("Post INIT!");
  }

  objectList(site?: string, environment?: string):Observable<GenericLambdaReturn<ObjectNode[]>>  {
    let params:any={};

    site ? params.Site = site.toString():null;
    environment ? params.Environment = environment.toString():null;

    return this.get({
      ApiResource: "/objectlist",
      data: params,
      repeatCall: false
    });
  }

  objectListSubscription(site?: string, environment?: string):Observable<GenericLambdaReturn<ObjectNode[]>>  {
    let params:any={};
    console.log('object-list service ', site, environment)
    site ? params.Site = site.toString():null;
    environment ? params.Environment = environment.toString():null;

    return this.get({
      ApiResource: "/objectlist",
      data: params,
      repeatCall: true
    });    
  }  
}

export interface ObjectNode {
  description: string;
  objectid?: string;
  plantid?: boolean;  
  site?: string;
  environment?: string;
  updatestatus?: string;
  EAMAsset?: string;
  children?: ObjectNode[];
  loadtime: number;
  RequireComment: number;
}
