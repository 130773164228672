
import { Component, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CurrentOverviewStatusService, OverviewStatus } from '../../services/current-overview-status.service'
import { ObjectDescriptionService } from 'src/app/services/object-description.service';
import { Observable, Subscription } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import {TpmService} from "src/app/dashboard/services/tpm.service";


@Component({
  selector: 'current-overview-status',
  templateUrl: './current-overview-status.component.html',
  styleUrls: ['./current-overview-status.component.scss']
})
export class CurrentOverviewStatusComponent implements OnInit {
  displayedColumns: string[] = ["DisplayName", "Shift1", "Shift2", "Shift3", "WeeklyPieChart", "BiweeklyPieChart", "MonthlyPieChart"];
  dataSource: MatTableDataSource<OverviewStatus>;
  CurrentOverciewObservable: Observable<any>;
  CurrentOverviewSubscription: Subscription;
  siteMenuData: any;
  sectionMenuData: any;
  subSectionMenuData: any;
  currentDate = new Date();
  isLoading: boolean= false;

  menuData: any;

  getSectionDataObservable: Observable<any>;
  getSectionDataSubscription: Subscription;

  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])

  // selectedSite can be changed to a site name (ex. AP1) to show charts for that site
  // with a site selected, can change selectedSection to section name (ex. Fabrication) to show charts for that section
  selectedSite: string = '';
  selectedSection: string = '';
  selectedSubSection: string = '';

  constructor(private router: Router,
              private breakpointObserver: BreakpointObserver,
              private currentOverviewStatusService: CurrentOverviewStatusService,
              private objDescriptionService: ObjectDescriptionService,
              private tpmService: TpmService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    let a : any 
    console.log("INITIALIZE CURRENT OVERVIEW!!!!");
    this.dataSource = new MatTableDataSource<OverviewStatus>();
    this.tpmService.isLoading.next(true);
    this.fetch();
  }

  fetch(): void{
    if (this.route.snapshot.paramMap.get("plant") !== undefined && this.route.snapshot.paramMap.get("plant") !== null){      
      this.selectedSite = this.route.snapshot.paramMap.get("plant");      
    }
    if (this.route.snapshot.paramMap.get("section") !== undefined && this.route.snapshot.paramMap.get("section") !== null){      
      this.selectedSection = this.route.snapshot.paramMap.get("section");      
    }
    if (this.route.snapshot.paramMap.get("subsection") !== undefined && this.route.snapshot.paramMap.get("subsection") !== null){      
      this.selectedSubSection = this.route.snapshot.paramMap.get("subsection"); 
    }
    this.getData();
    this.getMenu();
  }

  getData(): void {
    if (this.CurrentOverviewSubscription) this.CurrentOverviewSubscription.unsubscribe();

    if (this.selectedSite == '' && this.selectedSection == '') {
      this.CurrentOverciewObservable = this.currentOverviewStatusService.getCurrentOverviewStatusData().pipe(pluck("Body"));
    }
    else if (this.selectedSubSection != '') {
      this.CurrentOverciewObservable = this.currentOverviewStatusService.getCurrentOverviewStatusData(this.selectedSite, this.selectedSection, this.selectedSubSection).pipe(pluck("Body"));
    }
    else if (this.selectedSection != '') {
      this.CurrentOverciewObservable = this.currentOverviewStatusService.getCurrentOverviewStatusData(this.selectedSite, this.selectedSection).pipe(pluck("Body"));
    } 
    else if (this.selectedSite != '') {
      this.CurrentOverciewObservable = this.currentOverviewStatusService.getCurrentOverviewStatusData(this.selectedSite).pipe(pluck("Body"));
    }
    
    
    this.CurrentOverviewSubscription = this.CurrentOverciewObservable.subscribe((tableData) => {
      let tempTableData: OverviewStatus[];
      tempTableData = tableData;
      this.dataSource = new MatTableDataSource(tempTableData);
      this.currentDate = new Date();
      this.tpmService.isLoading.next(false); 
    })

  }

  getMenu() {
    this.objDescriptionService.getSiteSectionList().subscribe((siteData) =>{
      if (siteData != null){
        console.log("LOADED THE SITE DATA!!!", siteData);
        this.menuData = siteData;
        this.getSectionMenuData();
        this.getSubSectionMenuData();
       }
    }) 
  /*  this.tpmService.isLoading.next(false);    */
  }

  getSectionMenuData(){
    if (this.selectedSite != "") {
      this.sectionMenuData = this.menuData.find( ({ site }) => site === this.selectedSite )['sections'];
    }

  }

  getSubSectionMenuData(){
    if (this.selectedSubSection) {
      this.subSectionMenuData = this.menuData.find(({site}) => site === this.selectedSite)['subSections'];
    }
  }

  onClick(value, objectid) {
    if (this.selectedSite == "") {
      this.navigate(value);
    }
    else if (this.selectedSection == "") {
      this.navigate(this.selectedSite, value);
    } else if (this.selectedSubSection == "") {
      this.navigate(this.selectedSite, this.selectedSection, value)
    }
  }

  changeSection(sectionData) {
    this.selectedSection = sectionData;
    this.selectedSubSection = "";
    this.getData();
    this.getSubSectionMenuData()
  }

  changeSubSection(subSection) {
    this.selectedSubSection = subSection
    this.getData();
  }

  changeSite(siteData) {
    this.selectedSection = "";
    this.selectedSubSection = "";
    this.selectedSite = siteData;
    this.getData();
    this.getSectionMenuData();
  }

  navigate(site?:string, section?: string, subSection?: string) {
    site != null ? this.selectedSite=site:this.selectedSite ="";
    section != null ? this.selectedSection=section:this.selectedSection ="";
    subSection != null ? this.selectedSubSection = subSection : this.selectedSubSection = "";
    this.getData();
    this.getSectionMenuData();
    this.getSubSectionMenuData();
  }

  ngOnDestroy() {
    this.CurrentOverviewSubscription.unsubscribe();
  }
}
