
<mat-card  class="current-complete-card mat-elevation-z8">
 
    <mat-card-header>
      <mat-card-title>Active Shift Checklists Status for {{currentDate | date:'MM-dd-yyyy'}}</mat-card-title>
      <mat-card-subtitle>

        <mat-accordion>
          <mat-expansion-panel expanded=false>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Legend
              </mat-panel-title>
            </mat-expansion-panel-header>
           <mat-spinner value="100" *ngIf="isLoading" class="spinner"></mat-spinner>
            <mat-card class="mat-elevation-z8">
              <div class="fx-row-start-center">
                <div style="min-width: 30%; max-width: 30%; text-align: center;"><gea-mes-piechart [checklistCompletion]="[25,25,25,25]"></gea-mes-piechart></div>
                <div>Active Checklist exist. The pie chart will have up to three slices.
                  <ul>
                    <li>Gray - Percentage of tasks not started</li>
                    <li>Green - Percentage of tasks Completed (OK)</li>
                    <li>Aqua - Percentage of tasks marked Did Not Complete</li>
                    <li>Red - Percentage of tasks Completed with Issues (NOK)</li>
                  </ul>
                </div>
              </div>
            </mat-card>
            <mat-card class="mat-elevation-z8">
              <div class="fx-row-start-center">
                <div style="min-width: 30%; max-width: 30%; text-align: center; "><mat-icon  style="transform: scale(2);" matTooltip="No Proficy Schedule">event_busy</mat-icon></div>
                <div>No Schedule Loaded in Proficy for this time period.</div>
              </div>
            </mat-card>            
            <mat-card class="mat-elevation-z8">
              <div class="fx-row-start-center">
                <div style="min-width: 30%; max-width: 30%; text-align: center;" matTooltip="No Applicable Checklist"><div id="not-applicable">N/A</div></div>
                <div>No applicable checklist for this time period.</div>
              </div>
            </mat-card>                        
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card-subtitle>
    </mat-card-header>

        <button mat-button color="primary" (click)="navigate()">All Sites</button>
        <button mat-button *ngIf="selectedSite != ''" [matMenuTriggerFor]="menu" class="siteSelectButton" color="primary">{{selectedSite}}<mat-icon>arrow_drop_down</mat-icon></button>
        <button mat-button *ngIf="selectedSection != ''" [matMenuTriggerFor]="menu2" class="siteSelectButton" color="primary">{{selectedSection}}<mat-icon>arrow_drop_down</mat-icon></button>
        <button mat-button *ngIf="selectedSubSection != ''" [matMenuTriggerFor]="subSectionMenu" class="siteSelectButton" color="primary">{{selectedSubSection}}<mat-icon>arrow_drop_down</mat-icon></button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngFor="let siteData of menuData" (click)="changeSite(siteData.site)">{{siteData.site}}</button>
        </mat-menu>  
        <mat-menu #menu2="matMenu">
          <button mat-menu-item *ngFor="let sectionData of sectionMenuData" (click)="changeSection(sectionData)">{{sectionData}}</button>
        </mat-menu>
        <mat-menu #subSectionMenu="matMenu">
          <button mat-menu-item *ngFor="let subSectionData of subSectionMenuData" (click)="changeSubSection(subSectionData)">{{subSectionData}}</button>
        </mat-menu>
    <!-- DESKTOP VIEW -->
    <div *ngIf="(isScreen$ | async)?.matches">
      <table mat-table [dataSource]="dataSource" class="current-complete mat-elevation-z2">
        <ng-container matColumnDef="DisplayName">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element" (click)="onClick(element.DisplayName, element.ObjectID)">
                <button  *ngIf="element.ObjectID == ''" mat-button color="primary">{{element.DisplayName}}</button>
                <a *ngIf="element.ObjectID != ''" [routerLink]="['/tasklist/', element.Site, element.Environment, element.ObjectID]">{{element.DisplayName}}</a>
            </td>
        </ng-container>

        <ng-container matColumnDef="DailyPieChart">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Daily</th>
            <td mat-cell *matCellDef="let element" style="text-align: center;">
                <div *ngIf="element.ShiftTotal == 0 && element.ShiftConfigStatus != 0" style="display: block; "><mat-icon  style="transform: scale(2);" matTooltip="No Proficy Schedule">event_busy</mat-icon></div>
                <div *ngIf="element.ShiftConfigStatus == 0" style="display: block;" matTooltip="No Applicable Checklist"><div id="not-applicable">N/A</div></div>
                <gea-mes-piechart *ngIf="element.ShiftTotal > 0" [checklistCompletion]="[(element.ShiftCompletedPercentage*100).toFixed(2), (element.ShiftCompletedWithIssuePercentage*100).toFixed(2), (element.ShiftNotCompletedPercentage*100).toFixed(2), (element.ShiftDidNotCompletePercentage*100).toFixed(2)]"></gea-mes-piechart>
            </td>
        </ng-container>

        <ng-container matColumnDef="WeeklyPieChart">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Weekly</th>
            <td mat-cell *matCellDef="let element" style="text-align: center;">
                <div *ngIf="element.WeeklyTotal == 0 && element.WeeklyConfigStatus != 0" style="display: block;"><mat-icon style="transform: scale(2);" matTooltip="No Proficy Schedule">event_busy</mat-icon></div>
                <div *ngIf="element.WeeklyConfigStatus == 0" style="display: block;" matTooltip="No Applicable Checklist"><div id="not-applicable">N/A</div></div>
                <gea-mes-piechart *ngIf="element.WeeklyTotal > 0" [checklistCompletion]="[(element.WeeklyCompletedPercentage*100).toFixed(2), (element.WeeklyCompletedWithIssuePercentage*100).toFixed(2), (element.WeeklyNotCompletedPercentage*100).toFixed(2), (element.WeeklyDidNotCompletePercentage*100).toFixed(2)]"></gea-mes-piechart>
            </td>
        </ng-container>

        <ng-container matColumnDef="BiweeklyPieChart">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;">Biweekly</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;">
              <div *ngIf="element.BiweeklyTotal == 0 && element.BiweeklyConfigStatus != 0" style="display: block;"><mat-icon style="transform: scale(2);" matTooltip="No Proficy Schedule">event_busy</mat-icon></div>
              <div *ngIf="element.BiweeklyTotal == 0" style="display: block;" matTooltip="No Applicable Checklist"><div id="not-applicable">N/A</div></div>
              <gea-mes-piechart *ngIf="element.BiweeklyTotal > 0" [checklistCompletion]="[(element.BiweeklyCompletedPercentage*100).toFixed(2), (element.BiweeklyCompletedWithIssuePercentage*100).toFixed(2), (element.BiweeklyNotCompletedPercentage*100).toFixed(2), (element.BiweeklyDidNotCompletePercentage*100).toFixed(2)]"></gea-mes-piechart>
          </td>
        </ng-container>

        <ng-container matColumnDef="MonthlyPieChart">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Monthly</th>
            <td mat-cell *matCellDef="let element" style="text-align: center;">
                <div *ngIf="element.MonthlyTotal == 0 && element.MonthlyConfigStatus != 0" style="display: block;"><mat-icon style="transform: scale(2);" matTooltip="No Proficy Schedule">event_busy</mat-icon></div>
                <div *ngIf="element.MonthlyConfigStatus == 0" style="display: block;" matTooltip="No Applicable Checklist"><div id="not-applicable">N/A</div></div>
                <gea-mes-piechart *ngIf="element.MonthlyTotal > 0" [checklistCompletion]="[(element.MonthlyCompletedPercentage*100).toFixed(2), (element.MonthlyCompletedWithIssuePercentage*100).toFixed(2), (element.MonthlyNotCompletedPercentage*100).toFixed(2), (element.MonthlyDidNotCompletePercentage*100).toFixed(2)]"></gea-mes-piechart>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>

    <!-- MOBILLE VIEW-->
    <div *ngIf="(isTablet$ | async)?.matches">
      <mat-card *ngFor="let element of dataSource.data" class="current-complete-card mat-elevation-z8">
        <mat-card-header>
          <mat-card-title (click)="onClick(element.DisplayName, element.ObjectID)">
            <button  *ngIf="element.ObjectID == ''" mat-button color="primary">{{element.DisplayName}}</button>
            <a *ngIf="element.ObjectID != ''" [routerLink]="['/tasklist/', element.Site, element.Environment, element.ObjectID]">{{element.DisplayName}}</a>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-divider></mat-divider>
          <div class="fx-row">
            <div  style="min-width: 20%; max-width: 30%;">
              <div class="fx-column-center-center">
                <div>
                  <h3 class="mat-3">Daily</h3>
                </div>
                <div>
                  <div *ngIf="element.ShiftTotal == 0 && element.ShiftConfigStatus != 0" style="display: block; "><mat-icon  style="transform: scale(2);" matTooltip="No Proficy Schedule">event_busy</mat-icon></div>
                  <div *ngIf="element.ShiftConfigStatus == 0" style="display: block;" matTooltip="No Applicable Checklist"><div id="not-applicable">N/A</div></div>
                  <gea-mes-piechart *ngIf="element.ShiftTotal > 0" [checklistCompletion]="[(element.ShiftCompletedPercentage*100).toFixed(2), (element.ShiftCompletedWithIssuePercentage*100).toFixed(2), (element.ShiftNotCompletedPercentage*100).toFixed(2), (element.ShiftDidNotCompletePercentage*100).toFixed(2)]"></gea-mes-piechart>
                </div>
              </div>
            </div>
            <div  style="min-width: 20%; max-width: 30%;">
              <div class="fx-column-center-center">
                <div>
                  <h3 class="mat-3">Weekly</h3>
                </div>
                <div>
                  <div *ngIf="element.WeeklyTotal == 0 && element.WeeklyConfigStatus != 0" style="display: block;"><mat-icon style="transform: scale(2);" matTooltip="No Proficy Schedule">event_busy</mat-icon></div>
                  <div *ngIf="element.WeeklyConfigStatus == 0" style="display: block;" matTooltip="No Applicable Checklist"><div id="not-applicable">N/A</div></div>
                  <gea-mes-piechart *ngIf="element.WeeklyTotal > 0" [checklistCompletion]="[(element.WeeklyCompletedPercentage*100).toFixed(2), (element.WeeklyCompletedWithIssuePercentage*100).toFixed(2), (element.WeeklyNotCompletedPercentage*100).toFixed(2), (element.WeeklyDidNotCompletePercentage*100).toFixed(2)]"></gea-mes-piechart>
                </div>
              </div>
            </div>
            <div  style="min-width: 20%; max-width: 30%;">
              <div class="fx-column-center-center">
                <div>
                  <h3 class="mat-3">Biweekly</h3>
                </div>
                <div>
                  <div *ngIf="element.BiweeklyTotal == 0 && element.BiweeklyConfigStatus != 0" style="display: block;"><mat-icon style="transform: scale(2);" matTooltip="No Proficy Schedule">event_busy</mat-icon></div>
                  <div *ngIf="element.BiweeklyTotal == 0 || !element.BiweeklyTotal" style="display: block;" matTooltip="No Applicable Checklist"><div id="not-applicable">N/A</div></div>
                  <gea-mes-piechart *ngIf="element.BiweeklyTotal > 0" [checklistCompletion]="[(element.BiweeklyCompletedPercentage*100).toFixed(2), (element.BiweeklyCompletedWithIssuePercentage*100).toFixed(2), (element.BiweeklyNotCompletedPercentage*100).toFixed(2), (element.BiweeklyDidNotCompletePercentage*100).toFixed(2)]"></gea-mes-piechart>
                </div>
              </div>
            </div>
            <div  style="min-width: 20%; max-width: 30%;">
              <div class="fx-column-center-center">
                <div>
                  <h3 class="mat-3">Monthly</h3>
                </div>
                <div>
                  <div *ngIf="element.MonthlyTotal == 0 && element.MonthlyConfigStatus != 0" style="display: block;"><mat-icon style="transform: scale(2);" matTooltip="No Proficy Schedule">event_busy</mat-icon></div>
                  <div *ngIf="element.MonthlyConfigStatus == 0" style="display: block;" matTooltip="No Applicable Checklist"><div id="not-applicable">N/A</div></div>
                  <gea-mes-piechart *ngIf="element.MonthlyTotal > 0" [checklistCompletion]="[(element.MonthlyCompletedPercentage*100).toFixed(2), (element.MonthlyCompletedWithIssuePercentage*100).toFixed(2), (element.MonthlyNotCompletedPercentage*100).toFixed(2), (element.MonthlyDidNotCompletePercentage*100).toFixed(2)]"></gea-mes-piechart>
                </div>
              </div>
            </div>            
          </div>
        </mat-card-content>
      </mat-card>
    </div>    

  </mat-card>



