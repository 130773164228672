import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class EquipmentIssueDetailsService extends AbstractRestService{
  postInit(): void {
    console.log("Post INIT!");
  }

  getObjIssueDetials(start: string, end: string, site: string, objID: string, taskType?: string):Observable<GenericLambdaReturn<any>> 
  {
    let params:any={};
    console.log(start, end, site, objID, taskType)

    start ? params.DateRangeStart = start:null
    end ? params.DateRangeEnd = end:null
    site ? params.Site = site:null
    objID ? params.ObjectID = objID:null
    taskType ? params.TaskType = taskType:null

    return this.get({
      ApiResource: "/dashequipissuesdetails",
      data: params
    })    
  }

}

export type completedMetrics = {
  Site: string,
  ObjectID: string,
  Section: string,
  SubSection: string,
  task_type: string,
  Completed: number,
  Total: number,
  Status: string
}
