import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GEA_MES_NAVBAR_APPLICATION_NAME,GEA_MES_NAVBAR_MENU_SERVICE,GEA_MES_NAVBAR_ENV_PROD_STATUS, GEA_ENVIROMENT, NavItem} from '@gea-mes/navbar';
import {BehaviorSubject} from 'rxjs';
import { GeaMesLoginComponent, GeaMesCognitoAuthGuard, GeaMesCognitoModule, GeaMesAuthInterceptor, GeaMesCognitoAuthService ,COGNITO_CONFIG_HA, APPLICATION_NAME, ALLOW_SCREENAUTH, APIGATEWAY_CONFIG } from '@gea-mes/cognito';
import { LocationStrategy, PathLocationStrategy, APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { ObjectListComponent } from './object-list/object-list.component';
import { TaskListComponent } from './task-list/task-list.component';
import { AdminComponent } from './admin/components/admin/admin.component';
import { LogOutPageComponent } from './log-out-page/log-out-page.component';
import { environment } from 'src/environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';
import { CompletedOverviewComponent } from './dashboard/components/completed-overview/completed-overview.component';
import { EquipmentOverviewComponent } from './dashboard/components/equipment-overview/equipment-overview.component';
import { EquipmentIssueDetailsComponent } from './dashboard/components/equipment-issue-details/equipment-issue-details.component';
import { EquipmentStatusDetailsComponent } from './dashboard/components/equipment-status-details/equipment-status-details.component';
import { ShiftListComponent } from './shift-list/shift-list.component';
import { CurrentOverviewStatusComponent } from './dashboard/components/current-overview-status/current-overview-status.component';
import { ValidatorStatusComponent } from './admin/components/validator-status/validator-status.component';
import { ValidatorResultsComponent } from './admin/components/validator-results/validator-results.component';
import { appinfo } from './appinfo';
import { SectionOverviewComponent } from './dashboard/components/section-overview/section-overview.component';
import { AdminTaskListComponent } from './admin/components/admin-task-list/admin-task-list.component';

const appRoutes: Routes = [
  {path: 'login', component: GeaMesLoginComponent},
  {path: 'logout', component: LogOutPageComponent},
  {
    path: '', canActivate: [GeaMesCognitoAuthGuard],
    children: [
      {path: '', redirectTo:'/home', pathMatch: 'full'},
      {path: 'home', component: ObjectListComponent},
      {path: 'home/:plant', component: ObjectListComponent},
      {path: 'home/:plant/:section', component: ObjectListComponent},
      {path: 'home/:plant/:section/:subsection', component: ObjectListComponent},
      {path: 'admin', component: AdminComponent},
      {path: 'admin/:plant', component: AdminComponent},
      {path: 'admin/tasklist/:plant/:site/:environment/:object', component: AdminTaskListComponent},
      {path: 'admin/validator', component: ValidatorStatusComponent},
      {path: 'admin/validator/results/:tasktype', component: ValidatorResultsComponent},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'dashboard/overview-metrics', component: CompletedOverviewComponent},
      {path: 'dashboard/section-overview/:site', component: SectionOverviewComponent},
      {path: 'dashboard/equipment-overview/:site/:section/:subSection', component: EquipmentOverviewComponent},
      {path: 'dashboard/equipment-overview/:site/:section', component: EquipmentOverviewComponent},
      {path: 'dashboard/equipment-overview/:site', component: EquipmentOverviewComponent},
      {path: 'dashboard/equipment-issues/:site/:object/:tasktype', component: EquipmentIssueDetailsComponent},
      {path: 'dashboard/equipment-status-details/:site/:env/:object', component: EquipmentStatusDetailsComponent},
      {path: 'objectlist', component: ObjectListComponent},
      {path: 'tasklist/:site/:environment/:object', component: ShiftListComponent},
      {path: 'tasklist/:site/:environment/:object/:mode/:checklistType', component: TaskListComponent},
      {path: 'currentoverviewstatus', component: CurrentOverviewStatusComponent}
    ]
  },
  {path: '**', redirectTo:'/home' }
];

export function getBaseHref(platformLocation: PlatformLocation): string {
    return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  imports: [GeaMesCognitoModule, RouterModule.forRoot(appRoutes)],
  providers: [
    { provide: COGNITO_CONFIG_HA, useValue: environment.cognitoOptionsHA },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: GeaMesAuthInterceptor, multi: true },
    { provide: APPLICATION_NAME, useValue: appinfo.name.toUpperCase() },
    { provide: APIGATEWAY_CONFIG, useValue: environment.apiGateway },
    { provide: ALLOW_SCREENAUTH, useValue: environment.AllowScreenAuth },
    { provide: GEA_MES_NAVBAR_APPLICATION_NAME,useValue:appinfo.name.toUpperCase()}, 
     { provide: GEA_MES_NAVBAR_ENV_PROD_STATUS, useValue: environment.production },
     { provide: GEA_ENVIROMENT, useValue: environment },
     { provide: GEA_MES_NAVBAR_MENU_SERVICE, useValue: new BehaviorSubject<NavItem[]>([]) }    

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
