import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, Inject} from '@angular/core';

@Component({
    selector: 'clear-task-dialog',
    templateUrl: './clear-task-dialog.html',
    styleUrls: ['./task-list.component.scss']
  })
  export class ClearTaskDialog {
    taskItemData: task = {
      comment: '',
      correctiveAction: '',
      tagno: '',
      deleteFlag: false,
      status: 0
    }

  
    constructor(public dialogRef: MatDialogRef<ClearTaskDialog>,
      @Inject(MAT_DIALOG_DATA) public taskData: task) {
        this.taskItemData.comment = this.taskData.comment ? '':this.taskData.comment
        this.taskItemData.correctiveAction = this.taskData.correctiveAction ? '':this.taskData.correctiveAction
        this.taskItemData.tagno = this.taskItemData.tagno ? '':this.taskData.tagno
        this.taskItemData.deleteFlag = false
        this.taskItemData.status = this.taskData.status
      }

    submitClear(){
      this.taskItemData.deleteFlag = true
      this.dialogRef.close(this.taskItemData);
    }
  
    onNoClick(): void {
      this.taskItemData.deleteFlag = false
      this.dialogRef.close(this.taskItemData);
    }
  }
  
  
  export interface task {
    comment: string;
    correctiveAction: string;
    tagno: string;
    deleteFlag: boolean;
    status: number
  }