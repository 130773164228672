<div style="display:flex; flex-direction: row; gap: 400px;">
    <div>
        
    </div>
</div>
<div mat-dialog-content>
    <h3 mat-dialog-title>{{task.task}}</h3>
    <div *ngIf="fileList && fileList.length > 0">
        <ng-container *ngFor="let file of fileList">
            <div class="file-block">
                <span *ngIf="file.s3_filename.split('.').pop() != 'pdf'"><img class="small-size" [src]="file.s3link" (click)="zoomImage(file.s3link)"/></span>
            
                <span *ngIf="file.s3_filename.split('.').pop() == 'pdf'"><a [href]="file.s3link">Open Detailed Instructions</a></span>            
            </div>
        </ng-container>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-raised-button (click)="close()">Close</button>
</div>

<div *ngIf="largeImage" class="large-image" (click)="largeImage = ''">
    <div>
        <div class="close-button">
            <button mat-icon>X</button>
        </div>
        <img class="large-size" [src]="largeImage"/>
    </div>
</div>
