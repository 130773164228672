import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, Inject, OnInit} from '@angular/core';
import { ForceReloadService } from '../../services/force-reload.service';
import { pluck, map } from 'rxjs/operators';



export interface checklist{
  task_type: string,
  shift: string, 
  shift_date: string, 
  week: string, 
  month: string,
  checked: boolean,
  site: string,
  objEnv: string,
  objID: string,
  completed: number
}

@Component({
    selector: 'manual-load-dialog',
    templateUrl: './manual-load-dialog.html',
    styleUrls: ['./manual-load-dialog.scss'], 
  })
  export class ManualLoadDialog implements OnInit {
    objData: any
    checklistArr: checklist[]
    checklistArrComplete = []
    checklistArrNotComplete = []

    constructor(
      public dialogRef: MatDialogRef<ManualLoadDialog>,
      @Inject(MAT_DIALOG_DATA) public inData: any,
      private forceReloadService: ForceReloadService) {
        this.objData = this.inData
        this.checklistArr = [] 
        this.checklistArrComplete = []
        this.checklistArrNotComplete = []
    }

    ngOnInit() {
      this.getLoadedTPMChecklist(this.inData.site, this.inData.objEnv, this.inData.objID)
    }

    getLoadedTPMChecklist(objSite: string, objEnvironment: string, objectID: string){
      let a =0;
      let b=0;
      this.forceReloadService.loadedChecklist(objSite, objEnvironment, objectID).pipe(
        pluck('Body')).subscribe((objData) =>{
        let objectData: any = objData
        this.checklistArr = objectData  
        
        console.log(this.checklistArr) 
        this.checklistArr.forEach((checkList) =>{
          checkList.checked = false
          checkList.site = this.inData.site
          checkList.objEnv = this.inData.objEnv
          checkList.objID = this.inData.objID
        })
        for( let i = 0; i< this.checklistArr.length; i++){
          if (this.checklistArr[i].completed == 0 ){
             this.checklistArrComplete[a] = this.checklistArr[i]
             a = a + 1
             console.log('The value of complete array ',this.checklistArrComplete)
          }
          if (this.checklistArr[i].completed > 0 ) {
             this.checklistArrNotComplete[b] = this.checklistArr[i]
             b = b + 1
             console.log('The value of Not complete array ',this.checklistArrNotComplete)
          }
        }
      })

    }

    onSelection(areas){
      console.log(areas)
    }

    deleteTaskLists(){
      let checkedItems = this.checklistArr.filter((checklist) => checklist['checked'] == true)
      checkedItems = checkedItems.concat(this.checklistArrComplete); // Add in everything not started 

      if (checkedItems.length > 0){
        if (confirm("You are about to delete: " + checkedItems.length + " checklists. Are you sure you want to continue?")) {
          this.forceReloadService.deleteAndForceUpdate(checkedItems).subscribe((response) =>{
            this.dialogRef.close()
          })
        }
      } 

    }

    onNoClick(){
      this.dialogRef.close()
    }
  
  }
  