import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { Observable } from 'rxjs';
import { s3Link } from './config-s3-upload.service';

@Injectable({
  providedIn: 'root'
})
export class AdminTaskListService extends AbstractRestService {
  postInit(): void { }

  taskList(site: string, object: string):Observable<GenericLambdaReturn<TaskListType>> 
  {
    let params:any={};

    params.Site = site;
    params.Object = object;
        
    return this.get({
      ApiResource: "/configTasks",
      data: params,
      repeatCall: false
    });
  }

  taskFileList(configID:number):Observable<GenericLambdaReturn<any[]>> {
    let params:any={};

    params.ConfigID = configID;
        
    return this.get({
      ApiResource: "/config/tasks/files",
      data: params,
      repeatCall: false
    });
  }

  uploadFile(site: string, environment: string, object: string, configID:number, filename:string, displayorder:number):Observable<GenericLambdaReturn<s3Link>> {
    let params:any={};

    params.Site = site;
    params.Environment = environment;
    params.Object = object;
    params.Filename = filename;
    params.ConfigID = configID;
    params.DisplayOrder = displayorder;
        
    return this.put({
      ApiResource: "/config/tasks/files",
      data: params,
      repeatCall: false
    });
  }

  deleteFile(id:number):Observable<GenericLambdaReturn<any[]>> {
    let params:any={};

    params.ID = id;
        
    return this.delete({
      ApiResource: "/config/tasks/files",
      data: params,
      repeatCall: false
    });
  }

  updateOrder(order:any):Observable<GenericLambdaReturn<any[]>> {
    let params:any=order;
       
    return this.post({
      ApiResource: "/config/tasks/files",
      data: params,
      repeatCall: false
    });
  }


}

export type TaskListType = {
  tasks: TaskType[];
}

export type TaskType = {
 id?:number;
 type:string;
 label:string;
 task_type:string;
 task:string;
 time:string;
 otherinfo:string;
 form_number:string;
 revision:string;
 image_count:number;
}
