import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ErrorSnackBar } from '../task-list/task-complete-complete';
import { Component} from '@angular/core';

@Component({
    selector: 'did-not-complete-dialog',
    templateUrl: './did-not-complete-dialog.html',
    styleUrls: ['./task-list.component.scss']
  })
  export class DidNotCompleteDialog {
    deleteFlag: boolean;
    comment: string;
    
  
    constructor(
        public dialogRef: MatDialogRef<DidNotCompleteDialog>,
        private _snackBar: MatSnackBar) {
        this.deleteFlag = false;
        this.comment = '';
    }

    submitDidNotComplete(){
      if (this.comment == '' || this.comment == null){
        this._snackBar.openFromComponent(ErrorSnackBar, {
          duration: 5000,
        });
        this.deleteFlag = false

      } else {
        this.deleteFlag = true
        this.dialogRef.close({deleteFlag: this.deleteFlag, comment: this.comment});
      }
         
    }
  
    onNoClick(): void {
      this.dialogRef.close({deleteFlag: this.deleteFlag});
    }
  }
  
  
  export interface DialogData {
    deleteFlag: number;
    comment: string;
  }
