import {Component }  from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-wc-confirm',
  templateUrl: './wc-confirm.dialog.html',
  styleUrls: ['./wc-confirm.dialog.sass']
})
export class WcConfirmDialog {

  constructor(public dialogRef: MatDialogRef<WcConfirmDialog>) { }

  onClick(value:boolean) {
    this.dialogRef.close(value);
  }

}
