import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'; 
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list'; 
import { MatSidenavModule } from '@angular/material/sidenav'; 
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'; 
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatExpansionModule} from '@angular/material/expansion';
import { MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip'; 
import { MatSortModule } from '@angular/material/sort';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import {MatTreeModule} from '@angular/material/tree';

import {LayoutModule} from '@angular/cdk/layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TpmHomeComponent } from './tpm-home/tpm-home.component';
import { ObjectListComponent } from './object-list/object-list.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskCompleteDialog, ErrorSnackBar } from './task-list/task-complete-complete';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DidNotCompleteDialog } from './task-list/did-not-complete-dialog';
import { ClearTaskDialog } from './task-list/clear-task-dialog';
import { AdminComponent } from './admin/components/admin/admin.component';
import { FileUploadComponent} from './admin/components/file-upload/file-upload.component';
import { CreateObjectDialog, CreateObjErrorSnackBar } from './admin/components/admin/create-object-dialog';
import { LogOutButtonComponent } from './log-out-button/log-out-button.component';
import { LogOutPageComponent } from './log-out-page/log-out-page.component';
import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';
import { CompletedOverviewComponent } from './dashboard/components/completed-overview/completed-overview.component';
import { EquipmentOverviewComponent } from './dashboard/components/equipment-overview/equipment-overview.component';
import { EquipmentIssueDetailsComponent } from './dashboard/components/equipment-issue-details/equipment-issue-details.component';
import { MonthAndYearFormat } from './dashboard/components/equipment-issue-details/equipment-issue-details-format.pipe';
import { EquipmentStatusDetailsComponent } from './dashboard/components/equipment-status-details/equipment-status-details.component';
import { ShiftListComponent } from './shift-list/shift-list.component';
import { CurrentOverviewStatusComponent } from './dashboard/components/current-overview-status/current-overview-status.component';
import { GeaMesPiechartComponent } from './gea-mes-piechart/gea-mes-piechart.component';
import { NgChartsModule } from 'ng2-charts';
import { GeaMesDatepickerModule } from '@gea-mes/datepicker';
import { ManualLoadDialog } from './admin/components/admin/manual-load-dialog';
import { EamWorkRequestDialog } from './task-list/eam-work-request-dialog';
import { EamWorkRequestOrderComponent } from './task-list/eam-work-request-order/eam-work-request-order.component';
import { DateConversionPipe } from './pipes/date-conversion.pipe';
import { WeekMonthConversionPipe } from './pipes/week-month-conversion.pipe';
import { WcConfirmDialog } from './admin/components/wc-confirm/wc-confirm.dialog';
import { ProgressSpinnerComponent } from './dashboard/components/progress-spinner/progress-spinner.component'
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ServiceNowDialog } from './task-list/servicenow-dialog';
import { ValidatorStatusComponent } from './admin/components/validator-status/validator-status.component';
import { ValidatorResultsComponent } from './admin/components/validator-results/validator-results.component';
import { EamLookupDialog } from './admin/components/admin/eam-lookup-dialog';
import { GeaMesNavbarModule }from '@gea-mes/navbar';
import { NavItemService } from './services/nav-item.service';
import { SectionOverviewComponent } from './dashboard/components/section-overview/section-overview.component';
import { AdminTaskListComponent } from './admin/components/admin-task-list/admin-task-list.component';
import { AdminTaskFileDialog } from './admin/components/admin-task-file-dialog/admin-task-file-dialog.component';
import { TaskFileDialog } from './task-list/task-file-dialog/task-file-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    TpmHomeComponent,
    ObjectListComponent,
    TaskListComponent,
    TaskCompleteDialog,
    DidNotCompleteDialog,
    AdminComponent,
    FileUploadComponent,
    CreateObjectDialog,
    LogOutButtonComponent,
    LogOutPageComponent,
    DashboardComponent,
    CompletedOverviewComponent,
    EquipmentOverviewComponent,
    EquipmentIssueDetailsComponent,
    MonthAndYearFormat,
    EquipmentStatusDetailsComponent,
    ShiftListComponent,
    CurrentOverviewStatusComponent,
    GeaMesPiechartComponent,
    ErrorSnackBar,
    CreateObjErrorSnackBar,
    ClearTaskDialog,
    ManualLoadDialog,
    EamWorkRequestDialog,
    EamWorkRequestOrderComponent,
    DateConversionPipe,
    WeekMonthConversionPipe,
    WcConfirmDialog,
    ProgressSpinnerComponent,
    ServiceNowDialog,
    ValidatorStatusComponent,
    ValidatorResultsComponent,
    EamLookupDialog,
    SectionOverviewComponent,
    AdminTaskListComponent,
    AdminTaskFileDialog,
    TaskFileDialog
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MatCardModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatSidenavModule,
    MatButtonModule,
    MatTableModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSortModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    MatPaginatorModule,
    MatInputModule,
    MatMenuModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatStepperModule,
    LayoutModule,
    MatDividerModule,
    NgChartsModule,
    GeaMesDatepickerModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    ScrollingModule,
    GeaMesNavbarModule
  ],
  bootstrap: [AppComponent],
  providers: [NavItemService]
})
export class AppModule { }
