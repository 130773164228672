<div style="padding: 5px 5px 5px 5px">
        <ng-container *ngFor="let d of mDataSource; let i = index" >
            <button mat-raised-button color="primary" class="adminSiteButtons" (click)="selectSite(d.description)">{{d.description}}</button>
        </ng-container>
</div>
<div class="actionButtons">
  <div class="CellSpacingMedium"><button [disabled]="!adminFlag" mat-raised-button color="link" (click)="editObject(false)">Create New</button></div>
  <div class="CellSpacingMedium"><button [disabled]="!adminFlag" mat-raised-button color="link" [routerLink]="'/admin/validator'">Checklist File Validator</button></div>
</div>
<br><br>
<div *ngIf = "!productionMode">
  <button mat-raised-button color="primary" class="adminSiteButtons" (click)="selectScheduleData()">Build Schedule Data</button> {{scheduleDataStatus}}
</div>

<div *ngIf="selectedObject != ''"  class="taskDetailsHeader">
  <div >
    Form: {{selectedFormnum  == undefined ? '' : selectedFormnum }} 
  </div>
  <div >
    Revision: {{selectedRevision == undefined ? '' : selectedRevision  }}
  </div>
  <div >
    Filename: {{selOriginalFileName == undefined ? '' : selOriginalFileName  }}
  </div>
</div>

  
<div *ngIf="selectedSite != '' && selectedObject == ''">
  <mat-toolbar color="primary" class="tableTitleBar">Object List: {{selectedSite}}</mat-toolbar>
    <div *ngIf="fullSizeMatcher.matches; else cardDisplay">
        <mat-table [dataSource]="objectList">
            <ng-container matColumnDef="site">
                <mat-header-cell *matHeaderCellDef>Site</mat-header-cell>
                <mat-cell *matCellDef="let d">{{d.site}}</mat-cell>
              </ng-container>    
            <ng-container matColumnDef="section">
              <mat-header-cell *matHeaderCellDef>Section</mat-header-cell>
              <mat-cell *matCellDef="let d">{{d.section}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="subsection">
              <mat-header-cell *matHeaderCellDef>Sub Section</mat-header-cell>
              <mat-cell *matCellDef="let d">{{d.subsection}}</mat-cell>
            </ng-container>            
    
            <ng-container matColumnDef="objectID">
              <mat-header-cell *matHeaderCellDef>Object ID</mat-header-cell>
              <mat-cell *matCellDef="let d">{{d.objectID}}</mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
              <mat-cell *matCellDef="let d">{{d.description}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="eamasset">
              <mat-header-cell *matHeaderCellDef>EAM Asset</mat-header-cell>
              <mat-cell *matCellDef="let d">{{d.EAMAsset}}</mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="servicenowci">
              <mat-header-cell *matHeaderCellDef >ServiceNow CI</mat-header-cell>
              <mat-cell *matCellDef="let d">{{d.ServiceNow_CI}}</mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="updatestatus">
              <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
              <mat-cell *matCellDef="let d">{{d.updatestatus}} <br>  {{d.LastFileUpdateTime | date:'MM/dd/yyyy HH:mm:ss a' }} </mat-cell>
            </ng-container> 

           <ng-container matColumnDef="LastFileUpdateTime">
              <mat-header-cell *matHeaderCellDef>Last File Update Time</mat-header-cell>
              <mat-cell *matCellDef="let d">{{d.LastFileUpdateTime}}</mat-cell>
            </ng-container>     
    
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let d">
                  <button mat-button color="link" (click)="navigateTaskList(d.site, d.environment, d.objectID)"><mat-icon>assignment</mat-icon>Task Details</button>
                  <app-file-upload site={{d.site}} environment={{d.environment}} object={{d.objectID}} text="Upload Config" admin={{adminFlag}}></app-file-upload>
                  <button [disabled]="!adminFlag" mat-button color="primary" (click)="editObject(true, selectedSite, d.objectID, d.section,d.subsection,d.description,d.EAMAsset,d.ServiceNow_CI, d.loadtime,d.RequireComment)"><mat-icon>create</mat-icon>Edit</button>
                  <button [disabled]="!adminFlag" mat-button color="warn" (click)="deleteObject(selectedSite, d.objectID)"><mat-icon>delete_forever</mat-icon>Delete</button>
                  <button [disabled]="!adminFlag" mat-button color="primary" (click)="manualUpload(d.site, d.environment, d.objectID)"><mat-icon>sync</mat-icon>Force Update</button>
                </mat-cell>
              </ng-container>  
    
            <mat-header-row *matHeaderRowDef="displayedObjectColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedObjectColumns;"></mat-row>
          </mat-table>

    </div>
    <ng-template #cardDisplay>
        <mat-card *ngFor="let obj of objectList" class="objCards mat-elevation-z10">
          <mat-card-header>
            <mat-card-title>{{obj.site}} - {{obj.objectID}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            Section: {{obj.section}} <br>
            SubSection: {{obj.subsection}} <br>
            Description: {{obj.description}} <br>
            Status: {{obj.updatestatus}} <br>
            eAM Asset: {{obj.EAMAsset}}
          </mat-card-content>
          <mat-card-footer>
            <mat-card-actions>
              <button mat-button color="link" (click)="getTaskList(obj.site, obj.environment, obj.objectID)"><mat-icon>assignment</mat-icon>Task Details</button>
              <app-file-upload site={{obj.site}} environment={{obj.environment}} object={{obj.objectID}} text="Upload Config" admin={{adminFlag}}></app-file-upload>
              <button [disabled]="!adminFlag" mat-button color="primary" (click)="editObject(true, selectedSite, obj.objectID, obj.section,obj.subsection,obj.description,obj.EAMAsset,obj.loadtime, obj.RequireComment)"><mat-icon>create</mat-icon>Edit</button>
              <button [disabled]="!adminFlag" mat-button color="warn" (click)="deleteObject(selectedSite, obj.objectID)"><mat-icon>delete_forever</mat-icon>Delete</button>
              <button [disabled]="!adminFlag" mat-button color="primary" (click)="manualUpload(obj.site, obj.environment, obj.objectID)"><mat-icon>sync</mat-icon>Force Update</button>
            </mat-card-actions>
          </mat-card-footer>
        </mat-card>
    </ng-template>
</div>