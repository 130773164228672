/*
* Description: Static Application Information
*
* History:
*   01/18/2022  A Kuprion ITMFG-27821 Initial version, needed because package.json can no longer be read
*
**/
export const appinfo = {
    'name': 'tpm',
    'version': '2.0.0'
}