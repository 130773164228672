import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class EquipmentOverviewService extends AbstractRestService{
  postInit(): void {
    console.log("Post INIT!");
  }

  getEquipmentOverview(start: string, end: string, currentDate: string, displayName: string):Observable<GenericLambdaReturn<completedMetrics>> {
    let params:any={};

    currentDate ? params.CurrentDate = currentDate:null
    start ? params.DateRangeStart = start:null
    end ? params.DateRangeEnd = end:null
    displayName ? params.DisplayName = displayName:null

    return this.get({
      ApiResource: "/dashequipmentoverview",
      data: params,
      repeatCall: false
    });
  }

  getSectionOverview(start: string, end: string, currentDate: string, displayName: string):Observable<GenericLambdaReturn<sectionMetrics>> {
    let params:any={};

    currentDate ? params.CurrentDate = currentDate:null
    start ? params.DateRangeStart = start:null
    end ? params.DateRangeEnd = end:null
    displayName ? params.DisplayName = displayName:null

    return this.get({
      ApiResource: "/dashsectionoverview",
      data: params,
      repeatCall: false
    });
  }

}

export type completedMetrics = {
  Site: string,
  ObjectID: string,
  Section: string,
  task_type: string,
  Completed: number,
  Total: number,
  Status: string
}

export type sectionMetrics = {
  display_name: string,
  SubSection: string,
  Section: string,
  task_type: string,
  completeTaskList: number,
  total: number
}