import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class ObjectShiftListService extends AbstractRestService {

  postInit(): void {}

  objectShiftList(site: string, environment: string, object: string):Observable<GenericLambdaReturn<ObjectShiftList>>  {
    return this.get({
      ApiResource: "/objectshiftlist",
      data: {
        Site: site,
        Environment: environment,
        Object: object
      },
      repeatCall: false
    });

  }

  objectShiftListSubscription(site: string, environment: string, object: string):Observable<GenericLambdaReturn<ObjectShiftList>>  {
    return this.get({
      ApiResource: "/objectshiftlist",
      data: {
        Site: site,
        Environment: environment,
        Object: object
      },
      repeatCall: true,
      repeatRate: 120000
    });    
  }  
}

export type ObjectShiftList = {
  shiftdate: Date;
  shift: string;
}
