import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class GetTaskListService extends AbstractRestService{

  postInit(): void { }

  
  taskList(site: string, object: string):Observable<GenericLambdaReturn<TaskListType>> 
  {
    let params:any={};

    params.Site = site.toString();
    params.Object = object.toString();
        
    return this.get({
      ApiResource: "/configgettask",
      data: params
    }); 
  }

  deleteObject(displayName: string, object: string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.DisplayName = displayName.toString();
    params.Object = object.toString();
    params.Delete = true;

    return this.get({
      ApiResource: "/configobject",
      data: params,
      repeatCall: false
    });
  }

  editObject(display_name: string, object: string, section: string, subsection: string, description: string, eamasset: string, servicenowci: string, loadtime: number, requirecomment: number):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    params.DisplayName = display_name;
    params.Object = object;
    params.Section = section;
    params.SubSection = subsection;
    params.ManualDescription = description;
    params.EAMAsset = eamasset ? eamasset : ''
    params.servicenowci = servicenowci ? servicenowci : '';
    eamasset ? params.EAMAsset = eamasset : null
    servicenowci  ? params.servicenowci = servicenowci  : null
    params.LoadTime = loadtime;
    params.RequireComment = requirecomment;
 
    return this.get({
      ApiResource: "/configobject",
      data: params,
      repeatCall: false
    });
  }  

}

export type TaskListType = {
  tasks: Task[];
}

export type Task = {
 id?:number;
 type:string;
 label:string;
 task_type:string;
 task:string;
 time:string;
 otherinfo:string;
 form_number:string;
 revision:string;
}