<div class="fx-row-start-center">
  <gea-mes-datepicker
    class="dateRangeBut"
    matTooltip="Select Date" 
    (onDateSelect)="onDateSelection($event)" 
    (onDateInit)="onDateSelection($event)"
    singleDate="false" 
    showLabel="true"
    showCurrentShift="false"
    defaultRange="Year To Date"
    showRangeLabel="true"
    timeRangeExclude="Today,this week,tHiS MoNth">
  </gea-mes-datepicker>
  <div class="datepicker-div" *ngIf="!mobile">
    <button mat-raised-button color="primary" class="dateRangeBut" (click)="navToSSRS()">Reports</button>
  </div>
</div>  
<mat-card class="enterprise-complete-card mat-elevation-z8">
    <mat-card-header>
      <mat-card-title>Completed Tasks</mat-card-title>
      <mat-card-subtitle class="navCard">
        <button mat-button color="primary" class="siteSelectBackButton" (click)="navToEnterprise()"><mat-icon>arrow_back</mat-icon></button>
        <button mat-button [matMenuTriggerFor]="menu" class="siteSelectButton" color="primary">{{displayName}}<mat-icon>arrow_drop_down</mat-icon></button>
        <button mat-button [matMenuTriggerFor]="menu2" class="siteSelectButton" color="primary">{{currentSection}}<mat-icon>arrow_drop_down</mat-icon></button>
        <button mat-button *ngIf="subMenuDataExists" [matMenuTriggerFor]="subSectionMenu" class="siteSelectButton" color="primary">{{currentSubSection}}<mat-icon>arrow_drop_down</mat-icon></button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngFor="let siteData of siteMenuData" (click)="changeSite(siteData.DisplayName)">{{siteData.DisplayName}}</button>
        </mat-menu>  
        <mat-menu #menu2="matMenu">
          <button mat-menu-item *ngFor="let sectionData of sectionMenuData" (click)="changeSection(sectionData)">{{sectionData}}</button>
        </mat-menu>
        <mat-menu #subSectionMenu="matMenu">
          <button mat-menu-item *ngFor="let subSectionData of subSectionMenuData" (click)="changeSubSection(subSectionData)">{{subSectionData}}</button>
        </mat-menu>
      </mat-card-subtitle>
    </mat-card-header>

    <div *ngIf="!mobile; else mobileView">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
    
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition -->

        <!-- Section Column -->
        <ng-container matColumnDef="Section">
          <th mat-header-cell *matHeaderCellDef> Section </th>
          <td mat-cell *matCellDef="let element" (click)="changeSection(element.Section.Section)"> 
            <button mat-button color = "primary" class = "sectionTableButton">{{element.Section.Section}}</button>
          </td>
        </ng-container>

        <!-- Sub Section Column -->
        <ng-container matColumnDef="SubSection">
          <th mat-header-cell *matHeaderCellDef> Sub Section </th>
          <td mat-cell *matCellDef="let element" (click)="changeSubSection(element.Section.SubSection, element.Section.Section)">
            <button mat-button color = "primary" class="sectionTableButton">{{element.Section.SubSection}}</button>  
          </td>
        </ng-container>
     
        <!-- Daily Column -->
        <ng-container matColumnDef="Daily">
          <th mat-header-cell *matHeaderCellDef> Daily </th>
          <td mat-cell *matCellDef="let element"> 
            <div *ngIf="element.Section.PercentComplete.SHIFT == 'NA'; else shiftElseDiv">
              NA  
            </div>  
            <ng-template #shiftElseDiv>
              <div [ngStyle]="{'color': element.Section.PercentComplete.SHIFT > colorChangeThreshold ? 'green' : 'red'}">
                {{element.Section.PercentComplete.SHIFT | percent}}
              </div>              
            </ng-template>
          </td>
        </ng-container>
    
        <!-- Weekly Column -->
        <ng-container matColumnDef="Weekly">
          <th mat-header-cell *matHeaderCellDef> Weekly </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.Section.PercentComplete.WEEK == 'NA'; else weekElseDiv">              
              NA
            </div>  
            <ng-template #weekElseDiv>
              <div [ngStyle]="{'color': element.Section.PercentComplete.WEEK > colorChangeThreshold ? 'green' : 'red'}">
                {{element.Section.PercentComplete.WEEK | percent}}
              </div>
            </ng-template>
          </td>
        </ng-container>

        <!-- Biweekly Column -->
        <ng-container matColumnDef="Biweekly">
          <th mat-header-cell *matHeaderCellDef> Biweekly </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.Section.PercentComplete.BIWEEKLY== 'NA'; else biWeekElseDiv">
              NA  
            </div>  
            <ng-template #biWeekElseDiv>
              <div [ngStyle]="{'color': element.Section.PercentComplete.BIWEEKLY > colorChangeThreshold ? 'green' : 'red'}">
                {{element.Section.PercentComplete.BIWEEKLY | percent}}
              </div>
            </ng-template>            
          </td>
        </ng-container>
    
        <!-- Monthly Column -->
        <ng-container matColumnDef="Monthly">
          <th mat-header-cell *matHeaderCellDef> Monthly </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.Section.PercentComplete.MONTH == 'NA'; else monthElseDiv">
              NA  
            </div>  
            <ng-template #monthElseDiv>
              <div [ngStyle]="{'color': element.Section.PercentComplete.MONTH > colorChangeThreshold ? 'green' : 'red'}">
                {{element.Section.PercentComplete.MONTH | percent}}
              </div>
            </ng-template>          
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

  <!--- 
  *
  *  
    Mobile View 
  *
  *
  -->
    <ng-template #mobileView>
      <mat-card *ngFor="let obj of sectionOverviewData" class="enterprise-complete-card mat-elevation-z8">
        <mat-card-header>
          <mat-card-title style="color:'#1976D2'" (click)="changeSection(obj.Section.Section, obj.Section.SubSection)">{{obj.Section.Section}}</mat-card-title>          
          <mat-card-subtitle>{{obj.Section.SubSection}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-divider></mat-divider>
          <div class="fx-row-space-evenly-center">
            
            <div *ngIf="obj.Section.PercentComplete.SHIFT != 'NA'; else shiftElseDiv">
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Daily</h3>
                </div>
                <div [ngStyle]="{'color': obj.Section.PercentComplete.SHIFT > colorChangeThreshold ? 'green' : 'red'}">
                  {{obj.Section.PercentComplete.SHIFT | percent}}
                </div>                 
              </div>
            </div>
            <ng-template #shiftElseDiv>
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Daily</h3>
                </div>
                <div>
                  NA
                </div> 
              </div>
            </ng-template>

            <div *ngIf="obj.Section.PercentComplete.WEEK != 'NA'; else weekElseDiv">
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Weekly</h3>
                </div>
                <div  [ngStyle]="{'color': obj.Section.PercentComplete.WEEK > colorChangeThreshold ? 'green' : 'red'}">
                  {{obj.Section.PercentComplete.WEEK | percent}}
                </div>
              </div>
            </div>
            <ng-template #weekElseDiv>
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Weekly</h3>
                </div>
                <div>
                  NA
                </div>                          
              </div>
            </ng-template>

            <div *ngIf="obj.Section.PercentComplete.BIWEEKLY != 'NA'; else biWeekElseDiv">
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Biweekly</h3>
                </div>
                <div  [ngStyle]="{'color': obj.Section.PercentComplete.BIWEEKLY > colorChangeThreshold ? 'green' : 'red'}">
                  {{obj.Section.PercentComplete.BIWEEKLY | percent}}
                </div>
              </div>
            </div>
            <ng-template #biWeekElseDiv>
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Biweekly</h3>
                </div>
                <div> 
                  NA
                </div>
              </div>
            </ng-template>

            <div *ngIf="obj.Section.PercentComplete.MONTH != 'NA'; else monthElseDiv">
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Monthly</h3>
                </div>
                <div  [ngStyle]="{'color': obj.Section.PercentComplete.MONTH > colorChangeThreshold ? 'green' : 'red'}">
                  {{obj.Section.PercentComplete.MONTH | percent}}
                </div>                
              </div>
            </div>
            <ng-template #monthElseDiv>
              <div class="fx-row-center-center">
                <div>
                  <h3 class="mat-3">Monthly</h3>
                </div>                
                <div>  
                  NA
                </div>
              </div>
            </ng-template>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-template>
  </mat-card>