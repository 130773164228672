<h3 mat-dialog-title>{{title}}</h3>

<div mat-dialog-content>
    <form [formGroup]="completeFormGroup">
      <mat-form-field class="card-dialog">
        <textarea [ngClass]="validForm == true ? 'notValid' : 'Valid'" matInput cdkTextareaAutosize placeholder={{issuePlaceHolder}} formControlName="comment"></textarea>
      </mat-form-field>

      <mat-form-field *ngIf="data.type == 1 || data.type == 3" class="card-dialog">
        <textarea  matInput cdkTextareaAutosize placeholder="Corrective Action" formControlName="correctiveAction"></textarea>
      </mat-form-field>

      <mat-form-field *ngIf="data.type == 1 || data.type == 3" class="card-dialog">
        <textarea  matInput cdkTextareaAutosize placeholder="Tag #" formControlName="tagno"></textarea>
      </mat-form-field>    
    </form>
    <div *ngIf="data.type != 2 && data.type != 4 && data.type != 5 && data.type != 6 && eamAssetFlag" class="fx-row-end-center">
      <p [ngStyle]="{'color':descriptionCountOver ? 'red':'black'}">{{descriptionCount}} of {{eAMCharacterCount}} allowed charaters</p>
    </div>
</div>


<div mat-dialog-actions class="fx-row-space-evenly-center">
  <button *ngIf="data.type == 2 || data.type == 4 || data.type == 5 || data.type == 6" class="mat-raised-button dia-button" (click)="saveData()">Save</button>
  <button *ngIf="!editMode && data.type != 2 && data.type != 4 && data.type != 5 && data.type != 6" class="mat-raised-button dia-button"  (click)="saveData()" cdkFocusInitial>TPM Update Only</button>
  <button *ngIf="eamAssetFlag && data.type != 2 && data.type != 4 && data.type != 5 && data.type != 6" class="mat-raised-button dia-button" [disabled]="descriptionCountOver" [ngStyle]="{'opacity':descriptionCountOver ? '0.3': '1'}" (click)="createWorkRequest()" cdkFocusInitial>Create Work Request</button>
  <button *ngIf="eamAssetFlag && data.type != 2 && data.type != 4 && data.type != 5 && data.type != 6" class="mat-raised-button dia-button"  (click)="viewWorkRequest()" cdkFocusInitial>View Open Work Requests/Orders</button>
  <button *ngIf="serviceNowFlag && data.type!= 2 && data.type != 4 && data.type != 5 && data.type != 6" class="mat-raised-button dia-button" (click)="createServiceNowIncident()" cdkFocusInitial>Create ServiceNow Incident</button>
  <button class="mat-raised-button dia-button" (click)="onNoClick()">Cancel</button>
</div>
<br>
<br>


