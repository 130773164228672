import { Component, OnInit } from '@angular/core';
import { GetTaskListService } from '../../services/get-task-list.service';
import { ObjectListService } from 'src/app/services/object-list.service';
import { TasklistService } from 'src/app/services/tasklist.service';
import { Subscription} from 'rxjs';
import { first } from 'rxjs/operators'
import { CreateObjectDialog } from './create-object-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MediaMatcher } from '@angular/cdk/layout';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { ManualLoadDialog } from './manual-load-dialog';
import { AssetListStorageService } from '../../services/asset-list-storage.service';
import { ScheduleDataService } from '../../services/schedule-data.service';
import { environment } from 'src/environments/environment';
import * as dayjs from 'dayjs'
import { ActivatedRoute, Router } from '@angular/router';
dayjs().format()
 
export interface task {
  task: string;
}


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  
  displayedObjectColumns: string[] = ['objectID','section','subsection','description','updatestatus','eamasset','servicenowci','actions']
  dataSource: any;
  selectedSite: string;
  selectedEnv: string;
  selectedObject: string;
  selectedStatus: string;
  selectedFormnum: string;
  selectedRevision:string;
  selOriginalFileName:string;
  fullSizeMatcher: MediaQueryList;
  adminFlag: boolean;
  assetList: any[];
  productionMode: boolean;
  scheduleDataStatus: string;

  subscription: Subscription;
  adminSub$: Subscription;
  eamServiceSubscription: Subscription;

  mDataSource: {};
  objectList: any[] = [];

  sectionKeys = Object.keys;
 
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private objectListService: ObjectListService,
    private AdminService: GetTaskListService,
    public dialog: MatDialog,
    public mediaMatcher: MediaMatcher,
    private geaMesCognito: GeaMesCognitoAuthService,
    private assetListStorage: AssetListStorageService,
    private scheduleData: ScheduleDataService
  ) { 
    this.selectedSite = ''
    this.selectedObject = ''
    
    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => { 
      this.adminFlag = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });
  }

  ngOnInit() {
    if (environment.production) this.productionMode = true;

    if (this.route.snapshot.paramMap.has('plant')) {
      this.selectSite(this.route.snapshot.paramMap.get('plant'));
    } else {
      this.getSiteObjectList();
    }
    this.fullSizeMatcher = this.mediaMatcher.matchMedia('(min-width: 1024px)');
  }

  getSiteObjectList() {
    if (this.subscription) this.subscription.unsubscribe();     
    console.log('Before calling objectList Service');
    this.subscription = this.objectListService.objectListSubscription().subscribe((
      out => {
        this.mDataSource = out.Body;
        console.log(this.mDataSource);
        if (this.selectedSite != "") {
          for (var i in this.mDataSource) {
            if (this.mDataSource[i].description == this.selectedSite) {
              this.getObjectList(+i);
              break;
            }
          }
        } else {
          this.unsubscribe(); // If not listing objects, no need to constantly refresh
        }
      }
    ));
  }

  getObjectList(record: number, objectSelected: boolean= false) {
    this.objectList = []
    for (let siteChild in this.mDataSource[record]["children"]) {
      if ("children" in this.mDataSource[record]["children"][siteChild]) {
        let sectionDesc = this.mDataSource[record]["children"][siteChild]["description"]
        
        // Loop sections
        for (let sectionChild in this.mDataSource[record]["children"][siteChild]["children"]) {
          if ("children" in this.mDataSource[record]["children"][siteChild]["children"][sectionChild]) {
            let subSectionDesc = this.mDataSource[record]["children"][siteChild]["children"][sectionChild]["description"]
            
            // Loop Sub Sections
            for (let subSectionChild in this.mDataSource[record]["children"][siteChild]["children"][sectionChild]["children"]) {
              let thisChild = this.buildObject(sectionDesc, subSectionDesc, this.mDataSource[record]["children"][siteChild]["children"][sectionChild]["children"][subSectionChild]);
              this.objectList.push(thisChild);
            }
          } else {
            // Handle Children with no sub section
            let thisChild = this.buildObject(sectionDesc, "", this.mDataSource[record]["children"][siteChild]["children"][sectionChild]);
            this.objectList.push(thisChild);
          }
        }
      } else {
        // Handle Children with no Section
        let thisChild = this.buildObject("", "", this.mDataSource[record]["children"][siteChild]);
        this.objectList.push(thisChild);
      }
    }
    
    if (!objectSelected) this.selectedObject = "";
    this.selectedSite = this.mDataSource[record]["description"];
  }

  buildObject(sectionDesc:string, subSectionDesc:string, obj):{} {
    let object = {};
    object["site"] = obj["site"];
    object["environment"] = obj["environment"];
    object["section"] = sectionDesc;
    object["subsection"] = subSectionDesc
    object["objectID"] = obj["objectid"];
    object["description"] = obj["description"];
    object["updatestatus"] = obj["updatestatus"];
    object["EAMAsset"] = obj["EAMAsset"];
    object["LastFileUpdateTime"] = obj["LastFileUpdateTime"];
    object["OriginalFileName"] = obj["OriginalFileName"];
    object["form_number"] = obj["form_number"];
    object["revision"] = obj["revision"];
    object["ServiceNow_CI"] = obj["ServiceNow_CI"];
    object["loadtime"] = obj["loadtime"];
    object["RequireComment"] = obj["RequireComment"];

  /*  object["LastFileUpdateTime"] = dayjs(object["LastFileUpdateTime"]).format('YYYY-MM-DD HH:mm:ss')  */
    if (object["LastFileUpdateTime"] == 'Invalid Date')
      object["LastFileUpdateTime"] = "";
    return object;
  }
  
  selectSite(site: string) {
    console.log(site)
    this.selectedSite = site;
    this.getSiteObjectList();

    let sitePre = site.split(' ')[0]
    this.assetListStorage.updateAssetList(sitePre)
  }

  deleteObject(displayName: string, objectid: string) {
    if (confirm("Are you sure to delete "+objectid)) {
      this.AdminService.deleteObject(displayName, objectid).subscribe((
        out => {
            console.log(out);
            this.getSiteObjectList();
      }));    
    }
  }

  navigateTaskList(site: string, environment: string, object: string): void {
    this.router.navigate(["/admin/tasklist",this.selectedSite, site, environment, object]);
  }

  editObject(isEdit: boolean, displayName?: string, object?: string, section?: string, subsection?:string, description?: string, eamasset?: string,ServiceNow_CI?: string, loadtime?: number, requirecomment?: number) {
    console.log("requirecomment", requirecomment);
    let data = {};
    if (displayName != undefined) {
      data = {
        "isEdit": isEdit,
        "display_name": displayName,
        "section": section,
        "subsection": subsection,
        "objectid": object,
        "manualDescription": description,
        "eam_asset": eamasset,
        "servicenowci":ServiceNow_CI,
        "loadtime":loadtime,
        "requirecomment": requirecomment
      }
    } else{
      data = {
        "isEdit": isEdit
      }
    }
    console.log("OPEN DIALOG!!",data);

    const dialogRef = this.dialog.open(CreateObjectDialog, {
      width: '800px',
      maxHeight: '800px',
      data: {objData: data, objs: this.mDataSource}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      let site = result
      this.getSiteObjectList();
      if(site) this.selectSite(site);
    });

  }

  manualUpload(site: string, environment: string, objectid: string) {
    console.log(site, environment, objectid)

    const dialogRef = this.dialog.open(ManualLoadDialog, {
      width: '500px',
      maxHeight: '500px',
      data: {
        "site": site,
        "objEnv": environment,
        "objID": objectid
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("AFTER CLOSED!");
    });

  }

  selectScheduleData(){
    console.log ("selectScheduleData")
    this.scheduleDataStatus = ""
    this.scheduleData.buildScheduleData().subscribe((response) =>{
      console.log(response['Body']['errorMessage'])
      if (response['Body']['errorMessage'] != "")
        this.scheduleDataStatus = 'There was an error creating Schedule Data'
      else
        this.scheduleDataStatus = 'Schedule Data has been created'
    })
  }

  unsubscribe() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.adminSub$) this.adminSub$.unsubscribe();
  }  

}
