<div class="fx-row-start-center">
  <gea-mes-datepicker
    class="dateRangeBut"
    matTooltip="Select Date" 
    (onDateSelect)="onDateSelection($event)" 
    (onDateInit)="onDateSelection($event)"
    singleDate="false" 
    showLabel="true"
    showCurrentShift="false"
    defaultRange="Year To Date"
    showRangeLabel="true"
    timeRangeExclude="Today">
  </gea-mes-datepicker>
</div>
<mat-card class="enterprise-complete-card mat-elevation-z8">
  <mat-card-header>
    <mat-card-title>Equipment Issue Details: {{currentDescription}}</mat-card-title>
    <mat-card-subtitle>
      <button mat-button color="primary" class="siteSelectButton" (click)="navToEquipOver()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <button mat-button class="siteSelectButton" color="primary">{{displayName}}</button>

      <button mat-button [matMenuTriggerFor]="menu" class="siteSelectButton" color="primary">{{currentTaskType}}
        <mat-icon>arrow_drop_down</mat-icon></button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let typeTask of typeTaskData"
          (click)="changeTaskType(typeTask)">{{typeTask}}</button>
      </mat-menu>

    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <mat-card *ngFor="let issue of issueArray" class="issue-complete-card mat-elevation-z8">
      <mat-card-header>
        <mat-card-title>
          Task: {{issue.task}}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <h3 class="mat-h3">
          Task Type: {{issue.task_type}}
          <span *ngIf="issue.task_type=='DAILY'; else checkWeek">
            {{issue.shift_date | date:'yyyy-MM-dd'}} {{issue.shift}}
          </span>
          <ng-template #checkWeek>
            <span *ngIf="issue.task_type=='WEEK'; else checkMonth"> {{issue.week | DateConversion: 'week'}}</span>
            <ng-template #checkMonth>
              <span *ngIf="issue.task_type=='MONTH'"> {{issue.month | DateConversion: 'month'}}</span>
            </ng-template>
          </ng-template>
        </h3>
        <mat-divider></mat-divider>
        <h3 class="mat-h3">Issue Description: {{issue.comment}}</h3>
        <mat-divider></mat-divider>
        <h3 class="mat-h3">Corrective Action: {{issue.corective_action}}</h3>
        <mat-divider></mat-divider>
        <h3 class="mat-h3">User ID: {{issue.completed_userid}}</h3>
        <mat-divider></mat-divider>
        <h3 class="mat-h3">Tag Number: {{issue.tag_no}}</h3>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>