import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TaskType } from 'src/app/services/tasklist.service';
import { AdminTaskFileDialog } from '../admin-task-file-dialog/admin-task-file-dialog.component';
import { AdminTaskListService } from '../../services/admin-task-list.service';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-admin-task-file-associate-dialog',
  templateUrl: './admin-task-file-associate-dialog.component.html',
  styleUrls: ['./admin-task-file-associate-dialog.component.scss']
})
export class AdminTaskFileAssociateDialog implements OnInit {

  task:TaskType;
  site: string;
  environment: string;
  object: string;

  fileListSub$: Subscription;
  fileList: any[];

  alreadyAssociatedFiles: string[];

  nextDisplayOrder:number;

  selection = new SelectionModel<string>(true, []); // Stores the list of areas checked  

  displayedColumns: string[] = [ 'actions', 'image' ];

  constructor(
      public dialogRef: MatDialogRef<AdminTaskFileDialog>,
      @Inject(MAT_DIALOG_DATA) public inData: any,
      private svcAdminTaskList:AdminTaskListService,
  ) {
    this.task = inData['task'];
    this.site = inData['site'];
    this.environment = inData['environment'];
    this.object = inData['object'];
    this.nextDisplayOrder = inData['nextDisplayOrder'];
    
    let fileList:any[] = [...new Set(inData['files'].map(item => item.s3_filename))];
    this.alreadyAssociatedFiles = fileList;
    
  }

  ngOnInit(): void {
    this.loadFileList();
  }

  loadFileList(): void {
    if (this.fileListSub$) this.fileListSub$.unsubscribe();

    this.fileListSub$ = this.svcAdminTaskList.taskFileListS3(this.site, this.environment, this.object).subscribe(
      (out) => {
        this.fileList = out.Body.filter( item => this.alreadyAssociatedFiles.indexOf(item.FileName) < 0);
      }
    )
  }

  save(): void {
    this.svcAdminTaskList.taskFileAssociate(this.task.id, this.selection.selected, this.nextDisplayOrder).subscribe(
      out => {
        this.dialogRef.close({ "NewFiles": true });
      }
    )
  }

    /**
   * If user clicks CANCEL button or just clicks outside dialog close the dialog.
   */
    onNoClick(){
      this.dialogRef.close({ "NewFiles": false });
    }


}
