import { Component, OnInit, Inject } from '@angular/core';
import { EAMService } from 'src/app/services/eam.service';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { pluck } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';

export interface displaycolumn{
  displayname:string
  datakey:string
}


@Component({
  selector: 'app-eam-work-request-order',
  templateUrl: './eam-work-request-order.component.html',
  styleUrls: ['./eam-work-request-order.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EamWorkRequestOrderComponent implements OnInit {
  datasource:any[];
  AssetName:string;
  workOrdersExist: boolean;
  isLoading: boolean;

  constructor(private eamService: EAMService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.workOrdersExist = true;
    this.isLoading = false;
    this.datasource= [];
    this.AssetName= this.data.eamAsset;
   }

  ngOnInit() {
    this.getworkorders(this.AssetName)
  }

  getworkorders(assetname: string) : void{
    this.isLoading = true;
    this.eamService.getEAMWorkorders(assetname).pipe(
      pluck('Body')
    ).subscribe((assets:any) => {
      this.isLoading = false;     
      this.datasource = assets ? assets : []

      if (this.datasource.length > 1){
        this.workOrdersExist = true
      } else {
        this.workOrdersExist = false
      }
   
    })
  }

  close(): void {
    const dialogRef = this.dialog
    dialogRef.closeAll()
  }
}
