import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class LogOutService {
  public isLoggedIn: BehaviorSubject<boolean>

  constructor(private router: Router, private cognito: GeaMesCognitoAuthService) {
    this.isLoggedIn = new BehaviorSubject(true)
  }

  logOut(){
    console.log("LOG USER OFF!")
    this.isLoggedIn.next(false)
    this.cognito.signOutUser();        
  }

  logIn(){
    this.router.navigate(['/login'])
  }
}
