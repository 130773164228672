<div *ngIf="isLoading">
  <h1>Loading Work Items...</h1>
</div>
<div *ngIf="workOrdersExist; else noEAMRecords">
  <div mat-dialog-content>
    <mat-card  *ngFor="let d of datasource" class="workordercard mat-elevation-z8" >
     <mat-card-title>
        <div class="fx-row-space-evenly-center">
          <div class="fx-column-space-between-center">
            <div >Creation Time </div>
            <div >{{d.CREATION_DATE | date:'short'}}</div>
          </div>
          <div class="fx-column-space-between-center" >
            <div >Work Item</div>
            <div >{{d.WR_WO_NUMBER}}</div>
          </div>
        </div>
     </mat-card-title>
      <mat-card-content>
               <div> <b> Description</b></div>
               <div> {{d.DESCRIPTION}} </div>
               <div> <b> Work Type</b></div>
               <div> {{d.WORK_TYPE}}</div>
               <div ><b>Status</b></div>
               <div >{{d.STATUS}}</div>
               <div ><b>Priority</b></div>
               <div >{{d.PRIORTY}}</div>
               <div ><b>ASSET NUMBER</b></div>
               <div >{{d.ASSET_NUMBER}}</div>
      </mat-card-content>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="close()">Close</button> 
  </div>
</div>

<ng-template #noEAMRecords>
  <div mat-dialog-content>
    <div class="fx-row-center-center" >
      <h4>Work Items</h4>
    </div>
    <div class="fx-row-center-center" >
      <h4>No Current Work Items for this Equipment</h4>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="close()">Close</button> 
  </div>
</ng-template>