import { Component, OnInit } from '@angular/core';
import { CompletedOverviewService } from '../../services/completed-overview.service';
import { pluck, map } from 'rxjs/operators';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';
import * as dayjs from 'dayjs'; 

@Component({
  selector: 'app-completed-overview',
  templateUrl: './completed-overview.component.html',
  styleUrls: ['./completed-overview.component.scss']
})
export class CompletedOverviewComponent implements OnInit {
  displayedColumns: string[]
  dataSource: MatTableDataSource<any>;
  completedMetricsData: any;
  completedMetricsObservable: Observable<any>;
  completedMetricsSubscription: Subscription;
  mobile: boolean;
  colorChangeThreshold: number;
  public fromDateString: string;
  public toDateString: string;

  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])

  constructor(private CompletedOverviewService: CompletedOverviewService,
    private router: Router,
    private breakpointObserver: BreakpointObserver) {
    this.displayedColumns = ['Site', 'Daily', 'Weekly', 'Biweekly', 'Monthly']
    this.dataSource = new MatTableDataSource<any>()
    this.colorChangeThreshold = .85
  }

  ngOnInit() {
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.Handset])
      .subscribe((state: BreakpointState) =>{
        if (state.matches){
          this.mobile = true
        } else {
          this.mobile = false
        }
      })
  }

  fetch(){
    this.getCompletedMetrics(this.fromDateString, this.toDateString)
  }

  ngOnDestroy() {
    this.completedMetricsSubscription.unsubscribe()
  }

  getCompletedMetrics(dateRangeStart: string, dateRangeEnd: string){
    if (this.completedMetricsSubscription) this.completedMetricsSubscription.unsubscribe();
    
    this.completedMetricsObservable = this.CompletedOverviewService.completedDashData(dateRangeStart, dateRangeEnd).pipe(
      pluck('Body'),
      map(data => {
        for (let i in data) {
          if (data[i]['SHIFT'] === undefined){
            data[i]['SHIFT'] = {}
            data[i]['SHIFT']["completed"] = "NA",
            data[i]['SHIFT']["total"] = "NA",
            data[i]['SHIFT']["percentComplete"] = "NA"
          }

          if (data[i]['WEEK'] === undefined){
            data[i]['WEEK'] = {}
            data[i]['WEEK']["completed"] = "NA",
            data[i]['WEEK']["total"] = "NA",
            data[i]['WEEK']["percentComplete"] = "NA"
          }

          if (data[i]['BIWEEKLY'] === undefined){
            data[i]['BIWEEKLY'] = {}
            data[i]['BIWEEKLY']["completed"] = "NA",
            data[i]['BIWEEKLY']["total"] = "NA",
            data[i]['BIWEEKLY']["percentComplete"] = "NA"
          }

          if (data[i]['MONTH'] === undefined){
            data[i]['MONTH'] = {}
            data[i]['MONTH']["completed"] = "NA",
            data[i]['MONTH']["total"] = "NA",
            data[i]['MONTH']["percentComplete"] = "NA"
          }
        }
        return data
      })
    )
    
    this.completedMetricsSubscription = this.completedMetricsObservable.subscribe((data) =>{
      this.completedMetricsData = data
      this.dataSource.data = this.completedMetricsData
    })
  }

  navToSiteMetrics(site){
    if (this.completedMetricsSubscription) this.completedMetricsSubscription.unsubscribe();
    let navTarget = "/dashboard/section-overview/" + site
    this.router.navigate([navTarget])
  }

  navToSSRS(){
    let urlToReport = environment.SSRSreports 
    window.open(urlToReport,'_blank')
  }

  onDateSelection(e) {
    this.fromDateString = dayjs(e.start).format('YYYY-MM-DD');
    this.toDateString = dayjs(e.end).format('YYYY-MM-DD');

    console.debug('Selected Date Range: ' + this.fromDateString + ' - ' + this.toDateString);

    this.fetch();
  }
  
}
