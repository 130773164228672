import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogData } from '@gea-mes/datepicker';
import { AdminTaskListService } from '../../services/admin-task-list.service';
import { Subscription } from 'rxjs';
import { TaskType } from 'src/app/services/tasklist.service';
import { ConfigS3UploadService, s3Link } from '../../services/config-s3-upload.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-admin-task-file-dialog',
  templateUrl: './admin-task-file-dialog.component.html',
  styleUrls: ['./admin-task-file-dialog.component.scss']
})
export class AdminTaskFileDialog implements OnInit {

  task:TaskType;
  site: string;
  environment: string;
  object: string;

  fileListSub$: Subscription;
  fileList: any[];

  adminFlag: boolean;
  adminSub$: Subscription;

  displayedColumns: string[] = [ 'image','actions'];

  constructor(
    public dialogRef: MatDialogRef<AdminTaskFileDialog>,
    @Inject(MAT_DIALOG_DATA) public inData: DialogData,
    private snakBar: MatSnackBar,
    private geaMesCognito: GeaMesCognitoAuthService,
    private svcAdminTaskList:AdminTaskListService,
    private svcS3Upload: ConfigS3UploadService
  ) { 
    this.task = inData['task'];
    this.site = inData['site'];
    this.environment = inData['environment'];
    this.object = inData['object'];

    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => { 
      this.adminFlag = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });

  }


  ngOnInit(): void {
    console.log("TASK", this.task);

    this.loadFileList();
  }

  loadFileList(): void {
    if (this.fileListSub$) this.fileListSub$.unsubscribe();

    this.fileListSub$ = this.svcAdminTaskList.taskFileList(this.task.id).subscribe(
      (out) => {
        this.fileList = out.Body;
        console.log("fileList", this.fileList);
      }
    )
  }

  addFile(): void {
    const fileUpload = document.getElementById('fileImageUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
          for (let index = 0; index < fileUpload.files.length; index++) {
                const file = fileUpload.files[index];
                this.startUpload(file);
          }
    };

    fileUpload.click();
  };

  startUpload(file: File) {
    let displayOrder:number;
    
    if (this.fileList && this.fileList.length > 0) {
      displayOrder = this.fileList[this.fileList.length - 1].display_order + 1;
    } else {
      displayOrder = 1;
    }

    this.svcAdminTaskList.uploadFile(this.site, this.environment, this.object, this.task.id, file.name, displayOrder).subscribe((
      out => {
        this.completeUpload(out.Body,file);

    }));
  }

  completeUpload(signedURLInfo: s3Link, file: File) {
    this.svcS3Upload.uploadFile(signedURLInfo, file).subscribe(out => {
      this.snakBar.open(`File ${file.name} Uploaded.`, "Complete", { duration: 2000 } );
      this.loadFileList();
    });
  }

  deleteFile(id:number) {
    if (confirm("Are you sure you would like to delete this image?")) {
      this.svcAdminTaskList.deleteFile(id).subscribe((
        out => {
          console.log("File deleted", out);
          this.loadFileList();

      }));
    }
  }

  changeOrder(id:number, currentDisplayOrder:number, change:number) {
    let updates:any = [];

    // Only allow moving up or down one spot
    if (change == 1 || change == -1) {
      this.fileList.forEach(
        (file) => {
          let newDisplayOrder:number = -1;

          if (file.display_order == currentDisplayOrder + change) {
            newDisplayOrder = file.display_order - change;
          } else if (file.display_order == currentDisplayOrder) {
            newDisplayOrder = file.display_order + change;
          } else {
            newDisplayOrder = file.display_order;
          }

          updates.push({"ID": file.id, "NewDisplayOrder": newDisplayOrder});
        }
      );

      this.svcAdminTaskList.updateOrder(updates).subscribe((
        out => {
          console.log("Order changed", out);
          this.loadFileList();
      }));
    }
  }
}
