import { Injectable } from '@angular/core';
import { AbstractRestService } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class ServiceNowService extends AbstractRestService {

  postInit(): void{}

  createServiceNowTicket(urgency: string, id: number, site: string)
  {
    let params: any={};

    params.urgency = urgency;
    params.task_id = id;
    params.Site = site

    return this.post({
      ApiResource: "/servicenow",
      data: {
        data: params
      }
    })
    
  }

}
