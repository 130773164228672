<div class="fx-row-wrap-space-between-center">
    <div>
    <button mat-stroked-button color="primary" (click)="unSelectShift()">BACK</button>  
    </div>
    <div *ngIf="(checkListType == 'SHIFT') && selectedShift != 'NONE'" class="taskListTitle">
      {{siteTitle}} - {{lineTitle}} - {{objAliasTitle}} : {{selectedShiftDate | date:'MM/dd/yyyy'}} - {{selectedShift}}
    </div>
    <div *ngIf="(checkListType == 'SHIFT') && selectedShift == 'NONE'" class="taskListTitle">
      {{siteTitle}} - {{lineTitle}} - {{objAliasTitle}} : Current Checklist
    </div>
    <div *ngIf="checkListType == 'WEEK' && selectedShift != 'NONE'" class="taskListTitle">
      {{siteTitle}} - {{lineTitle}} - {{objAliasTitle}} : {{selectedShiftDate | DateConversion: 'week'}} - {{selectedShift}}
    </div>    
    <div *ngIf="checkListType == 'WEEK'&& selectedShift == 'NONE'" class="taskListTitle">
      {{siteTitle}} - {{lineTitle}} - {{objAliasTitle}} : {{selectedShiftDate | DateConversion: 'week'}}
    </div>
    <div *ngIf="checkListType == 'BIWEEKLY' && selectedShift != 'NONE'" class="taskListTitle">
      {{siteTitle}} - {{lineTitle}} - {{objAliasTitle}} : {{selectedShiftDate | DateConversion: 'week'}} - {{selectedShift}}
    </div>
    <div *ngIf="checkListType == 'BIWEEKLY' && selectedShift == 'NONE'" class="taskListTitle">
      {{siteTitle}} - {{lineTitle}} - {{objAliasTitle}} : {{selectedShiftDate | DateConversion: 'week'}}
    </div>
    <div *ngIf="checkListType == 'MONTH' && selectedShift != 'NONE'" class="taskListTitle">
      {{siteTitle}} - {{lineTitle}} - {{objAliasTitle}} : {{selectedShiftDate | date:'LLLL'}} - {{selectedShift}}
    </div>
    <div *ngIf="checkListType == 'MONTH' && selectedShift == 'NONE'" class="taskListTitle">
      {{siteTitle}} - {{lineTitle}} - {{objAliasTitle}} : {{selectedShiftDate | date:'LLLL'}}
    </div>
    <div class="fx-column-start-center" class="eamLink" (click)="navToEam()">
      <img class="eamLinkImg" src="assets/eAM.jpg"><span class="eamLinkSpan">eAM Express</span>
    </div>
</div>

  <div class="flex-container">
    <div style="flex-grow: 1" class="lastupdate">
      Last Task Completion: {{selectedLastCompleted == undefined ? '' : selectedLastCompleted}}
    </div>
    <div style="flex-grow: 1" class="lastupdate">
      Form Number: {{selectedFormNumber == undefined ? '' : selectedFormNumber}}
    </div>
    <div style="flex-grow: 1" class="lastupdate">
      Revision:{{selectedRevision == undefined ? '' : selectedRevision}}
    </div>
    <div style="flex-grow: 8">
      <button *ngIf="!editMode" mat-raised-button style='background:cyan' (click)="didNotCompleteAll()" [ngClass]="showDidNotCompleteAllButton == true ? 'showDidNotCompleteAllButton' : 'hideDidNotCompleteAllButton'">Did Not Complete All</button>
    </div> 
  </div>


  <div *ngIf="(isScreen$ | async)?.matches">
    <mat-table [dataSource]="dataSource" matSort matSortDirection="desc" matSortStart="desc" matSortDisabled>
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>Activity Type</mat-header-cell>
            <mat-cell *matCellDef="let d" [ngClass]="d.type == 'HEADER' ? 'sectionHeader' : 'clear'">
              <div *ngIf="d.type != 'HEADER'" >
              
                <div class="fx-row-wrap-space-between-center" style="cursor:pointer" *ngIf="d.type != 'HEADER' && (!editMode || (editMode && d.completed > 1))" [matMenuTriggerFor]="menu" aria-label="Actions available for task" >   
                   <div>
                     <mat-icon> menu </mat-icon>
                   </div>
                   <div style="padding:10px">
                      {{d.type}}
                   </div>
                </div> 
            
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item *ngIf="d.completed == 0 && !editMode" (click)="openCompleteDialog(d.id, 1, d)">
                        <mat-icon>info</mat-icon>
                        <span>Issue Found (NOK)</span>
                      </button>  
                      <button mat-menu-item *ngIf="d.completed == 0 && !editMode" (click)="markOK(d.id, d)">
                          <mat-icon>check</mat-icon>
                          <span>Complete (OK)</span> 
                      </button>
                      <button mat-menu-item *ngIf="d.completed == 0 && !editMode" (click)="openCompleteDialog(d.id, 2, d)">
                        <mat-icon>block</mat-icon>
                        <span>Did Not Complete</span> 
                      </button>              
                      <button mat-menu-item *ngIf="d.completed != 0 && !(!requireComment && d.completed == 1)" (click)="viewComments(d)">
                          <mat-icon>edit</mat-icon>

                          <span *ngIf="!editMode && d.completed == 1 && requireComment">View/Edit Comment (OK)</span>
                          <span *ngIf="editMode && d.completed == 1 && requireComment">View Comment (OK)</span>

                          <span *ngIf="!editMode && d.completed == 2">View/Edit Issue Found (NOK)</span>
                          <span *ngIf="editMode && d.completed == 2">View Issue Found (NOK)</span>

                          <span *ngIf="!editMode && d.completed == 3">View/Edit Comment</span>
                          <span *ngIf="editMode && d.completed == 3">View Comment</span>

                      </button>
                      <button mat-menu-item *ngIf="d.completed != 0 && !editMode" (click)="clearChecklistItem(d.id,  d.comment, d.corective_action, d.tag_no, d.completed)">
                        <mat-icon>clear</mat-icon>
                        <span>Clear Checklist Item</span>
                      </button>
                    </mat-menu>
                </div>
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="task">
            <mat-header-cell *matHeaderCellDef><div style="text-align: center">Task</div></mat-header-cell>
            <mat-cell *matCellDef="let d" [ngClass]="d.type == 'HEADER' ? 'sectionHeader' : 'clear'">
              <div style="display: flex; flex-direction:column">
                <div>
                  {{d.task}}
                  <button mat-icon-button *ngIf="d.task!='' && d.type!='HEADER'" color="primary" (click)="tts(d.task)"><mat-icon>campaign</mat-icon> Read</button>
                </div>
                
                <div *ngIf="d.files">
                  <button mat-icon-button color="primary" (click)="openfiles(d)"><mat-icon>image</mat-icon> Pictures/Instructions</button>
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <!-- Time Column -->
          <ng-container matColumnDef="time">
            <mat-header-cell *matHeaderCellDef>Time Per Task</mat-header-cell>
            <mat-cell *matCellDef="let d" [ngClass]="d.type == 'HEADER' ? 'sectionHeader' : 'clear'">{{d.time}}</mat-cell>
          </ng-container>          

          <ng-container matColumnDef="otherinfo">
            <mat-header-cell *matHeaderCellDef>Other Information</mat-header-cell>
            <mat-cell *matCellDef="let d" [ngClass]="d.type == 'HEADER' ? 'sectionHeader' : 'clear'">
              <div *ngIf="d.type != 'HEADER'">
                {{d.otherinfo}}
                <button mat-icon-button *ngIf="d.otherinfo!=''" color="primary" (click)="tts(d.otherinfo)"><mat-icon>campaign</mat-icon> Read</button>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="issueFound">
            <mat-header-cell *matHeaderCellDef>Issue Description (NOK)</mat-header-cell>
            <mat-cell *matCellDef="let d" [ngClass]="d.type == 'HEADER' ? 'sectionHeader' : 'clear'"><div *ngIf="d.type != 'HEADER'">
              <div *ngIf="d.input_data != ''" style="padding-bottom: 10px;">
                <span style="font-style: italic;">{{d.required_input}}</span> {{d.input_data}}
              </div>
              <div>
                {{d.comment}}
              </div>
            
            </div></mat-cell>
          </ng-container>

          <ng-container matColumnDef="tag">
            <mat-header-cell *matHeaderCellDef>Tag</mat-header-cell>
            <mat-cell *matCellDef="let d" [ngClass]="d.type == 'HEADER' ? 'sectionHeader' : 'clear'"><div *ngIf="d.type != 'HEADER'">{{d.tag_no}}</div></mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columns; sticky: true" ></mat-header-row>
          <mat-row [ngClass]="
            {
              'task-complete': row.completed == 1,
              'task-complete-issue': row.completed == 2,
              'task-did-not-complete-issue': row.completed == 3 
            }"
             *matRowDef="let row; columns: columns;" >
          </mat-row> 
    </mat-table>
  </div>

  <div *ngIf="(isTablet$ | async)?.matches">
    <mat-card [ngClass]="{
        'card-section-header': d.type == 'HEADER', 
        'cards': d.completed == 0, 
        'card-complete': d.completed == 1, 
        'card-complete-issue': d.completed == 2,
        'card-did-not-complete-issue': d.completed == 3
      }" 
      *ngFor="let d of mDataSource">
      <mat-card-title-group>
        <mat-card-title class="tasklist-header">
        <div style="display: flex;">
          <div *ngIf="d.type != 'HEADER'">                
            <div style="float: left; width: 35px; margin: 0px; overflow: hidden; vertical-align: text-top;">
                  <button *ngIf="!editMode" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions available for task" >              
                    <mat-icon>menu</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngIf="d.completed == 0 && !editMode" (click)="openCompleteDialog(d.id, 1, d)">
                      <mat-icon>info</mat-icon>
                      <span>Issue Found (NOK)</span>
                    </button>                

                    <button mat-menu-item *ngIf="d.completed == 0 && !editMode" (click)="markOK(d.id, d)">
                        <mat-icon>check</mat-icon>
                        <span>Complete (OK)</span> 
                      </button>
                      <button mat-menu-item *ngIf="d.completed == 0 && !editMode" (click)="openCompleteDialog(d.id, 2, d)">
                        <mat-icon>block</mat-icon>
                        <span>Did Not Complete</span> 
                      </button>                                
                      <button mat-menu-item *ngIf="d.completed != 0 && !editMode && !(!requireComment && d.completed == 1)" (click)="viewComments(d)">
                        <mat-icon>edit</mat-icon>
                        <span *ngIf="!editMode && d.completed == 1 && requireComment">View/Edit Comment (OK)</span>
                        <span *ngIf="editMode && d.completed == 1 && requireComment">View Comment (OK)</span>

                        <span *ngIf="!editMode && d.completed == 2">View/Edit Issue Found (NOK)</span>
                        <span *ngIf="editMode && d.completed == 2">View Issue Found (NOK)</span>

                        <span *ngIf="!editMode && d.completed == 3">View/Edit Comment</span>
                        <span *ngIf="editMode && d.completed == 3">View Comment</span>
                      </button>
                      <button mat-menu-item *ngIf="d.completed != 0 && !editMode" (click)="clearChecklistItem(d.id,  d.comment, d.corective_action, d.tag_no, d.completed)">
                        <mat-icon>clear</mat-icon>
                        <span>Clear Checklist Item</span>
                      </button>
                    </mat-menu>
                </div>
              </div>
          <!--div style="float: right; flex: 1; overflow: hidden;"-->
          <div style="float: right; flex: 1;">
            <div [ngClass]="d.type == 'HEADER' ? 'sectionHeader' : 'clear'">
              {{d.task}}
              <button mat-icon-button *ngIf="d.task!='' && d.type!='HEADER'" color="primary" (click)="tts(d.task)"><mat-icon>campaign</mat-icon> Read</button>
            </div>
          </div>
        </div>
        </mat-card-title>
      </mat-card-title-group>
      <mat-card-content>
        <div *ngIf="d.type != 'HEADER'">
        <div style="display: flex;">
          <div style="float: left; width: 35px; margin: 0px; text-align: flex-start; overflow: hidden;"></div>
          <!--div style="float: right; flex: 1; overflow: hidden;"-->
          <div style="float: right; flex: 1;">
            <div class="dtl-container">
              <div class="dtl-header-item r1c1">Activity Type</div>
              <div class="dtl-item r2c1">{{d.type}}</div>

              <div class="dtl-header-item r3">Time Per Task</div>
              <div class="dtl-item r4">{{d.time}}</div>

              <div class="dtl-header-item r5">Other Information</div>
              <div class="dtl-item r6">
                <div>
                  {{d.otherinfo}}
                  <button mat-icon-button *ngIf="d.otherinfo!=''" color="primary" (click)="tts(d.otherinfo)"><mat-icon>campaign</mat-icon> Read</button>
                </div>
                <div *ngIf="d.files">
                  <button mat-icon-button color="primary" (click)="openfiles(d)"><mat-icon>image</mat-icon> Pictures/Instructions</button>
                </div>
              </div>

              <div class="dtl-header-item r7">Issue Found</div>
              <div class="dtl-item r8">
                <div *ngIf="d.input_data != ''" style="padding-bottom: 10px;">
                  <span style="font-style: italic;">{{d.required_input}}</span> {{d.input_data}}
                </div>
                <div>
                  {{d.comment}}
                </div>
              </div>

              <div class="dtl-header-item r9">Tag</div>
              <div class="dtl-item r10">{{d.tag_no}}</div>
            </div>
          </div>
          </div>
          </div>
      </mat-card-content>
    </mat-card>
  </div>
