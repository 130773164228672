import { Injectable, Output } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ObjectListService } from './object-list.service';
import { pluck } from 'rxjs/operators';

export interface objs {
  obj: string
}


@Injectable({
  providedIn: 'root'
})
export class ObjectDescriptionService {
  public objDescriptObj:BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private siteList:BehaviorSubject<String[]> = new BehaviorSubject<String[]>(null);
  private siteSectionList:BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private objListService: ObjectListService) {

  }

  public getObjs():Observable<any> {
    if (this.objDescriptObj.value == null) {
      console.debug("Call to load object descriptions");
      this.objListService.objectList().subscribe((
        out => {
          this.updateObjList(out);
          console.debug("object descriptions loaded");
        }
      ));      
    }
    return this.objDescriptObj.asObservable();
  }

  public getSiteSectionList():Observable<String[]> {
    if (this.siteSectionList.value == null) {
      console.debug("Call to load site list");
      this.objListService.objectList().subscribe((
        out => {
          this.updateObjList(out);
          console.debug("Site list loaded");
        }
      ));      
    }
    return this.siteSectionList.asObservable();
  }

  public getSectionList():Observable<String[]> {
    if (this.siteList.value == null) {
      console.debug("Call to load site list");
      this.objListService.objectList().subscribe((
        out => {
          this.updateObjList(out);
          console.debug("Site list loaded");
        }
      ));      
    }
    return this.siteList.asObservable();
  }

  deepLinkObjs(site, environment, obj){
    let dlObjDescript = {}

    this.objListService.objectList(site, environment).pipe(
      pluck('Body'),
      ).subscribe((
      objDataRaw => {
        let objDataArr: any = objDataRaw
        dlObjDescript['siteTitle'] = objDataArr[0]['site']
        Object.keys(objDataArr[0]['sections']).forEach(lines => {
          Object.keys(objDataArr[0]['sections'][lines]).forEach(areaElement => {
            console.log(areaElement, obj)
            if(areaElement == obj){
              dlObjDescript['lineTitle'] = lines
              dlObjDescript['objAliasTitle'] = objDataArr[0]['sections'][dlObjDescript['lineTitle']][obj]['description']
              return dlObjDescript
            }
          })
        })
      }
    ));

    
  }

  updateObjList(objList){
    console.log(objList)
    let objListBody = objList.Body
    let objStorage: any = {}
    let siteSectionList: any=[];

    // Work down tree, first loop are sites
    for (let site in objListBody) {
      let plantDesc = objListBody[site]['description']
      let siteOnly = (plantDesc).slice(0,3)
      siteSectionList.push({site: plantDesc, sections: [], subSections: []});

      objStorage[siteOnly] = {}

      // Second Loop are children of the Site
      for (let siteChild in objListBody[site]["children"]) {
        if ("children" in objListBody[site]["children"][siteChild]) {
          let sectionDesc = objListBody[site]["children"][siteChild]["description"]
          siteSectionList[siteSectionList.length-1]["sections"].push(sectionDesc);
          siteSectionList[siteSectionList.length - 1][sectionDesc] = []
          
          // Third loop are sections
          for (let sectionChild in objListBody[site]["children"][siteChild]["children"]) {
            if ("children" in objListBody[site]["children"][siteChild]["children"][sectionChild]) {
              let subSectionDesc = objListBody[site]["children"][siteChild]["children"][sectionChild]["description"]
              if (subSectionDesc) {
                if (siteSectionList[siteSectionList.length - 1][sectionDesc].length === 0){
                  siteSectionList[siteSectionList.length - 1][sectionDesc].push('All')  
                }
                siteSectionList[siteSectionList.length - 1]["subSections"].push(subSectionDesc)
                siteSectionList[siteSectionList.length - 1][sectionDesc].push(subSectionDesc)
              }

              // Forth Loop are Sub Sections
              for (let subSectionChild in objListBody[site]["children"][siteChild]["children"][sectionChild]["children"]) {
                let thisObjectID = objListBody[site]["children"][siteChild]["children"][sectionChild]["children"][subSectionChild]["objectid"];
                let thisChild = this.buildChild(plantDesc, sectionDesc, subSectionDesc, objListBody[site]["children"][siteChild]["children"][sectionChild]["children"][subSectionChild]);
                objStorage[siteOnly][thisObjectID] = thisChild;
              }
            } else {
              // Handle Children with no sub section
              let thisObjectID = objListBody[site]["children"][siteChild]["children"][sectionChild]["objectid"];
              let thisChild = this.buildChild(plantDesc, sectionDesc, "", objListBody[site]["children"][siteChild]["children"][sectionChild]);
              objStorage[siteOnly][thisObjectID] = thisChild;
            }
          }
        } else {
          // Handle Children with no Section
          let thisObjectID = objListBody[site]["children"][siteChild]["objectid"];
          let thisChild = this.buildChild(plantDesc, "", "", objListBody[site]["children"][siteChild]);
          objStorage[siteOnly][thisObjectID] = thisChild;
        }
      }

    }
    this.objDescriptObj.next(objStorage);
    this.siteSectionList.next(siteSectionList);
    console.log("OUTPUT OF siteSectionList");
    console.log(siteSectionList);
  }

  private buildChild(plantDesc:string, sectionDesc:string, subSectionDesc:string, objRecord:{}) {
    let thisChild = {
      site_description: plantDesc,
      description: objRecord["description"],
      section: sectionDesc,
      subsection: subSectionDesc,
      eamasset: objRecord["EAMAsset"],
      servicenowci: objRecord["ServiceNow_CI"],
      site: objRecord["site"],
      environment: objRecord["environment"],
      objectid: objRecord["objectid"],
      requirecomment: objRecord["RequireComment"]
    }    
    return thisChild;
  }
}
