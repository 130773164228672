import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { pluck, map } from 'rxjs/operators';
import { EquipmentOverviewService } from '../../services/equipment-overview.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CompletedOverviewService } from '../../services/completed-overview.service';
import { Observable, Subscription } from 'rxjs';
import { ObjectDescriptionService } from 'src/app/services/object-description.service';
import { EquipmentOverviewData } from './equipment-overview-data-model';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-equipment-overview',
  templateUrl: './equipment-overview.component.html',
  styleUrls: ['./equipment-overview.component.scss']
})
export class EquipmentOverviewComponent implements OnInit {

  currentSite: string;
  siteSubSections: any[];
  subSectionsExist: boolean;
  subMenuDataExists: boolean;
  dateRangeStart: string;
  dateRangeEnd: string;
  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;
  displayName: string;
  equipmentOverviewData: EquipmentOverviewData[];
  siteMenuData: any;
  sectionMenuData: any;
  subSectionMenuData: any;
  currentSection: string;
  currentSubSection: string;
  currentEnvironment: string;
  getSectionDataObservable: Observable<any>;
  equipmentOverviewObservable: Observable<any>; 
  equipmentOverviewSubscription: Subscription;
  completeOverviewObservable: Observable<any>; 
  completeOverviewSubscription: Subscription;
  menuDataObservable: Observable<any>;
  menuDataSubscription: Subscription;
  mobile: boolean;
  currentDate: string;
  currentDateObj: Date;
  colorChangeThreshold: number;
  fromDateString: string;
  toDateString: string;
  
  constructor(private activatedRoute: ActivatedRoute,
    private equipmentOverview: EquipmentOverviewService,
    private router: Router,
    private CompletedOverviewService: CompletedOverviewService,
    private objDescriptionService: ObjectDescriptionService,
    private breakpointObserver: BreakpointObserver) { 
      this.displayedColumns = ['Section', 'Object', 'Daily', 'Weekly', 'Biweekly', 'Monthly']
      this.dataSource = new MatTableDataSource<any>();
      this.currentSite = this.activatedRoute.snapshot.paramMap.get('site')
      this.currentSection = this.activatedRoute.snapshot.paramMap.get('section')
      this.currentSubSection = this.activatedRoute.snapshot.paramMap.get('subSection')
      this.currentDate = dayjs().format('YYYY-MM-DD')

      console.debug('Current Date: ' + this.currentDate);

      this.colorChangeThreshold = .85
    }

  ngOnInit() {
    console.log("this.currentSite", this.currentSite);

    if (this.currentSection === null){
      this.currentSection = "All"
    }

    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.Handset])
      .subscribe((state: BreakpointState) =>{
        if (state.matches){
          this.mobile = true
        } else {
          this.mobile = false
        }
      })
  }

  ngOnDestroy() {
    if (this.equipmentOverviewSubscription) this.equipmentOverviewSubscription.unsubscribe();
    if (this.menuDataSubscription) this.menuDataSubscription.unsubscribe();
  }

  fetch(){
    this.getMenuData()
    this.getIssueData()
  }

  getIssueData(){
    if (this.equipmentOverviewSubscription) this.equipmentOverviewSubscription.unsubscribe();
    this.equipmentOverviewObservable = this.equipmentOverview.getEquipmentOverview(this.fromDateString, this.toDateString, this.currentDate, this.currentSite).pipe(
      pluck('Body'),
      map(data => {
        this.displayName = data['DisplayName']
        this.getSectionMenuData(this.displayName);
        for (let i in data["MetricsArray"]) {
          let objName = Object.keys(data["MetricsArray"][i])[0]
          if (data["MetricsArray"][i][objName]['Issues']['SHIFT'] === undefined){
            data["MetricsArray"][i][objName]['Issues']['SHIFT'] = "NA"
          }

          if (data["MetricsArray"][i][objName]['Issues']['WEEK'] === undefined){
            data["MetricsArray"][i][objName]['Issues']['WEEK'] = "NA"
          }
          
          if (data["MetricsArray"][i][objName]['Issues']['BIWEEKLY'] === undefined){
            data["MetricsArray"][i][objName]['Issues']['BIWEEKLY'] = "NA"
          }

          if (data["MetricsArray"][i][objName]['Issues']['MONTH'] === undefined){
            data["MetricsArray"][i][objName]['Issues']['MONTH'] = "NA"
          }
          if (data["MetricsArray"][i][objName]['Complete']['SHIFT'] === undefined){
            data["MetricsArray"][i][objName]['Complete']['SHIFT'] = -1
          }

          if (data["MetricsArray"][i][objName]['Complete']['WEEK'] === undefined){
            data["MetricsArray"][i][objName]['Complete']['WEEK'] = -1
          }
          
          if (data["MetricsArray"][i][objName]['Complete']['BIWEEKLY'] === undefined){
            data["MetricsArray"][i][objName]['Complete']['BIWEEKLY'] = -1
          }

          if (data["MetricsArray"][i][objName]['Complete']['MONTH'] === undefined){
            data["MetricsArray"][i][objName]['Complete']['MONTH'] = -1
          }

          if (data["MetricsArray"][i][objName]['PercentComplete']['SHIFT'] === undefined){
            data["MetricsArray"][i][objName]['PercentComplete']['SHIFT'] = "NA"
          }

          if (data["MetricsArray"][i][objName]['PercentComplete']['WEEK'] === undefined){
            data["MetricsArray"][i][objName]['PercentComplete']['WEEK'] = "NA"
          }
          
          if (data["MetricsArray"][i][objName]['PercentComplete']['BIWEEKLY'] === undefined){
            data["MetricsArray"][i][objName]['PercentComplete']['BIWEEKLY'] = "NA"
          }

          if (data["MetricsArray"][i][objName]['PercentComplete']['MONTH'] === undefined){
            data["MetricsArray"][i][objName]['PercentComplete']['MONTH'] = "NA"
          }

          
        }
        return data
      })
    )
    
    this.equipmentOverviewSubscription = this.equipmentOverviewObservable.subscribe((data) =>{
      this.equipmentOverviewData = this.filterData(data["MetricsArray"])
      this.dataSource.data = this.filterData(data["MetricsArray"])
    })
  }

  filterData(dataSet: any[]): any[] {
    let filterDataSet = []
    if (this.currentSubSection && this.currentSubSection != 'All'){
      let filteredEquipmentOverviewData = dataSet.filter(obj => obj.Object.SubSection == this.currentSubSection)
      filterDataSet = filteredEquipmentOverviewData
    }
    else if (this.currentSection == "All"){
      filterDataSet = dataSet
    } 
    else {
      let filteredEquipmentOverviewData = dataSet.filter(obj => obj.Object.Section == this.currentSection)
      filterDataSet = filteredEquipmentOverviewData
    }

    return filterDataSet
  }

  getMenuData(){
    if (this.menuDataSubscription) this.menuDataSubscription.unsubscribe();

    this.menuDataObservable = this.CompletedOverviewService.completedDashData().pipe(
      pluck('Body')
    )
    
    this.menuDataSubscription = this.menuDataObservable.subscribe((menuData) =>{
      this.siteMenuData = menuData
    })
  }

  getSectionMenuData(displayName:string){
    this.objDescriptionService.getSiteSectionList().subscribe((siteData:any) =>{
      if (siteData != null){
        let siteDataFiltered = siteData.find( ({site }) => site === displayName) 
        this.sectionMenuData = 'sections' in siteDataFiltered ? siteDataFiltered['sections'] : [];
        this.currentSection === 'All' ? this.subSectionMenuData = [] : this.subSectionMenuData = siteDataFiltered[this.currentSection];
        this.setSubSectionColumn(siteDataFiltered)
      }
    }) 
  }

  setSubSectionColumn(siteDataFiltered: any[]): void {
    if (this.currentSection != 'All') {
      let siteDataFilteredForSection = siteDataFiltered[this.currentSection]
      let siteDataFilteredForSectionLength = siteDataFilteredForSection.length
      if (siteDataFilteredForSectionLength > 0) {
        this.subSectionsExist = true
        this.subMenuDataExists = true
        this.subSectionMenuData = siteDataFiltered[this.currentSection]
        
        if (this.currentSubSection == null){
          this.currentSubSection = 'All'
        }
        
      } else {
        this.subSectionsExist = false
      }
    
    } else if (this.currentSection === 'All') {
      let subSectionsData = siteDataFiltered['subSections']
      let lenSubSect = subSectionsData.length
      this.subSectionsExist = lenSubSect > 0 ? true : false
    } else {
      this.subSectionsExist = false
    }

    if (this.subSectionsExist) {
      this.displayedColumns = ['Section', 'SubSection', 'Object', 'Daily', 'Weekly', 'Biweekly', 'Monthly']
    } else {
      this.displayedColumns = ['Section', 'Object', 'Daily', 'Weekly', 'Biweekly', 'Monthly']
    }
  }

  navToSection(section){
    let buildUrl = "/dashboard/equipment-overview/" + this.currentSite + '/' + section 
    this.router.navigate([buildUrl])
  }

  navToTaskList(curObject, Environment){
    let buildUrl = "/tasklist/" + this.currentSite + '/' + Environment + '/' + curObject
    this.router.navigate([buildUrl])
  }

  changeSite(site){
    let buildUrl = "/dashboard/equipment-overview/" + site
    this.router.navigate([buildUrl])
    this.currentSite = site
    this.currentSubSection = null
    this.getIssueData();
    this.getMenuData();
  }

  changeSection(section){
    let buildUrl = "/dashboard/equipment-overview/" + this.currentSite + '/' + section
    this.router.navigate([buildUrl])
    this.currentSection = section
    this.currentSubSection = null
    this.getIssueData();
    this.getMenuData();
  }

  changeSubSection(subSection: string, section: string = this.currentSection) {
    let buildUrl = "/dashboard/equipment-overview/" + this.currentSite + '/' + section + '/' + subSection
    this.router.navigate([buildUrl])
    this.currentSubSection = subSection
    this.getIssueData();
    this.getMenuData();
  }
  
  navToEnterprise(){
    let buildUrl = "/dashboard/section-overview/" + this.displayName
    this.router.navigate([buildUrl])
  }

  navToEquipDetials(taskType: string, env:string, objID: string){
    console.log('taskType/env/objID:')
    console.log(taskType)
    console.log(env)
    console.log(objID)
    let buildUrl = 'dashboard/equipment-issues/' + env.substring(0,3) + '/' + objID + '/' + taskType
    this.router.navigate([buildUrl])
  }

  navToSSRS(){
    let urlToReport = environment.SSRSreports 
    window.open(urlToReport,'_blank')
  }

  navToEquipStatusDetails(env: string, obj: string){
    let buildUrl = 'dashboard/equipment-status-details/' + env.substring(0,3) + '/' + env + '/' + obj
    this.router.navigate([buildUrl])
  }

  onDateSelection(e) {
    let utcOffset:number = dayjs(e.start).utcOffset() * -1;

    this.fromDateString = dayjs(e.start).add(utcOffset,'minute').format('YYYY-MM-DD');
    this.toDateString = dayjs(e.end).format('YYYY-MM-DD');

    console.debug('Selected Date Range: ' + this.fromDateString + ' - ' + this.toDateString);

    this.fetch();
  }

}
