import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { formatDate } from '@angular/common';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})

export class CurrentOverviewStatusService extends AbstractRestService {
  postInit(): void {}

  getCurrentOverviewStatusData(site?: string, section?: string, subSection?: string): Observable<GenericLambdaReturn<any>> {
    let currentDate = new Date();
    let currentDateString = formatDate(currentDate, "yyyy-MM-dd HH:mm:ss", "en-US");

    let params:any={};

    currentDateString ? params.CurrentDate = currentDateString:null
    site ? params.SiteName = site:null
    section ? params.Section = section:null
    subSection ? params.SubSection = subSection:null

    return this.get({
      ApiResource: "/dashcurrent",
      data: params
    })    
  }
}

export type OverviewStatus = {
  DisplayName: string,
  MonthlyNotCompleted: number,
  WeeklyNotCompleted: number,
  ShiftNotCompleted: number,
  MonthlyCompleted: number,
  WeeklyCompleted: number,
  ShiftCompleted: number,
  MonthlyCompletedWithIssue: number,
  WeeklyCompletedWithIssue: number,
  ShiftCompletedWithIssue: number,
  MonthlyNotCompletedPercentage: number,
  WeeklyNotCompletedPercentage: number,
  ShiftNotCompletedPercentage: number,
  MonthlyCompletedPercentage: number,
  WeeklyCompletedPercentage: number,
  ShiftCompletedPercentage: number,
  MonthlyCompletedWithIssuePercentage: number,
  WeeklyCompletedWithIssuePercentage: number,
  ShiftCompletedWithIssuePercentage: number
}