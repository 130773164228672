import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, Inject, OnInit, ViewChild} from '@angular/core';
import { PlantListService } from '../../services/plant-list.service'
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import { AssetListStorageService } from '../../services/asset-list-storage.service';
import { GetTaskListService } from '../../services/get-task-list.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { MatLegacySelect as MatSelect } from "@angular/material/legacy-select";
import { EamLookupDialog, EAMLookupData } from "./eam-lookup-dialog";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

export type assetInfo = {
  assetname: string,
  descript: string
}

export type EAMAsset = {
  AssetNumber:string,
  Description:string,
  Maintainable_flag:string
}


@Component({
    selector: 'create-object-dialog',
    templateUrl: './create-object-dialog.html',
    styleUrls: ['./create-object-dialog.scss']
})
export class CreateObjectDialog implements OnInit{

    @ViewChild(CdkVirtualScrollViewport, { static: false })
    cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
    @ViewChild(MatSelect, { static: true }) matSelect: MatSelect;

    title:string;
    disable: boolean;
    assetList: any[];
    filteredOptions: Observable<any>;
    selectedSite: string;
    assetListLoaded: boolean;
    assetListLoading:boolean=false;
    data: DialogData;
    showAutoComplete:boolean=false;
    showInvalidAsset:boolean=false;
    lookupAsset:string;
    lookupAssetSite:string;

    eamAssets:BehaviorSubject<any> = new BehaviorSubject({});
    datasource:any;
    objCreateFormGroup: UntypedFormGroup;

    isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
    isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
  

    constructor(
      public dialogRef: MatDialogRef<CreateObjectDialog>,
      private plantListService: PlantListService,
      @Inject(MAT_DIALOG_DATA) public inData: DialogData,
      private fb: UntypedFormBuilder,
      private _snackBar: MatSnackBar,
      private adminService: GetTaskListService,
      private breakpointObserver: BreakpointObserver,
      private assetListStorageService: AssetListStorageService,
      private dialog:MatDialog)
       {
        this.assetListLoaded = true
        this.data = inData['objData'];
        
        let loadtime_checked: boolean;
        loadtime_checked = this.data.loadtime == 0 ? true : false;

        let requireComment_checked: boolean;
        requireComment_checked = this.data.requirecomment == 1 ? true : false;

        this.selectedSite = this.data.display_name
        
        if (this.data.isEdit) {
          this.title = "Edit Object";
        } else {
          this.title = "Create Object";
        }
        
        this.plantListService.plantList().subscribe((
          out => {
            this.datasource = out.Body;
            if(this.data.isEdit) {
              this.select();
            }             
          }
        ));
        this.disable = this.data.isEdit;

        this.objCreateFormGroup = this.fb.group({
          display_name: new UntypedFormControl(
            {value: this.data.display_name, disabled: this.disable}, Validators.required),
          section: new UntypedFormControl(
            {value: this.data.section, disabled: false}, Validators.required),
          subsection: new UntypedFormControl(
            {value: this.data.subsection, disabled: false}),
          objectID: new UntypedFormControl(
            {value: this.data.objectid, disabled: this.disable}, Validators.required),
          manualDescript: new UntypedFormControl(
            {value: this.data.manualDescription, disabled: false}, Validators.required),
          assetNum: new UntypedFormControl(
            {value: this.data.eam_asset, disabled: false}),
          servicenowci: new UntypedFormControl(
            {value: this.data.servicenowci, disabled: false}),
          loadtime: new UntypedFormControl(
              {value: loadtime_checked, disabled: false}),
          requireComment: new UntypedFormControl(
            {value: requireComment_checked, disabled: false})
        })
      }

    

    ngOnInit(){
      this.filteredOptions = this.eamAssets.asObservable();

      this.objCreateFormGroup.controls['assetNum'].valueChanges.subscribe(value => {
        console.debug("valuechanged", value);
        this.filter(value);
      });


      this.assetListStorageService.eamAssetObservable.subscribe((assets) => {
        console.debug("ASSET LIST RECEIVED!!", assets);
        let assetArr: any = assets
        this.assetList = assetArr
        if (this.assetList[this.selectedSite]) {
          this.assetListLoading = false;
        }
      })
    }

    private filter(value:string) {
      let output:EAMAsset[] = [];

      if (this.assetList[this.selectedSite]){
        output = this.assetList[this.selectedSite].filter(function (option) {
          return option.AssetNumber.toLowerCase().includes(value.toLowerCase())
        });
      }
      
      if (output.length == 0) {
        this.showAutoComplete = false;
      } else {
        this.showAutoComplete = true;
      }
     
      this.eamAssets.next(output);
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    select() {
      const d = this.objCreateFormGroup.getRawValue().display_name;
      console.debug("Site Selected", d);
      this.selectedSite = d;

      if (this.selectedSite != undefined) {
        if (this.selectedSite != "") {
          if (this.assetList != undefined && this.assetList[this.selectedSite]) {
            this.assetListLoading = false;
          } else {
            this.assetListLoading = true;
            this.assetListStorageService.updateAssetList(this.selectedSite);
          }
        }
      }
    }

    duplicateCheck(){
      const d = this.objCreateFormGroup.getRawValue();
      let newObjectID = d.objectID.toUpperCase();
      console.log("OBJS", this.inData['objs'])
      console.log("d", d);
      for (let idx1 in this.inData['objs']){
        let plants = this.inData['objs'][idx1];
        if (plants["description"] == d.display_name) {
          if ("children" in plants) {

            for(let idx2 in plants["children"]) {
              let section = plants["children"][idx2]
              if ("objectid" in section) {
                if (section["objectid"] == newObjectID) {
                  return true;
                }
              }    
              else if ("children" in section) {
                  for(let idx3 in section["children"]) {
                    let subSection = section["children"][idx3]
                    if (subSection["objectid"] == newObjectID) {
                      return true;
                    }
                  }
              }

            }
          }
        }
      };
      return false;
    }

    validateAsset(asset:string):boolean {
      let result:boolean = false;
      
      if (asset == this.data.eam_asset || asset == "") {
        result = true; // Original asset, no changes
      } else if (this.lookupAsset == asset) {
        result = true; // Validated from the lookup
      } else {
        let assetList = this.assetList[this.selectedSite].filter(function (option) {
          return option.AssetNumber.toLowerCase().includes(asset.toLowerCase())
        });
  
        if (assetList.length == 1) {
          result = true;
        } else if (assetList.length > 1) {
          assetList.forEach(function (value) {
            if (value.AssetNumber == asset) {
              result = true;
            }
          })
        } else {
          result = false;
        }
      }
  
      return result;
    }
    
    saveData(): void {
      console.log("Save Data", this.objCreateFormGroup)
      let bClose:boolean = true;
      let loadtime: number;
      let requireComment: number;


      let rawFormData = this.objCreateFormGroup.getRawValue();
      loadtime = rawFormData.loadtime ? 0 : 2;
      requireComment = rawFormData.requireComment ? 1 : 0;
      console.log("requireComment", requireComment);
      const formData = {DisplayName: this.data.display_name, objectid: rawFormData.objectID, section: rawFormData.section, subsection: rawFormData.subsection, manualDescription: rawFormData.manualDescript, LoadTime: loadtime, RequireComment: requireComment, EAMAsset: rawFormData.assetNum,ServiceNow_CI: rawFormData.servicenowci}
      if ( (formData.section == '' || formData.section == null) ||  (formData.objectid == '' || formData.objectid == null) ||  (formData.manualDescription == '' || formData.manualDescription == null) ){
        bClose = false;
        this._snackBar.openFromComponent(CreateObjErrorSnackBar, {
          duration: 5000,
          data: "All fields are required"
        });
      } else if (!this.validateAsset(formData.EAMAsset)) {
        this.showInvalidAsset = true;
        bClose = false;
      } else if (!this.data.isEdit) {
        let dupCheck = this.duplicateCheck()
        if (dupCheck == true) {
          console.log("duplicate check")
          bClose = false;
          this._snackBar.openFromComponent(CreateObjErrorSnackBar, {
            duration: 5000,
            data: "This object already exists"
          });
        } 
      } 
      
      if (bClose) {
          let subSection = "";
          console.log(rawFormData.subsection);
          if (rawFormData.subsection == undefined) {
            subSection = "";
          } else {
            subSection = rawFormData.subsection
          }
          this.adminService.editObject(rawFormData.display_name, rawFormData.objectID, rawFormData.section, subSection, rawFormData.manualDescript, rawFormData.assetNum, rawFormData.servicenowci, loadtime, requireComment).subscribe((
            out => {
              this.dialogRef.close(rawFormData.display_name); 
            }))
      }
    }

    openEAMLookup() {
      const eamAsset:string = this.objCreateFormGroup.getRawValue().assetNum;;
      let site:string;

      if (this.lookupAssetSite != "") {
        site = this.lookupAssetSite;
      } else {
        site = this.selectedSite;
      }

      const dialogRef = this.dialog.open(EamLookupDialog, {
        width: '800px',
        maxHeight: '800px',
        data: {
          "display_name": site,
          "eam_asset": eamAsset
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined && result != "") {
          this.objCreateFormGroup.patchValue({"assetNum": result.eam_asset});
          this.lookupAssetSite = result.display_name;
          this.lookupAsset = result.eam_asset;
        }
      });
    }
}
  
  export interface DialogData {
    isEdit: boolean;
    display_name: string;
    section: string;
    subsection: string;
    objectid: string;
    manualDescription: string;
    eam_asset: string;
    servicenowci: string;
    loadtime: number;
    requirecomment: number;
  }

  @Component({
    selector: 'ErrorSnackBar',
    template: "<span style='color: yellow'>{{data}}</span>",
    styles: [],
  })
  export class CreateObjErrorSnackBar {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
  }