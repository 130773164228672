<div class="currentShifts">
    <div class="taskListTitle">
        <h1 class="mat-h1 shiftListTitle">{{siteTitle}} - {{lineTitle}} - {{objAliasTitle}}</h1>
    </div>
    <div>
        <h1 class="mat-h1">Active Checklists: </h1>
    </div>
    <div *ngIf="showNoActiveChecklist">
        <br>
        <h3 class="noChecklistsTitle">No Active Checklists</h3>    
        <br>
    </div>
    <div *ngIf="showActiveDaily">
        <h3 class="mat-h2">Daily: </h3>
        <div>
            <ng-container *ngFor="let d of activeShiftList">
                <button mat-raised-button class="histShiftButton" [ngClass]="{'issue':d.NOTOKTASKCOUNT>0, 'accent':d.TOTALTASKS == d.COMPLETETASKCOUNT, 'default':d.TOTALTASKS != d.COMPLETETASKCOUNT && d.NOTOKTASKCOUNT == 0}" (click)="selectShift(d.SHIFTDATE, d.SHIFT, 'SHIFT', 'ACTIVE')">
                    <span *ngIf="(d.RECORDTYPE == 'SHIFT')">{{d.SHIFTDATE | date:'MM/dd/yyyy'}} &nbsp;{{d.SHIFT}}</span>
                    <span *ngIf="d.NOTOKTASKCOUNT != 0"> ({{d.NOTOKTASKCOUNT}})</span>
                </button>
            </ng-container>
        </div>
    </div>
    <br *ngIf="showActiveDaily">
    <div *ngIf="showActiveWeekly">
        <h3 class="mat-h2">Weekly: </h3>
        <div>
            <ng-container *ngFor="let wl of activeWeekList">
                <button mat-raised-button class="histShiftButton" [ngClass]="{'issue':wl.NOTOKTASKCOUNT>0, 'accent':wl.TOTALTASKS == wl.COMPLETETASKCOUNT, 'default':wl.TOTALTASKS != wl.COMPLETETASKCOUNT && wl.NOTOKTASKCOUNT == 0}" (click)="selectShift(wl.SHIFTDATE, wl.SHIFT, 'WEEK', 'ACTIVE')">
                    <span>{{wl.SHIFTDATE | DateConversion: 'week'}} </span>
                    <span *ngIf="wl.SHIFT != 'NONE'">&nbsp;{{wl.SHIFT}}</span>
                    <span *ngIf="wl.NOTOKTASKCOUNT != 0"> ({{wl.NOTOKTASKCOUNT}})</span>
                </button>
            </ng-container>
        </div>
    </div>
    <br *ngIf="showActiveWeekly">
    <div *ngIf="showActiveBiweekly">
        <h3 class="mat-h2">Biweekly: </h3>
        <div>
            <ng-container *ngFor="let wl of activeBiWeeklyList">
                <button mat-raised-button class="histShiftButton" [ngClass]="{'issue':wl.NOTOKTASKCOUNT>0, 'accent':wl.TOTALTASKS == wl.COMPLETETASKCOUNT, 'default':wl.TOTALTASKS != wl.COMPLETETASKCOUNT && wl.NOTOKTASKCOUNT == 0}" (click)="selectShift(wl.SHIFTDATE, wl.SHIFT, 'BIWEEKLY', 'ACTIVE')">
                    <span>{{wl.SHIFTDATE | DateConversion: 'week'}} </span>
                    <span *ngIf="wl.SHIFT != 'NONE'">&nbsp;{{wl.SHIFT}}</span>
                    <span *ngIf="wl.NOTOKTASKCOUNT != 0"> ({{wl.NOTOKTASKCOUNT}})</span>
                </button>
            </ng-container>
        </div>
    </div>
    <br *ngIf="showActiveBiweekly">
    <div *ngIf="showActiveMonthly">
        <h3 class="mat-h2">Monthly: </h3>
        <div>
            <ng-container *ngFor="let ml of activeMonthList">
                <button mat-raised-button class="histShiftButton" [ngClass]="{'issue':ml.NOTOKTASKCOUNT>0, 'accent':ml.TOTALTASKS == ml.COMPLETETASKCOUNT, 'default':ml.TOTALTASKS != ml.COMPLETETASKCOUNT && ml.NOTOKTASKCOUNT == 0}" (click)="selectShift(ml.SHIFTDATE, ml.SHIFT, 'MONTH', 'ACTIVE')">
                    <span>{{ml.MONTH | WeekMonthConversion: 'MMMM'}} </span>
                    <span *ngIf="ml.SHIFT != 'NONE'">&nbsp;{{ml.SHIFT}}</span>
                    <span *ngIf="ml.NOTOKTASKCOUNT != 0"> ({{ml.NOTOKTASKCOUNT}})</span>
                </button>
            </ng-container>
        </div>
    </div>
</div>

<mat-divider></mat-divider>

<div class="historicTitle">
    <h1 class="mat-h1">Historical View-Only Shifts: </h1>
</div>
<div *ngIf="showNoHistoricChecklist">
    <br>
    <h3 class="noChecklistsTitle">No Historical Checklists</h3>    
    <br>
</div>
<div *ngIf="showHistoricDaily">
    <h2 class="mat-h2">Daily: </h2>
    <div class="fx-row">

        <ng-container *ngFor="let d of histShiftList">
            <div class="fx-column-start-center">
                <div>{{d.SHIFTDATE| date:'EEE MM/dd/yyyy'}}</div>
                <div *ngFor="let s of d.shifts">
                    <button mat-raised-button class="histDayShiftButton" [ngClass]="{'issue':s.NOTOKTASKCOUNT>0, 'accent':s.TOTALTASKS == s.COMPLETETASKCOUNT, 'default':s.TOTALTASKS != s.COMPLETETASKCOUNT && s.NOTOKTASKCOUNT == 0}" (click)="selectShift(d.SHIFTDATE,s.SHIFT,'SHIFT', 'HISTORIC')">
                        {{s.SHIFT}} <span *ngIf="s.NOTOKTASKCOUNT != 0">({{s.NOTOKTASKCOUNT}})</span>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<br *ngIf="showHistoricDaily">
<div *ngIf="showHistoricWeekly">
    <h3 class="mat-h2">Weekly: </h3>
    <div>
        <ng-container *ngFor="let wl of histWeekList">
            <button mat-raised-button class="histShiftButton" [ngClass]="{'issue':wl.NOTOKTASKCOUNT>0, 'accent':wl.TOTALTASKS == wl.COMPLETETASKCOUNT, 'default':wl.TOTALTASKS != wl.COMPLETETASKCOUNT && wl.NOTOKTASKCOUNT == 0}" (click)="selectShift(wl.SHIFTDATE, wl.SHIFT, 'WEEK', 'HISTORIC')">
                <span>{{wl.SHIFTDATE | DateConversion: 'week'}} </span>
                <span *ngIf="wl.SHIFT != null">&nbsp;{{wl.SHIFT}}</span>
                <span *ngIf="wl.NOTOKTASKCOUNT != 0"> ({{wl.NOTOKTASKCOUNT}})</span>
            </button>
        </ng-container>
    </div>
</div>
<br *ngIf="showHistoricWeekly">
<div *ngIf="showHistoricBiweekly">
    <h3 class="mat-h2">Biweekly: </h3>
    <div>
        <ng-container *ngFor="let wl of histBiWeeklyList">
            <button mat-raised-button class="histShiftButton" [ngClass]="{'issue':wl.NOTOKTASKCOUNT>0, 'accent':wl.TOTALTASKS == wl.COMPLETETASKCOUNT, 'default':wl.TOTALTASKS != wl.COMPLETETASKCOUNT && wl.NOTOKTASKCOUNT == 0}" (click)="selectShift(wl.SHIFTDATE, wl.SHIFT, 'BIWEEKLY', 'HISTORIC')">
                <span>{{wl.SHIFTDATE | DateConversion: 'week'}} </span>
                <span *ngIf="wl.SHIFT != null">&nbsp;{{wl.SHIFT}}</span>
                <span *ngIf="wl.NOTOKTASKCOUNT != 0"> ({{wl.NOTOKTASKCOUNT}})</span>
            </button>
        </ng-container>
    </div>
</div>
<br *ngIf="showHistoricBiweekly">
<div *ngIf="showHistoricMonthly">
    <h3 class="mat-h2">Monthly: </h3>
    <div>
        <ng-container *ngFor="let ml of histMonthList">
            <button mat-raised-button class="histShiftButton" [ngClass]="{'issue':ml.NOTOKTASKCOUNT>0, 'accent':ml.TOTALTASKS == ml.COMPLETETASKCOUNT, 'default':ml.TOTALTASKS != ml.COMPLETETASKCOUNT && ml.NOTOKTASKCOUNT == 0}" (click)="selectShift(ml.SHIFTDATE, ml.SHIFT, 'MONTH', 'HISTORIC')">
                <span>{{ml.mon | WeekMonthConversion: 'MMMM'}} </span>
                <span *ngIf="ml.SHIFT != null">&nbsp;{{ml.SHIFT}}</span>
                <span *ngIf="ml.NOTOKTASKCOUNT != 0"> ({{ml.NOTOKTASKCOUNT}})</span>
            </button>
        </ng-container>
    </div>
</div>