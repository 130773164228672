import { Injectable } from '@angular/core';
import { AbstractRestService,GenericLambdaReturn  } from '@gea-mes/cognito';
import { Observable } from 'rxjs';

export interface tasklist{
  task_type: string,
  shift: string, 
  shift_date: string, 
  week: string, 
  month: string,
  checked: boolean
}


@Injectable({
  providedIn: 'root'
})
export class ForceReloadService extends AbstractRestService {
  postInit(){}


  loadedChecklist(site: string, objEnv: string, objID: string):Observable<GenericLambdaReturn<any>> {
    let params: any = {}
    console.log(site, objEnv, objID)
    site != null ? params.site=site:null
    objEnv != null ? params.objEnv = objEnv:null
    objID != null ? params.objID = objID:null
    console.log(params)

    return this.get({
      ApiResource: "/configforcereload",
      data: params,
      repeatCall: false
    })

  }

  deleteAndForceUpdate(tasklists: tasklist[]):Observable<GenericLambdaReturn<LoadTaskList>>{
    let params:any={};

    params.tasklists = tasklists;

    return this.post({
      ApiResource: "/configforcereload",
      data: { 
        data: params
      }
    })    
  }

}
export interface LoadTaskList{
  "Body": TaskList[],
  "ErrorMessage": string,
  "Timestamp": string
}

export interface TaskList{
  "task_type": string, 
  "shift": string,
  "shift_date": string,
  "month":string,
  "week": string,
  "completed": number
}