import { Injectable } from '@angular/core';
import { EAMService } from 'src/app/services/eam.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssetListStorageService {

  assetList: any
  eamServiceSubscription: Subscription
  private eamAssets = new BehaviorSubject({});
  eamAssetObservable = this.eamAssets.asObservable();
  
  constructor(private eamService: EAMService) {
    this.assetList = {};
    this.eamAssets.next(this.assetList);
  }

  updateAssetList(plantName: string){
    if (plantName != "" && plantName != undefined) {
      let eamSite = plantName.substring(0,3);

      if (eamSite == "MZL"){
        eamSite = "MR2"
      }
      if (eamSite == "CPO"){
        eamSite = "CAM"
      }
      if (eamSite == "Plastics" ||  eamSite == "Pla"){
        eamSite = "AP4"
      }  

      if (this.eamServiceSubscription) this.eamServiceSubscription.unsubscribe();

      this.eamServiceSubscription = this.eamService.getEAMAssetList(eamSite).pipe(
        pluck('Body')
      ).subscribe((assetListRestRaw: any[]) =>{
        let assetListRest: any[] = assetListRestRaw
        if (assetListRestRaw != undefined){
          this.assetList[plantName] = assetListRest.map((asset) => ({AssetNumber: asset.CHILD_ASSET,Description: asset.INSTANCE_DESCRIPTION,Maintainable_flag: asset.MAINTAINABLE_FLAG } ) )  
          console.log("after ",this.assetList) 
          this.eamAssets.next(this.assetList)
       }
      })
    } else if  (this.assetList[plantName]) {
      console.debug("assets for plant already loaded")
      this.eamAssets.next(this.assetList);
    }
  }

}

