<div class="fx-row-start-center">
    <gea-mes-datepicker
        class="dateRangeBut"
        matTooltip="Select Date" 
        (onDateSelect)="onDateSelection($event)" 
        (onDateInit)="onDateSelection($event)"
        singleDate="false" 
        showLabel="true"
        showCurrentShift="false"
        defaultRange="Year To Date"
        showRangeLabel="true"
        timeRangeExclude="Today">
    </gea-mes-datepicker>
    <div class="datepicker-div" *ngIf="!mobile">
      <button mat-raised-button color="primary" class="dateRangeBut" (click)="navToSSRS()">Reports</button>
    </div>
</div>  
<mat-card class="enterprise-complete-card mat-elevation-z8">
    <mat-card-header>
        <mat-card-title>Equipment Status Details</mat-card-title>
        <mat-card-subtitle class="siteSelectSubtitle">
            <button mat-button class="siteSelectBackButton"  color="primary" (click)="navToEnterprise()"><mat-icon>arrow_back</mat-icon></button>
            <button mat-button class="siteSelectButton" color="primary">{{displayName}}</button>
            <button mat-button [matMenuTriggerFor]="objMenu" class="siteSelectButton" color="primary">{{currentObjName}}<mat-icon>arrow_drop_down</mat-icon></button>
            <mat-menu #objMenu="matMenu">
                <button mat-menu-item *ngFor="let objData of objMenuData" (click)="changeObj(objData.objectid, objData.environment)">{{objData.description}}</button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>

    <mat-tab-group>
        <mat-tab label="Daily">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Daily</mat-card-title>
                </mat-card-header>

                    <table mat-table matSort #shiftSort="matSort" [dataSource]="shiftDataSource" matSortActive="shiftDate" matSortDirection = "desc" class="mat-elevation-z2">
                    
                        <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition -->

                        <!-- Section Column -->
                        <ng-container matColumnDef="shiftDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Range</th>
                        <td mat-cell *matCellDef="let element"> {{element.shiftDate | date}} </td>
                        </ng-container>

                        <!-- Section Column -->
                        <ng-container matColumnDef="shift">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Shift</th>
                            <td mat-cell *matCellDef="let element"> {{element.shift}} </td>
                        </ng-container>

                        <!-- Section Column -->
                        <ng-container matColumnDef="Status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                            <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
                        </ng-container>
                
                        <tr mat-header-row *matHeaderRowDef="shiftDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: shiftDisplayedColumns;"></tr>
                    </table>
            </mat-card>
        </mat-tab>
        <mat-tab label="Weekly">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Weekly</mat-card-title>
                </mat-card-header>

                    <table mat-table matSort #weekSort="matSort" [dataSource]="weekDataSource" matSortActive="timeRange" matSortDirection = "desc" class="mat-elevation-z2">
                    
                        <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition -->

                        <!-- Section Column -->
                        <ng-container matColumnDef="timeRange">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Range</th>
                            <td mat-cell *matCellDef="let element"> {{element.shiftDate | DateConversion: 'week'}} </td>
                        </ng-container>

                        <!-- Section Column -->
                        <ng-container matColumnDef="shift">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Shift</th>
                            <td mat-cell *matCellDef="let element"> {{element.shift}} </td>
                        </ng-container>

                        <!-- Section Column -->
                        <ng-container matColumnDef="Status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                            <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
                        </ng-container>
                
                        <tr mat-header-row *matHeaderRowDef="weekDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: weekDisplayedColumns;"></tr>
                    </table>
            </mat-card>
        </mat-tab>
        <mat-tab label="Biweekly" *ngIf="biweeklyCheckListExist">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Biweekly</mat-card-title>
                </mat-card-header>

                    <table mat-table matSort #biweekSort="matSort" [dataSource]="biweeklyDataSource" matSortActive="timeRange" matSortDirection = "desc" class="mat-elevation-z2">
                    
                        <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition -->

                        <!-- Section Column -->
                        <ng-container matColumnDef="timeRange">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Range</th>
                            <td mat-cell *matCellDef="let element"> {{element.timeRange}} </td>
                        </ng-container>

                        <!-- Section Column -->
                        <ng-container matColumnDef="shift">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Shift</th>
                            <td mat-cell *matCellDef="let element"> {{element.shift}} </td>
                        </ng-container>

                        <!-- Section Column -->
                        <ng-container matColumnDef="Status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                            <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
                        </ng-container>
                
                        <tr mat-header-row *matHeaderRowDef="weekDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: weekDisplayedColumns;"></tr>
                    </table>
            </mat-card>
        </mat-tab>
        <mat-tab label="Monthly">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Monthly</mat-card-title>
                </mat-card-header>
                    <table mat-table matSort #monthSort="matSort" [dataSource]="monthDataSource" matSortActive="timeRange" matSortDirection = "desc" class="mat-elevation-z2">
                    
                        <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition -->

                        <!-- Section Column -->
                        <ng-container matColumnDef="timeRange">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Range</th>
                            <td mat-cell *matCellDef="let element"> {{element.shiftDate | DateConversion: 'month'}} </td>
                        </ng-container>

                        <!-- Section Column -->
                        <ng-container matColumnDef="shift">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Shift</th>
                            <td mat-cell *matCellDef="let element"> {{element.shift}} </td>
                        </ng-container>

                        <!-- Section Column -->
                        <ng-container matColumnDef="Status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                            <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
                        </ng-container>
                
                        <tr mat-header-row *matHeaderRowDef="monthDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: monthDisplayedColumns;"></tr>
                    </table>
            </mat-card>
        </mat-tab>
    </mat-tab-group>
</mat-card>

  
