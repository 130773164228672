import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentStatusDetailsService } from '../../services/equipment-status-details.service';
import { environment } from 'src/environments/environment';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'; 
import { MatSort } from '@angular/material/sort';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ObjectDescriptionService } from 'src/app/services/object-description.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-equipment-status-details',
  templateUrl: './equipment-status-details.component.html',
  styleUrls: ['./equipment-status-details.component.scss']
})
export class EquipmentStatusDetailsComponent implements OnInit {
  @ViewChild('shiftSort') shiftSort: MatSort;
  @ViewChild('weekSort') weekSort: MatSort;
  @ViewChild('monthSort') monthSort: MatSort;
  @ViewChild('biweekSort') biweekSort: MatSort;
  
  mobile: boolean;
  currentSite: string; 
  currentEnv: string;
  currentObj: string;
  currentObjName: string;
  dateRangeStart: string;
  dateRangeEnd: string;
  objMenuData: any[];
  displayName: string;
  section: string;
  subsection: string;

  biweeklyCheckListExist: boolean;

  shiftDataSource: MatTableDataSource<any>;
  shiftDisplayedColumns: string[];

  monthDataSource: MatTableDataSource<any>;
  monthDisplayedColumns: string[];

  weekDataSource: MatTableDataSource<any>;
  weekDisplayedColumns: string[];

  biweeklyDisplayedColumns: string[];
  biweeklyDataSource: MatTableDataSource<any>;
  
  equipStatusDataSource: any[];
  equipStatusDataObservable: Observable<any>;
  equipStatusDataSubscription: Subscription;

  getObjDataObservable: Observable<any>;
  
  fromDateString: string;
  toDateString: string;

  constructor(private activatedRoute: ActivatedRoute,
    private equipStatusDetailsService: EquipmentStatusDetailsService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private objDescriptionService: ObjectDescriptionService) { 
    this.currentSite = this.activatedRoute.snapshot.paramMap.get('site');
    this.currentEnv = this.activatedRoute.snapshot.paramMap.get('env');
    this.currentObj = this.activatedRoute.snapshot.paramMap.get('object');
    this.biweeklyCheckListExist = false
    
    this.shiftDisplayedColumns = ['shiftDate', 'shift', 'Status'];
    this.shiftDataSource = new MatTableDataSource<any>();

    this.monthDisplayedColumns = ['timeRange', 'shift', 'Status'];
    this.monthDataSource = new MatTableDataSource<any>();

    this.weekDisplayedColumns = ['timeRange', 'shift', 'Status'];
    this.weekDataSource = new MatTableDataSource<any>();

    this.biweeklyDisplayedColumns = ['timeRange', 'shift', 'Status'];
    this.biweeklyDataSource = new MatTableDataSource<any>();
  }

  ngOnInit() {
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.Handset])
      .subscribe((state: BreakpointState) =>{
        if (state.matches){
          this.mobile = true
        } else {
          this.mobile = false
        }
      })

  }

  ngOnDestroy(){
    if (this.equipStatusDataSubscription) this.equipStatusDataSubscription.unsubscribe();
  }

  fetch(){
    this.getEquipStatusDetails();
  }

  getEquipStatusDetails() {
    if(this.equipStatusDataSubscription) this.equipStatusDataSubscription.unsubscribe()
    this.equipStatusDataObservable = this.equipStatusDetailsService.getEquipStatus(this.currentSite, this.currentEnv, this.currentObj, this.fromDateString, this.toDateString).pipe(
      pluck('Body')
    )
    
    this.equipStatusDataSubscription = this.equipStatusDataObservable.subscribe((equipStatusData) =>{  
      this.equipStatusDataSource = equipStatusData
      console.log(this.equipStatusDataSource)
      this.displayName = equipStatusData['DisplayName']
      this.section = equipStatusData['Section']
      this.subsection = equipStatusData['SubSection']  

      this.getObjMenuData();      
      if (this.equipStatusDataSource['SHIFT'] != undefined){
        this.shiftDataSource.data = this.equipStatusDataSource['SHIFT']
        this.shiftDataSource.sort = this.shiftSort
      } else {
        this.shiftDataSource.data = []
      }

      if (this.equipStatusDataSource['WEEK'] != undefined) {
        this.weekDataSource.data = this.equipStatusDataSource['WEEK']
        this.weekDataSource.sort = this.weekSort
      } else {
        this.weekDataSource.data = []
      }
      
      if (this.equipStatusDataSource['BIWEEKLY'] != undefined) {
        console.log("biweekly exists")
        this.biweeklyDataSource.data = this.equipStatusDataSource['BIWEEKLY']
        this.biweeklyDataSource.sort = this.biweekSort
        this.biweeklyCheckListExist = true
      } else {
        console.log("biweekly does not exists")
        this.biweeklyCheckListExist = false
        this.biweeklyDataSource.data = []
      }

      if (this.equipStatusDataSource['MONTH'] != undefined) {
        this.monthDataSource.data = this.equipStatusDataSource['MONTH']
        this.monthDataSource.sort = this.monthSort
      } else {
        this.monthDataSource.data = []
      }
    })
  }

  navToSSRS(){
    let urlToReport = environment.SSRSreports 
    window.open(urlToReport,'_blank')
  }

  getObjMenuData(){
    this.objDescriptionService.getObjs().subscribe((objData) =>{
      console.log("getObjMenuData()", objData);
      if (objData != null){
        if (this.displayName != null && this.displayName != "") {
          let site = this.displayName.substring(0,3);
          this.objMenuData = Object.values(objData[site]);
          
          if (this.currentObj != null && this.currentObj != "") {
            this.currentObjName = objData[site][this.currentObj]["description"];
          }
        }
      }
    }) 
  }



  changeObj(obj, env){
    this.currentObj = obj
    this.currentEnv = env
    let buildUrl = "/dashboard/equipment-status-details/" + this.currentSite + '/' + env + '/' + obj
    this.router.navigate([buildUrl])

    this.getObjMenuData();
    this.getEquipStatusDetails();
  }

  navToEnterprise(){
    let buildUrl:string = '';
    if (this.subsection == null){
      buildUrl = "/dashboard/equipment-overview/" + this.displayName + '/' + this.section
    }
    else{
      buildUrl = "/dashboard/equipment-overview/" + this.displayName + '/' + this.section  + '/' + this.subsection
    }
    this.router.navigate([buildUrl])
  }

  onDateSelection(e) {
    this.fromDateString = dayjs(e.start).format('YYYY-MM-DD');
    this.toDateString = dayjs(e.end).format('YYYY-MM-DD');

    console.debug('Selected Date Range: ' + this.fromDateString + ' - ' + this.toDateString);

    this.fetch();
  }

}
