import { Injectable } from '@angular/core';
import { AbstractRestService } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class EquipmentStatusDetailsService extends AbstractRestService {
  postInit(): void {
    console.log("Post INIT!");
  }

  getEquipStatus(site: string, env: string, obj: string, start: string, end: string){
    let params:any={};

    site ? params.Site = site:null
    env ? params.ObjEnv = env:null
    obj ? params.ObjectID = obj:null
    start ? params.DateRangeStart = start:null
    end ? params.DateRangeEnd = end:null

    return this.get({
      ApiResource: "/dashequipstatusdetails",
      data: params
    });    
  }
}
