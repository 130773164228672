import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';


@Injectable({
  providedIn: 'root'
})

export class ScheduleDataService extends AbstractRestService {
  
  postInit(): void { }

  buildScheduleData(){
    let params:any={};
    return this.post({
      ApiResource: "/scheduledata",
      data: {
        data: params
       }
    })    
  }
}
