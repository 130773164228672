<h3 mat-dialog-title>Additional Required Input</h3>

<div mat-dialog-content>
    <div>
        Adding a question here will require entering an answer for this question each time this task is completed.
    </div>
    <div style="padding-top: 10px">
        <form [formGroup]="inputFormGroup">
        <mat-form-field class="card-dialog">
            <textarea  matInput cdkTextareaAutosize placeholder="Question" formControlName="required_input"></textarea>
        </mat-form-field> 
        </form>
    </div>
</div>

<div mat-dialog-actions class="fx-row-space-evenly-center">
  <button class="mat-raised-button" color="accent" (click)="save()" cdkFocusInitial>Save</button>
  <button class="mat-raised-button" (click)="close()">Cancel</button>
</div>