import { Component, OnInit } from '@angular/core';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AdminTaskFileDialog } from '../admin-task-file-dialog/admin-task-file-dialog.component';
import { AdminTaskListService } from '../../services/admin-task-list.service';
import { TaskType } from 'src/app/services/tasklist.service';

@Component({
  selector: 'app-admin-task-list',
  templateUrl: './admin-task-list.component.html',
  styleUrls: ['./admin-task-list.component.scss']
})
export class AdminTaskListComponent implements OnInit {
  selectedSite: string;
  site: string;
  environment: string;
  object: string;

  adminFlag: boolean;

  adminSub$: Subscription;
  taskListSub$: Subscription;

  dataSource: any;

  displayedColumns: string[] = [ 'task_type','type', 'tasks', 'time','otherinfo','actions'];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private geaMesCognito: GeaMesCognitoAuthService,
    private svcAdminTaskList:AdminTaskListService
  ) {
    this.selectedSite = this.route.snapshot.paramMap.get('plant');
    this.site = this.route.snapshot.paramMap.get('site');
    this.environment = this.route.snapshot.paramMap.get('environment');
    this.object = this.route.snapshot.paramMap.get('object');

    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => { 
      this.adminFlag = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });

  }

  ngOnInit(): void {
    this.getTaskList();
  }

  getTaskList(): void {
    if (this.taskListSub$) this.taskListSub$.unsubscribe();
    
    this.taskListSub$ = this.svcAdminTaskList.taskList(this.site, this.object).subscribe((data) => {
      this.dataSource = data.Body;
    });
  }

  navigateObjectList():void {
    this.router.navigate(["/admin",this.selectedSite]);
  }

  openFileList(task:TaskType):void {
    const dialogRef = this.dialog.open(AdminTaskFileDialog, {
      width: '800px',
      maxHeight: '800px',
      data: {task: task,
            site: this.site,
            environment: this.environment,
            object: this.object
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      this.getTaskList();
    });
  }


}
