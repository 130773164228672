<!-- Following should be moved to another component -->
<div>
    <button mat-button color="primary" (click)="navigateObjectList()"><mat-icon>arrow_back</mat-icon> Back</button>

    <app-file-upload site={{selectedSite}} environment={{environment}} object={{object}} admin={{adminFlag}} text="Upload Config File"></app-file-upload>
  
    <mat-toolbar color="primary" class="tableTitleBar">Task Details: {{site}} : {{object}}</mat-toolbar>
  
    <div class="table-container">
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
          <mat-cell *matCellDef="let element"><span *ngIf="element.type != 'HEADER'">{{element.type}}</span></mat-cell>
        </ng-container>
  
        <!-- Tasks Type Column -->
        <ng-container matColumnDef="task_type">
          <mat-header-cell *matHeaderCellDef> Time Frame </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.task_type}}</mat-cell>
        </ng-container>
  
        <!-- Tasks Column -->
        <ng-container matColumnDef="tasks">
          <mat-header-cell *matHeaderCellDef>Tasks</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.task}}</mat-cell>
        </ng-container>
  
        <!-- Time Column -->
        <ng-container matColumnDef="time">
          <mat-header-cell *matHeaderCellDef>Time Per Task</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.time}}</mat-cell>
        </ng-container>

        <!-- Other Info -->
        <ng-container matColumnDef="otherinfo">
            <mat-header-cell *matHeaderCellDef>Other Information</mat-header-cell>
            <mat-cell *matCellDef="let element"><div *ngIf="element.type != 'HEADER'">{{element.otherinfo}}</div></mat-cell>
        </ng-container>
  
        <!-- Actions -->
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button [disabled]="!adminFlag" *ngIf="element.type != 'HEADER'" mat-button color="primary" style="color: green" (click)="openInput(element)">
                  <span *ngIf="element.required_input == ''">
                    <mat-icon>info</mat-icon>Add Input</span>
                  <span *ngIf="element.required_input != ''"><mat-icon>edit</mat-icon>Edit Input</span>
                </button>
                <button [disabled]="!adminFlag" *ngIf="element.type != 'HEADER'" mat-button color="primary" (click)="openFileList(element)"><mat-icon>upload_file</mat-icon> 
                  <span *ngIf="element.image_count > 0">Manage Pictures</span>
                  <span *ngIf="element.image_count == 0">Add Pictures</span>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </div>