import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { first } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TaskType } from 'src/app/services/tasklist.service';
import { AdminTaskListService } from '../../services/admin-task-list.service';

@Component({
  selector: 'app-admin-input-dialog',
  templateUrl: './admin-input-dialog.component.html',
  styleUrls: ['./admin-input-dialog.component.scss']
})
export class AdminInputDialog implements OnInit {
  task:TaskType;

  adminFlag: boolean;
  adminSub$: Subscription;

  inputFormGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AdminInputDialog>,
    @Inject(MAT_DIALOG_DATA) public inData: AdminInputDialogData,
    private fb: FormBuilder,
    private geaMesCognito: GeaMesCognitoAuthService,
    private svcAdminTaskList:AdminTaskListService,
  ) {
    this.task = inData['task'];

    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => { 
      this.adminFlag = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });

    this.inputFormGroup = this.fb.group({
      required_input: new FormControl<string>(this.task.required_input ? this.task.required_input : '')
    })
  }

  ngOnInit(): void {
    console.log("TASK", this.task);
  }

  save():void {
    if (this.inputFormGroup.dirty) {
      // Changes detected, save them
      let required_input:string = this.inputFormGroup.controls['required_input'].value;
      console.debug("Change detected, save required_input", required_input);

      this.svcAdminTaskList.updateTask(this.task.id, required_input).subscribe(
        (out) => {
          this.close();
        }
      )
    } else {
      this.close();
    }
  }

  close():void {
    this.dialogRef.close();
  }
}

export interface AdminInputDialogData {
  task: TaskType
}