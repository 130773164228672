<h1 class="mat-h1">Checklist File Validator</h1>


<div class="fx-row-space-between">
    <div>
        <button mat-raised-button [routerLink]="'/admin/validator'">BACK</button>  
    </div>
    <div class="taskListTitle">{{label}}</div>
    <div></div>
</div>

<div class="fx-row-space-evenly-center" style="margin: 15px 15px 15px 15px;">
    <div class="HeadingSmall">Uploaded by: {{userid}}</div>
    <div class="HeadingSmall">Form Number: {{formNumber}}</div>
    <div class="HeadingSmall">Revision: {{revision}}</div>
</div>


<div>
    <mat-table [dataSource]="dataSource" matSort matSortDirection="desc" matSortStart="desc" matSortDisabled>
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>Activity Type</mat-header-cell>
            <mat-cell *matCellDef="let d" [ngClass]="d.type == 'HEADER' ? 'sectionHeader' : 'clear'">
                <div *ngIf="d.type != 'HEADER'">
                    {{d.type}}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="task">
            <mat-header-cell *matHeaderCellDef>
                <div style="text-align: center">Task</div>
            </mat-header-cell>
            <mat-cell *matCellDef="let d" [ngClass]="d.type == 'HEADER' ? 'sectionHeader' : 'clear'">
                <div>{{d.task}}</div>
            </mat-cell>
        </ng-container>

        <!-- Time Column -->
        <ng-container matColumnDef="time">
            <mat-header-cell *matHeaderCellDef>Time Per Task</mat-header-cell>
            <mat-cell *matCellDef="let d" [ngClass]="d.type == 'HEADER' ? 'sectionHeader' : 'clear'">{{d.time}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="otherinfo">
            <mat-header-cell *matHeaderCellDef>Other Information</mat-header-cell>
            <mat-cell *matCellDef="let d" [ngClass]="d.type == 'HEADER' ? 'sectionHeader' : 'clear'">
                <div *ngIf="d.type != 'HEADER'">{{d.otherinfo}}</div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;">
        </mat-row>
    </mat-table>
</div>