import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { PlantListService } from '../../services/plant-list.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AssetListStorageService } from '../../services/asset-list-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-eam-lookup-dialog',
  templateUrl: './eam-lookup-dialog.html',
  styleUrls: ['./eam-lookup-dialog.scss']
})
export class EamLookupDialog implements OnInit {

  plantList:any;
  assetList: any[];
  
  assetListLoading: boolean=false;
  
  selectedSite:string="";
  
  filteredOptions: Observable<any>;
  eamAssets:BehaviorSubject<any> = new BehaviorSubject({});
  showAutoComplete:boolean=true;
  showInvalidAsset:boolean=false;
  
  objEAMLookupFormGroup: UntypedFormGroup;

  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]);
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]);

  constructor(
    public dialogRef: MatDialogRef<EamLookupDialog>,
    private fb: UntypedFormBuilder,
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public inData:EAMLookupData,
    private plantListService: PlantListService,
    private assetListStorageService: AssetListStorageService
  ) {
   }

  ngOnInit(): void {

    if (this.inData.display_name != "") {
      this.selectedSite = this.inData.display_name;
    }

    this.objEAMLookupFormGroup = this.fb.group({
      display_name: new UntypedFormControl({value: this.selectedSite, disabled: false}, Validators.required),
      assetNum: new UntypedFormControl({value: this.inData.eam_asset, disabled: true})
    });

    this.filteredOptions = this.eamAssets.asObservable();

    this.plantListService.plantList().subscribe((
      out => {
        this.plantList = out.Body;
      }
    ));

    this.assetListStorageService.eamAssetObservable.subscribe((assets) => {
      console.debug("ASSET LIST RECEIVED!!", assets);
      let assetArr: any = assets;
      this.assetList = assetArr;
      if (this.assetList[this.selectedSite]) {
        this.setAssetLoading(false);
      }
      let assetNumber:string = this.objEAMLookupFormGroup.getRawValue().assetNum;
      if (assetNumber == undefined) {
        assetNumber = "";
      }

      this.filter(assetNumber); 
    });

    this.objEAMLookupFormGroup.controls['assetNum'].valueChanges.subscribe(value => {
      if (value != undefined) {
        this.filter(value);
      }
    });

    this.changePlant();
  }

  private filter(value:string) {
    let output = [];

    if (this.assetList[this.selectedSite]){
      output = this.assetList[this.selectedSite].filter(function (option) {
        return option.AssetNumber.toLowerCase().includes(value.toLowerCase())
      });
    }

    console.log(output);
    
    if (output.length == 0) {
      this.showAutoComplete = false;
    } else if (this.showInvalidAsset && output.length >= 1) {
      // Clear validation error message if possible
      output.forEach(function (value) {
        if (value.AssetNumber == value) {
          this.showInvalidAsset = false;
        }
      });
      this.showAutoComplete = true;
    } else {
      this.showAutoComplete = true;
    }
   
    this.eamAssets.next(output);
  }  

  changePlant() {
    const displayName = this.objEAMLookupFormGroup.getRawValue().display_name;  
    console.log("Display_name",displayName);

    if (displayName != undefined) {
      this.selectedSite = displayName;

      if (this.selectedSite != "") {
        if (this.assetList[this.selectedSite]) {
          this.setAssetLoading(false);
        } else {
          this.setAssetLoading(true);
          this.assetListStorageService.updateAssetList(this.selectedSite);
        }
      }
    }
  }

  setAssetLoading(loading:boolean=false) {
    if (!loading) {
      this.objEAMLookupFormGroup.controls.assetNum.enable();
    }
    this.assetListLoading = loading;
  }

  validateAsset(asset:string):boolean {
    let result:boolean = false;
    
    if (asset == this.inData.eam_asset || asset == "") {
      result = true; // Original asset, no changes
    } else {
      let assetList = this.assetList[this.selectedSite].filter(function (option) {
        return option.AssetNumber.toLowerCase().includes(asset.toLowerCase())
      });

      if (assetList.length == 1) {
        result = true;
      } else if (assetList.length > 1) {
        assetList.forEach(function (value) {
          if (value.AssetNumber == asset) {
            result = true;
          }
        })
      } else {
        result = false;
      }
    }

    return result;
  }  

  selectData() {
    const assetNumber = this.objEAMLookupFormGroup.getRawValue().assetNum;

    if (this.validateAsset(assetNumber)) {
      this.showInvalidAsset = false;
      let output:EAMLookupData = { 
        display_name: this.selectedSite,
        eam_asset: assetNumber
      }

      this.dialogRef.close(output);
    } else {
      this.showInvalidAsset = true;
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

}

export interface EAMLookupData {
  display_name: string;
  eam_asset: string;
}