import { Injectable } from '@angular/core';
import {BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({
  providedIn: 'root'
})
export class TpmService {


  postInit(): void {
  }
  public isLoading = new BehaviorSubject<boolean>(false);

}
