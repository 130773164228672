import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShiftDateShareService {
  private shiftDateSubject = new BehaviorSubject('');
  shiftDateOb = this.shiftDateSubject.asObservable();
  
  private shiftSubject = new BehaviorSubject('');
  shiftOb = this.shiftSubject.asObservable();

  private objDescriptionSubject = new BehaviorSubject('');
  objDesctiptOb = this.objDescriptionSubject.asObservable();

  constructor() {}

   changeShiftDate(shiftDate){
     console.log(shiftDate)
     this.shiftDateSubject.next(shiftDate)
   }

   changeShift(date){
     console.log(date)
     this.shiftSubject.next(date)
   }

   changeDescription(description){
     console.log(description)
    this.objDescriptionSubject.next(description)
   }


}
