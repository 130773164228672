import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);

@Pipe({name: 'WeekMonthConversion'})
export class WeekMonthConversionPipe implements PipeTransform {
  transform(value: any, format: string): string {
    var converstionStr: string
    let year = value.substring(0,4)

    if(format == "MMMM"){
      var month = value.substring(4,6) - 1 // Month is 0 based (i.e. Jan = 0, Feb = 1)
      converstionStr = dayjs().year(year).month(month).format('MMMM')
      console.debug('WeekMonthConversionPipe (MMMM) - Input:' + value + ' Output: ' + converstionStr)
      return converstionStr
    }else if (format == "w"){
      let week = value.substring(4,6)
      converstionStr = dayjs().year(year).week(week).format('w')
      console.debug('WeekMonthConversionPipe (w) - Input:' + value + ' Output: ' + converstionStr)
      return converstionStr
    } 
  }
}
